// import node module libraries
import { Fragment, useState } from 'react';
import { Card, Col, Image, Row } from 'react-bootstrap';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import '../../../components/Style.css';

// import media files
import Logo from 'assets/images/brand/logo/logo_prestadio_grande.png';

import SignUp from './SignUp';

const FirstSignUp = () => {
    const [userType, setUserType] = useState();
    const navigate = useNavigate();
    const params = useLocation();
    const googleData = params?.state;

    const selectUserType = (userType) => {
        userType;
        setUserType(userType);
    };

    const navigateToSignUpUser = () => {
        if (googleData) {
            navigate('/authentication/finish-sign-up/user', { state: googleData });
        } else {
            navigate('/authentication/sign-up/user/prestadio');
        }
    };

    const navigateToSignUpProvider = () => {
        if (googleData) {
            navigate('/authentication/finish-sign-up/provider', { state: googleData });
        } else {
            navigate('/authentication/sign-up/provider/prestadio');
        }
    };

    console.log(googleData);

    return (
        <Fragment>
            <Row
                className={
                    userType
                        ? 'align-items-center justify-content-center g-0 min-vh-100 d-none'
                        : 'align-items-center justify-content-center g-0 min-vh-100'
                }
            >
                <Col lg={5} md={5} className="py-8 py-xl-0">
                    <Card>
                        <Card.Body className="p-6">
                            <div className="mb-0 justify-content-center">
                                <Link to="/">
                                    <div id="div-logo-grande">
                                        <Link to="/">
                                            <Image
                                                src={Logo}
                                                className="mb-1"
                                                alt=""
                                                id="logo-grande"
                                            />
                                        </Link>
                                    </div>
                                    <hr className="my-4" />
                                </Link>
                            </div>

                            <Row className="">
                                <div className="d-flex align-items-center justify-content-center">
                                    <h2 className="mb-1 fw-bold">
                                        Como você quer utilizar a Prestadio?
                                    </h2>
                                </div>

                                <Col lg={6} md={6} xl={6} className="mt-3">
                                    <div
                                        className="select-card"
                                        onClick={() => navigateToSignUpProvider()}
                                    >
                                        <h1 className="select-card-title">Quero trabalhar</h1>
                                    </div>
                                </Col>
                                <Col lg={6} md={6} xl={6} className="mt-3">
                                    <div
                                        className="select-card"
                                        onClick={() => navigateToSignUpUser()}
                                    >
                                        <h1 className="select-card-title">Quero contratar</h1>
                                    </div>
                                </Col>
                            </Row>

                            <hr className="my-4" />
                            <span>
                                Já possui uma conta?{' '}
                                <Link to="/authentication/sign-in" className="ms-1">
                                    Entrar
                                </Link>
                            </span>
                            {/*<div className="mt-4 text-center">
                               
                                <Link
                                    to="#"
                                    className="btn-social btn-social-outline btn-facebook"
                                >
                                    <i className="fab fa-facebook"></i>
                                </Link>{' '}
                                
                                <Link
                                    to="#"
                                    className="btn-social btn-social-outline btn-google"
                                >
                                    <i className="fab fa-google"></i>
                                </Link>{' '}
                            </div>*/}
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
            <Row>
                {userType ? <SignUp userType={userType} googleData={googleData}></SignUp> : ''}
            </Row>
        </Fragment>
    );
};

export default FirstSignUp;
