// import node module libraries
import React, { createContext, useContext, useEffect, useState } from 'react';
import { Col, Row, Container, Tab } from 'react-bootstrap';
import { Fragment } from 'react';
import axios from 'axios';
// import custom components
import FormSelect from 'components/elements/form-select/FormSelect';

// import sub components

import { checkLogin } from 'functions/checkLogin';

import { useSelector, useDispatch } from 'react-redux';

import AuthContext from 'authentication/auth-context';

import Styles from './styles/styles.css';

import RequestsSendedListTable from './RequestsSendedListTable';

const MyRequestsSended = () => {
    const sortByOptions = [
        { value: 'newest', label: 'Mais Recentes' },
        { value: 'oldest', label: 'Mais Antigos' },
    ];

    return (
        <Fragment>
            <section className="py-4 bg-light">
                <Container>
                    <Row>
                        <Col lg={8} md={10} xs={12}>
                            <div>
                                <div className="mb-2">
                                    <h1 className=" fw-bold mb-4">
                                        Solicitações{' '}
                                        <span className="text-primary">
                                            enviadas
                                        </span>
                                    </h1>
                                    <span className="text-body">
                                        Por aqui você pode acompanhar todas as
                                        suas solicitações de serviço enviadas
                                    </span>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>
            <section className="py-8 px-2 bg-white">
                <Container className="service-container">
                    <Row>
                        <Col md={12} xl={12}>
                            <RequestsSendedListTable />
                        </Col>
                    </Row>
                </Container>
            </section>
        </Fragment>
    );
};

export default MyRequestsSended;
