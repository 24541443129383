import React, { useEffect, useState, useRef } from 'react';
import { Card, Row, Col, Image, Button } from 'react-bootstrap';
import ReactPaginate from 'react-paginate';
import { ChevronLeft, ChevronRight } from 'react-feather';
import moment from 'moment';
import axios from 'axios';

import styles from './styles/styles.css';
import UserImage from 'assets/images/avatar/user.png';
import UserMenu from 'assets/images/icons/menu-pontos.png';
import ProfileLayout from 'components/marketing/student/ProfileLayout';
import ServicesReceivedTable from './ServicesReceivedTable';

const ServiceReceived = () => {
    const [currentPage, setCurrentPage] = useState(0);
    const [quantityItems, setQuantityItems] = useState([]);
    const [requests, setRequest] = useState([]);
    const [openPopupIndex, setOpenPopupIndex] = useState(-1); // Índice do popup aberto
    const popupRef = useRef(null); // Referência ao elemento do popup

    const urgencyLow = '1';
    const urgencyMid = '2';

    const itemsPerPage = 12;

    const pageCount = Math.ceil(quantityItems / itemsPerPage);

    const url = process.env.REACT_APP_API_URL;

    const openDropDown = (index) => {
        if (index === openPopupIndex) {
            setOpenPopupIndex(-1);
        } else {
            setOpenPopupIndex(index);
        }
    };

    const getServiceReceived = async () => {
        const hash = localStorage.getItem('hash');
        const config = {
            headers: {
                Authorization: `Bearer ${hash}`,
            },
        };

        await axios
            .get(
                `${url}accounts/service-requests?limit=${itemsPerPage}&skip=${
                    currentPage * itemsPerPage
                }&sortColumn=createdAt&sortDirection=DESC`,
                config
            )
            .then((response) => {
                setRequest(response.data.result);
                setQuantityItems(response.data.result.length);
                setOpenPopupIndex(-1); // Fechar qualquer popup aberto ao atualizar os dados
            })
            .catch((error) => {
                console.error(error);
            });
    };

    useEffect(() => {
        getServiceReceived();
    }, [currentPage]);

    const changePage = ({ selected }) => {
        setCurrentPage(selected);
    };

    // Função para fechar o popup quando o usuário clica fora dele
    const handleOutsideClick = (e) => {
        if (popupRef.current && !popupRef.current.contains(e.target)) {
            setOpenPopupIndex(-1);
        }
    };

    useEffect(() => {
        // Adiciona um ouvinte de eventos para detectar cliques fora do popup
        document.addEventListener('mousedown', handleOutsideClick);

        return () => {
            // Remove o ouvinte de eventos ao desmontar o componente
            document.removeEventListener('mousedown', handleOutsideClick);
        };
    }, []);

    const viewService = (id) => {
        'Visualizando Serviço: ', id;

        const url = `/solicitacoes/${id}`;
        window.open(url); // '_blank' abre a URL em uma nova aba
    };

    return (
        <ProfileLayout>
            <Card>
                <Card.Header>
                    <div className="mb-3 mb-lg-0">
                        <h3 className="mb-0">Solicitações de serviço recebidas</h3>
                        <p className="mb-0">
                            Nesta página você pode ver todos as solicitações de serviço recebidas
                        </p>
                    </div>
                </Card.Header>
                <Card.Body className="my-requests-card-body">
                    <ServicesReceivedTable dataList={requests}></ServicesReceivedTable>
                </Card.Body>
            </Card>
            <ReactPaginate
                previousLabel={<ChevronLeft size="14px" />}
                nextLabel={<ChevronRight size="14px" />}
                pageCount={pageCount}
                onPageChange={changePage}
                containerClassName={'justify-content-center mb-0 pagination mt-5'}
                previousLinkClassName={'page-link mx-1 rounded'}
                nextLinkClassName={'page-link mx-1 rounded'}
                pageClassName={'page-item'}
                pageLinkClassName={'page-link mx-1 rounded'}
                disabledClassName={'paginationDisabled'}
                activeClassName={'active'}
            />
        </ProfileLayout>
    );
};

export default ServiceReceived;
