/* eslint-disable import/no-unused-modules */
import React from 'react';
import { Col, Row, Container } from 'react-bootstrap';
import { Fragment } from 'react';

import RequestsListTable from './RequestsListTable';

const MyRequests = () => {
    return (
        <Fragment>
            <section className="py-8 bg-light">
                <Container>
                    <Row>
                        <Col lg={8} md={10} xs={12}>
                            <div>
                                <div className="mb-4">
                                    <h1 className=" fw-bold mb-4">Solicitações</h1>
                                    <span className="text-body">
                                        Por aqui você pode acompanhar todas as suas solicitações de
                                        serviço
                                    </span>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>
            <section className="py-8 px-2 bg-white">
                <Container className="service-container">
                    <Row>
                        <Col md={12} xl={12}>
                            <RequestsListTable />
                        </Col>
                    </Row>
                </Container>
            </section>
        </Fragment>
    );
};

export default MyRequests;
