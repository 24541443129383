// import node module libraries
import { Col, Row, Container, Form } from 'react-bootstrap';
import { Fragment, useEffect, useState } from 'react';

import { useParams } from 'react-router-dom';

// import sub-custom components
import FormRatingInfo from './FormRatingInfo';

const PostARating = () => {
    const { id } = useParams();

    const [loading, setLoading] = useState(false);
    const [serviceData, setServiceData] = useState();

    const url = process.env.REACT_APP_API_URL;
    const hash = localStorage.getItem('hash');

    return (
        <Fragment>
            <section className="container-fluid p-6">
                <Container>
                    <Row>
                        <Col md={12} lg={12}>
                            <div className="mb-12">
                                <h1 className="display-4 mb-3 fw-bold">
                                    Formulário de Avaliação
                                </h1>
                                <p className="mb-0 lead">
                                    Este formulário tem como objetivo coletar
                                    feedback dos usuários sobre o sistema de
                                    prestadores de serviço. Sua opinião é
                                    fundamental para aprimorarmos a plataforma e
                                    oferecermos uma experiência cada vez melhor
                                    😁.
                                </p>
                            </div>
                        </Col>
                    </Row>
                    {/* form */}
                    <Form>
                        <Row>
                            <Col>
                                <FormRatingInfo />
                            </Col>
                        </Row>

                        <hr className="my-10" />
                        {/*<CompanyInfo />*/}
                    </Form>
                </Container>
            </section>
        </Fragment>
    );
};

export default PostARating;
