import React from 'react';
import { createRoot } from 'react-dom/client';

import App from './App';
import reportWebVitals from './reportWebVitals';

import AppProvider from 'context/providers/AppProvider';

const container = document.getElementById('root');
const root = createRoot(container);

root.render(
  <AppProvider>
    <App />
  </AppProvider>,
);

reportWebVitals();
