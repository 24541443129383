// import node module libraries
import { Col, Form, InputGroup, Button, CloseButton } from 'react-bootstrap';
import { useSelector, useDispatch } from 'react-redux';
import { useForm, Controller } from 'react-hook-form';
import { searchBoxFilter } from 'redux/filter/actions';

const ServiceProvidersSearchBox = () => {
	const dispatch = useDispatch();
	const { control, handleSubmit, reset } = useForm();
	const searchBox = useSelector((state) => state.filterReducer.searchBox);

	const onSubmit = (data) => {
		dispatch(searchBoxFilter(data.search));
	};

	const clearFilter = () => {
		dispatch(searchBoxFilter(null));
		reset();
	};

	return (
		<section>
			<div className="bg-white rounded-md-pill shadow rounded-3 mb-4">
				<div className="p-md-2 p-4">
					<Form className="row g-1" onSubmit={handleSubmit(onSubmit)}>
						<Col md={9} sm={12}>
							{/* input group job title */}
							<InputGroup>
								<InputGroup.Text
									id="searchJob"
									className="bg-transparent border-0 pe-0 ps-md-3 ps-md-0"
								>
									<svg
										xmlns="http://www.w3.org/2000/svg"
										width="14"
										height="14"
										fill="currentColor"
										className="bi bi-search text-muted"
										viewBox="0 0 16 16"
									>
										<path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z" />
									</svg>
								</InputGroup.Text>
								<Controller
									name="search"
									control={control}
									defaultValue=""
									render={({ field }) => (
										<Form.Control
											{...field}
											placeholder="Nome, área de atuação ou serviço ofertado"
											aria-describedby="searchJob"
											className="rounded-pill border-0 ps-3 form-focus-none"
										/>
									)}
								/>
							</InputGroup>
						</Col>
						<Col md={3} sm={12} className="text-end d-grid">
							{/* button */}
							<Button type="submit" className="rounded-pill">
								Buscar
							</Button>
						</Col>
					</Form>
				</div>
			</div>
			{searchBox && (
				<div className="d-flex align-items-center gap-2 mb-0">
					<p className="mb-0">
						Buscando por: <strong>{searchBox}</strong>
					</p>
					<CloseButton className="btn-close" onClick={clearFilter} />
				</div>
			)}
		</section>
	);
};

export default ServiceProvidersSearchBox;
