// import node module libraries
import { Row, Col, Image, Button, Tooltip, OverlayTrigger } from 'react-bootstrap';
import { Link } from 'react-router-dom';

// // import custom components
// import LevelIconWithTooltip from 'components/marketing/common/miscellaneous/LevelIconWithTooltip';

// import media files
import ProfileBackground from 'assets/images/background/profile-bg.jpg';

import UserImage from 'assets/images/avatar/user.png';

// import SuggestionInput from 'components/marketing/account-settings/functions/AutoSuggest';
import { useNavigate } from 'react-router-dom';

import './styles.css';
import { Clipboard } from 'react-bootstrap-icons';

import { useContext, useState } from 'react';

const ProfileCover = (props) => {
    const { loggedUserData } = props;
    const navigate = useNavigate();

    const viewMyProfile = () => {
        navigate(`/prestadores/listagem/${loggedUserData?._id}`);
    };

    const [linkCopiado, setLinkCopiado] = useState();
    const copiarLinkPerfil = () => {
        try {
            navigator.clipboard.writeText(
                `https://prestadio.com.br/prestadores/listagem/${loggedUserData._id}`
            );
            setLinkCopiado(true);
            setTimeout(() => {
                setLinkCopiado('');
            }, 2000); // Voltar para false após 2 segundos
        } catch {
            setLinkCopiado(false);
            setTimeout(() => {
                setLinkCopiado('');
            }, 2000); // Voltar para false após 2 segundos
        }
    };

    return (
        <Row className="align-items-center header-portfolio-edit-wrapper">
            <div className="header-portfolio-edit">
                <Col>
                    {/* <!-- Bg --> */}
                    <div
                        className="pt-16 rounded-top-md"
                        style={{
                            background: `url(${ProfileBackground})`,
                            backgroundRepeat: 'no-repeat',
                            backgroundSize: 'cover',
                        }}
                    >
                        {' '}
                    </div>
                    <div className="d-flex align-items-center justify-content-between bg-white px-4 pt-2 pb-4 rounded-none rounded-bottom-md shadow-sm flex-wrap gap-3">
                        <div className="d-flex align-items-center">
                            <div className="me-2 position-relative d-flex justify-content-end align-items-end mt-n5">
                                <Image
                                    src={
                                        loggedUserData?.profilePicture
                                            ? loggedUserData?.profilePicture?.url
                                            : UserImage
                                    }
                                    className="avatar-xl rounded-circle border border-4 border-white position-relative"
                                    alt=""
                                />
                                {loggedUserData?.verified ? (
                                    <Link
                                        to="#"
                                        className="position-absolute top-0 end-0"
                                        data-bs-toggle="tooltip"
                                        data-placement="top"
                                        title=""
                                        data-original-title="Verified"
                                    ></Link>
                                ) : (
                                    ''
                                )}
                            </div>
                            <div className="lh-1">
                                <div className="d-flex align-items-baseline gap-2">
                                    <h2 className="mb-0">
                                        {loggedUserData?.firstName} {loggedUserData?.lastName}
                                        {/*<LevelIconWithTooltip
										level={dashboardData.level}
								/>{' '}*/}
                                    </h2>
                                    <div
                                        className="cursor-pointer d-flex align-items-center gap-2"
                                        onClick={() => copiarLinkPerfil()}
                                    >
                                        <OverlayTrigger
                                            key="right"
                                            placement="right"
                                            className="cursor-pointer"
                                            overlay={
                                                <Tooltip
                                                    id={`tooltip-left`}
                                                    className="cursor-pointer"
                                                    style={{ cursor: 'pointer' }}
                                                >
                                                    Copiar link para o meu perfil
                                                </Tooltip>
                                            }
                                        >
                                            <Clipboard />
                                        </OverlayTrigger>
                                        {linkCopiado === true && (
                                            <span className="text-success">Link copiado!</span>
                                        )}
                                        {linkCopiado === false && (
                                            <span className="text-danger">
                                                Erro ao copiar link!
                                            </span>
                                        )}
                                        {linkCopiado === '' && null}
                                    </div>
                                </div>

                                <p className="mb-0 d-block">{loggedUserData?.email}</p>
                            </div>
                        </div>
                        <div className="d-flex view-profile-button">
                            <Button
                                size="sm"
                                className="d-flex align-items-center gap-1"
                                onClick={() => viewMyProfile()}
                            >
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="16"
                                    height="16"
                                    fill="currentColor"
                                    class="bi bi-eye"
                                    viewBox="0 0 16 16"
                                >
                                    <path d="M16 8s-3-5.5-8-5.5S0 8 0 8s3 5.5 8 5.5S16 8 16 8M1.173 8a13 13 0 0 1 1.66-2.043C4.12 4.668 5.88 3.5 8 3.5s3.879 1.168 5.168 2.457A13 13 0 0 1 14.828 8q-.086.13-.195.288c-.335.48-.83 1.12-1.465 1.755C11.879 11.332 10.119 12.5 8 12.5s-3.879-1.168-5.168-2.457A13 13 0 0 1 1.172 8z" />
                                    <path d="M8 5.5a2.5 2.5 0 1 0 0 5 2.5 2.5 0 0 0 0-5M4.5 8a3.5 3.5 0 1 1 7 0 3.5 3.5 0 0 1-7 0" />
                                </svg>
                            </Button>
                        </div>
                    </div>
                </Col>
            </div>
        </Row>
    );
};

export default ProfileCover;
