// import node module libraries
import React, { Fragment } from 'react';
import { Outlet } from 'react-router-dom';

// import layouts
// import NavbarJobPages from 'layouts/marketing/navbars/NavbarJobPages';
import NavbarDefault from 'layouts/marketing/navbars/NavbarDefault';

const FeedLayout = (props) => {
	return (
		<Fragment>
			<NavbarDefault />
			<main>
				{props.children}
				<Outlet />
			</main>
		</Fragment>
	);
};

export default FeedLayout;
