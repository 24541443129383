// import node module libraries
import { Fragment } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { Col, Row, Card, Form, Image } from 'react-bootstrap';
// import media files
import Logo from 'assets/images/brand/logo/logo_prestadio_grande.png';

const EmailConfirmationSent = () => {
    const params = useLocation();

    const email = params?.state ?? '';

    return (
        <Fragment>
            <Row className="align-items-center justify-content-center g-0 min-vh-100">
                <Col lg={5} md={5} className="py-8 py-xl-0 signin">
                    <Card>
                        <Card.Body className="p-6">
                            <div className="mb-4">
                                <Link to="/">
                                    <div id="div-logo-grande">
                                        <Link to="/">
                                            <Image
                                                src={Logo}
                                                className="mb-1"
                                                alt=""
                                                id="logo-grande"
                                            />
                                        </Link>
                                    </div>
                                    <hr className="my-4" />
                                </Link>
                                <h2 className="mb-4 fw-bold">
                                    Um e-mail de confirmação foi enviado para: {email}
                                </h2>

                                <span className="mt-4">
                                    Verifique sua caixa de email e acesse o link enviado para ativar
                                    sua conta
                                </span>
                            </div>
                            <Form>
                                <hr className="my-4" />
                                <span>
                                    Voltar para o <Link to="/authentication/sign-in">login</Link>
                                </span>
                            </Form>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </Fragment>
    );
};

export default EmailConfirmationSent;
