// import node module libraries
import axios from 'axios';
import { Fragment, useState } from 'react';
import { Alert, Button, Card, Col, Form, Image, InputGroup, Row } from 'react-bootstrap';
import { Controller, useForm } from 'react-hook-form';
import { Link, useNavigate } from 'react-router-dom';
import '../../../components/Style.css';

import { differenceInYears, parse } from 'date-fns';

// import media files
import Logo from 'assets/images/brand/logo/logo_prestadio_grande.png';
import ReactInputMask from 'react-input-mask';
import PasswordStrengthBar from 'react-password-strength-bar';

import { FormSelect } from 'components/elements/form-select/FormSelect';
import occupationAreas from 'components/serviceProviders/occupationAreas';

// import cep from 'cep-promise';

const SignUp = (props) => {
	const navigate = useNavigate();
	const { userType, googleData } = props;
	const [submitSuccefull, setSubmitSuccefull] = useState('');
	const [submitError, setSubmitError] = useState('');
	const [loading, setLoading] = useState(false);
	const [password, setPassword] = useState('');
	const [passwordConfirm, setPasswordConfirm] = useState('');
	const [passwordPass, setPasswordPass] = useState(false);

	const url = process.env.REACT_APP_API_URL;

	const {
		register,
		handleSubmit,
		formState: { errors },
		control
	} = useForm({
		defaultValues: {
			email: googleData?.email ?? '',
			firstName: googleData?.given_name ?? '',
			lastName: googleData?.family_name ?? ''
		}
	});

	// Defina a função que receberá o valor do CEP
	const handleCEPChange = async (cepValue) => {
		setSubmitSuccefull('');
		setLoading(true);
		try {
			// Faça o que precisar com o valor do CEP aqui
			const result = await axios.get(`https://viacep.com.br/ws/${cepValue}/json/`);
			return result;
		} catch (error) {
			setSubmitError('CEP Inválido');
			setLoading(false);
		}
	};

	const onSubmit = async (data) => {
		setLoading(true);
		const cepResult = await handleCEPChange(data.cep);
		if (data.birthday) {
			const dataNascimento = parse(data.birthday, 'dd/mm/yyyy', new Date());
			const idade = differenceInYears(new Date(), dataNascimento);

			const age = idade;

			if (age < 18) {
				setSubmitError('Você precisa ter mais de 18 anos para utilizar a Prestadio  :(');
			}

			if (cepResult.data.erro === 'true') {
				setSubmitSuccefull('');
				setSubmitError('CEP Inválido!');
				setLoading(false);
			} else if (password === passwordConfirm && cepResult.data.erro !== 'true' && age >= 18) {
				setSubmitError('');
				setSubmitSuccefull('');
				setLoading(true);
				axios({
					method: 'post',
					url: `${url}accounts/users`,
					data: {
						firstName: data.firstName,
						lastName: data.lastName,
						email: data.email,
						phone: data.phone,
						occupationArea: data.occupationArea,
						password: password,
						userType: userType,
						cep: cepResult.data.cep,
						state: cepResult.data.uf,
						city: cepResult.data.localidade,
						birthday: data.birthday,
						profilePicture: googleData?.picture ?? null
					}
				})
					.then((response) => {
						setLoading(false);

						// Substitua a linha abaixo pela lógica real de redirecionamento
						setSubmitSuccefull('Conta criada com sucesso! Clique aqui para fazer login');

						// Agora, vamos adicionar o contador de 3 segundos
						navigate('/authentication/confirmation-email-sent', {
							state: data.email
						});
					})
					.catch((error) => {
						if (error.response.data.errorCode === 72) {
							setSubmitError('E-mail já cadastrado!');
							setLoading(false);
						} else {
							setSubmitError('Erro ao cadastrar a conta :(');
							setLoading(false);
						}
					});
			} else {
				setLoading(false);
				setPasswordPass(false);
			}
		}
	};

	const options = occupationAreas.map((area) => ({
		label: area.area,
		value: area.area
	}));

	options.sort((a, b) => a.value.localeCompare(b.value));

	const [viewPassword, setViewPassword] = useState(false);
	const [viewConfirmPassword, setViewConfirmPassword] = useState(false);

	return (
		<Fragment>
			<Row className="align-items-center justify-content-center g-0 min-vh-100">
				<Col lg={5} md={5} className="py-8 py-xl-0">
					<Card>
						<Card.Body className="p-6">
							<div className="mb-0">
								<Link to="/">
									<div id="div-logo-grande">
										<Link to="/">
											<Image src={Logo} className="mb-1" alt="" id="logo-grande" />
										</Link>
									</div>
									<hr className="my-4" />
								</Link>
								<h2 className="mb-1 fw-bold">Cadastre-se</h2>
								<span>
									Já possui uma conta?{' '}
									<Link to="/authentication/sign-in" className="ms-1">
										Entrar
									</Link>
								</span>
							</div>
							{/* Form */}
							<Form>
								<Row className="">
									<Col lg={12} md={6} xl={6} className="mt-3">
										{/* User Name */}

										<Form.Control
											type="text"
											id="firstName"
											placeholder="Primeiro Nome"
											readOnly={!!googleData}
											{...register('firstName', {
												required: true
											})}
										/>
									</Col>
									{errors?.firstName?.type === 'required' && (
										<p className="errorMessageInput">Campo obrigatório*</p>
									)}
									<Col lg={12} md={6} xl={6} className="mt-3">
										{/* User Name */}

										<Form.Control
											type="text"
											id="lastName"
											readOnly={!!googleData}
											placeholder="Segundo Nome"
											{...register('lastName', {
												required: true
											})}
										/>
									</Col>
									{errors?.lastName?.type === 'required' && (
										<p className="errorMessageInput">Campo obrigatório*</p>
									)}
									<Col lg={12} md={6} xl={6} className="mt-3">
										{/* User Name */}

										<ReactInputMask
											className="form-control"
											type="text"
											id="birthday"
											mask="99/99/9999"
											placeholder="Data de Nascimento"
											{...register('birthday', {
												required: true
											})}
										/>
									</Col>
									{errors?.birthday?.type === 'required' && (
										<p className="errorMessageInput">Campo obrigatório*</p>
									)}
									<Col lg={12} md={6} xl={6} className="mt-3">
										{/* Telefone */}

										<ReactInputMask
											className="form-control"
											type="text"
											id="phone"
											mask="(99) 99999-9999"
											placeholder="Telefone"
											{...register('phone', {
												required: true
											})}
										/>
									</Col>
									{errors?.phone?.type === 'required' && (
										<p className="errorMessageInput">Campo obrigatório*</p>
									)}
									<Col lg={12} md={12} xl={12} className="mt-3">
										{/* email */}

										<Form.Control
											type="text"
											id="email"
											readOnly={!!googleData}
											placeholder="E-mail"
											{...register('email', {
												required: true
											})}
										/>
									</Col>
									{errors?.email?.type === 'required' && (
										<p className="errorMessageInput">Campo obrigatório*</p>
									)}
									<Col lg={12} md={12} className="mt-3">
										{/* Username or email */}
										{/*<Form.Label>E-mail </Form.Label>*/}
										<Form.Control
											type="number"
											id="cep"
											placeholder="CEP"
											{...register('cep', {
												required: true
											})}
										/>
										{errors?.cep?.type === 'required' && (
											<p className="errorMessageInput">Campo obrigatório*</p>
										)}
									</Col>
									{userType === 'provider' ? (
										<Col lg={12} md={12} className="mt-3">
											<Controller
												name="occupationArea"
												control={control}
												rules={{ required: true }}
												render={({ field: { onChange, onBlur, value, ref } }) => (
													<Form.Control
														as={FormSelect}
														options={options}
														onBlur={onBlur}
														onChange={onChange}
														checked={value}
														inputRef={ref}
														placeholder="Selecione a sua área de atuação"
													/>
												)}
											/>

											{errors?.occupationArea?.type === 'required' && (
												<p className="errorMessageInput">Campo obrigatório*</p>
											)}
										</Col>
									) : (
										''
									)}

									<Col lg={12} md={6} xl={6} className="mt-3">
										{/* Password */}

										<InputGroup>
											<Form.Control
												type={viewPassword === true ? 'text' : 'password'}
												id="password"
												placeholder="Senha"
												onChange={(e) => setPassword(e.target.value)}
											/>
											<InputGroup.Text
												id="basic-addon1"
												className="cursor-pointer"
												onClick={() => setViewPassword(!viewPassword)}
											>
												{viewPassword === true ? (
													<svg
														xmlns="http://www.w3.org/2000/svg"
														width="16"
														height="16"
														fill="currentColor"
														class="bi bi-eye"
														viewBox="0 0 16 16"
													>
														<path d="M16 8s-3-5.5-8-5.5S0 8 0 8s3 5.5 8 5.5S16 8 16 8M1.173 8a13 13 0 0 1 1.66-2.043C4.12 4.668 5.88 3.5 8 3.5s3.879 1.168 5.168 2.457A13 13 0 0 1 14.828 8q-.086.13-.195.288c-.335.48-.83 1.12-1.465 1.755C11.879 11.332 10.119 12.5 8 12.5s-3.879-1.168-5.168-2.457A13 13 0 0 1 1.172 8z" />
														<path d="M8 5.5a2.5 2.5 0 1 0 0 5 2.5 2.5 0 0 0 0-5M4.5 8a3.5 3.5 0 1 1 7 0 3.5 3.5 0 0 1-7 0" />
													</svg>
												) : (
													<svg
														xmlns="http://www.w3.org/2000/svg"
														width="16"
														height="16"
														fill="currentColor"
														class="bi bi-eye-slash"
														viewBox="0 0 16 16"
													>
														<path d="M13.359 11.238C15.06 9.72 16 8 16 8s-3-5.5-8-5.5a7 7 0 0 0-2.79.588l.77.771A6 6 0 0 1 8 3.5c2.12 0 3.879 1.168 5.168 2.457A13 13 0 0 1 14.828 8q-.086.13-.195.288c-.335.48-.83 1.12-1.465 1.755q-.247.248-.517.486z" />
														<path d="M11.297 9.176a3.5 3.5 0 0 0-4.474-4.474l.823.823a2.5 2.5 0 0 1 2.829 2.829zm-2.943 1.299.822.822a3.5 3.5 0 0 1-4.474-4.474l.823.823a2.5 2.5 0 0 0 2.829 2.829" />
														<path d="M3.35 5.47q-.27.24-.518.487A13 13 0 0 0 1.172 8l.195.288c.335.48.83 1.12 1.465 1.755C4.121 11.332 5.881 12.5 8 12.5c.716 0 1.39-.133 2.02-.36l.77.772A7 7 0 0 1 8 13.5C3 13.5 0 8 0 8s.939-1.721 2.641-3.238l.708.709zm10.296 8.884-12-12 .708-.708 12 12z" />
													</svg>
												)}
											</InputGroup.Text>
										</InputGroup>
										<PasswordStrengthBar
											className="password_strength"
											scoreWords={['Fraca', 'Média', 'Forte', 'Muito Forte', 'Fortíssima']}
											minLenght={8}
											shortScoreWord="Muito pequena"
											password={password}
										/>
										{password.length === undefined && (
											<p className="errorMessageInput">Campo obrigatório*</p>
										)}
									</Col>

									<Col lg={12} md={6} xl={6} className="mt-3">
										{/* Password */}
										<InputGroup>
											<Form.Control
												type={viewConfirmPassword === true ? 'text' : 'password'}
												id="password-confirm"
												placeholder="Confirmar senha"
												onChange={(e) => setPasswordConfirm(e.target.value)}
											/>
											<InputGroup.Text
												id="basic-addon1"
												className="cursor-pointer"
												onClick={() => setViewConfirmPassword(!viewConfirmPassword)}
											>
												{viewConfirmPassword === true ? (
													<svg
														xmlns="http://www.w3.org/2000/svg"
														width="16"
														height="16"
														fill="currentColor"
														class="bi bi-eye"
														viewBox="0 0 16 16"
													>
														<path d="M16 8s-3-5.5-8-5.5S0 8 0 8s3 5.5 8 5.5S16 8 16 8M1.173 8a13 13 0 0 1 1.66-2.043C4.12 4.668 5.88 3.5 8 3.5s3.879 1.168 5.168 2.457A13 13 0 0 1 14.828 8q-.086.13-.195.288c-.335.48-.83 1.12-1.465 1.755C11.879 11.332 10.119 12.5 8 12.5s-3.879-1.168-5.168-2.457A13 13 0 0 1 1.172 8z" />
														<path d="M8 5.5a2.5 2.5 0 1 0 0 5 2.5 2.5 0 0 0 0-5M4.5 8a3.5 3.5 0 1 1 7 0 3.5 3.5 0 0 1-7 0" />
													</svg>
												) : (
													<svg
														xmlns="http://www.w3.org/2000/svg"
														width="16"
														height="16"
														fill="currentColor"
														class="bi bi-eye-slash"
														viewBox="0 0 16 16"
													>
														<path d="M13.359 11.238C15.06 9.72 16 8 16 8s-3-5.5-8-5.5a7 7 0 0 0-2.79.588l.77.771A6 6 0 0 1 8 3.5c2.12 0 3.879 1.168 5.168 2.457A13 13 0 0 1 14.828 8q-.086.13-.195.288c-.335.48-.83 1.12-1.465 1.755q-.247.248-.517.486z" />
														<path d="M11.297 9.176a3.5 3.5 0 0 0-4.474-4.474l.823.823a2.5 2.5 0 0 1 2.829 2.829zm-2.943 1.299.822.822a3.5 3.5 0 0 1-4.474-4.474l.823.823a2.5 2.5 0 0 0 2.829 2.829" />
														<path d="M3.35 5.47q-.27.24-.518.487A13 13 0 0 0 1.172 8l.195.288c.335.48.83 1.12 1.465 1.755C4.121 11.332 5.881 12.5 8 12.5c.716 0 1.39-.133 2.02-.36l.77.772A7 7 0 0 1 8 13.5C3 13.5 0 8 0 8s.939-1.721 2.641-3.238l.708.709zm10.296 8.884-12-12 .708-.708 12 12z" />
													</svg>
												)}
											</InputGroup.Text>
										</InputGroup>
									</Col>
									{passwordConfirm !== password && (
										<p className="errorMessageInput">As senhas não conferem*</p>
									)}
									<Col lg={12} md={12} className="mt-3 mb-3">
										{/* Checkbox */}
										<Form.Check type="checkbox" id="check-api-checkbox">
											{/*<Form.Check.Input type="checkbox" />*/}
											<Form.Check.Label>
												Ao criar uma conta, concordo com os{' '}
												<Link to="/termos-de-uso">Termos de Uso </Link> e{' '}
												<Link to="/politica-de-privacidade">Política de Privacidade.</Link>
											</Form.Check.Label>
										</Form.Check>
									</Col>
									<Col lg={12} md={12} className="mb-0 d-grid gap-2">
										{/* Button */}
										<Button
											variant="primary"
											type="submit"
											disabled={loading}
											onClick={handleSubmit(onSubmit)}
											style={{ color: 'white' }}
										>
											{loading && (
												<span
													className="spinner-border spinner-border-sm me-2"
													role="status"
													aria-hidden="true"
												></span>
											)}
											Criar conta
										</Button>
										{submitError.length > 0 && (
											<Col className="submitError">
												<Alert variant="danger">{submitError}</Alert>
											</Col>
										)}
										{submitSuccefull.length > 0 && (
											<Col className="submitError">
												<Alert
													variant="success"
													className="cursor-pointer"
													onClick={() => navigate('/authentication/sign-in')}
												>
													{submitSuccefull}
												</Alert>
											</Col>
										)}
										{passwordPass === true && (
											<Col className="submitError">
												<p>As senhas não conferem!</p>
											</Col>
										)}
									</Col>
								</Row>
							</Form>
						</Card.Body>
					</Card>
				</Col>
			</Row>
		</Fragment>
	);
};

export default SignUp;
