import React, { useEffect, useState, useRef } from 'react';
import { Card, Row, Col, Image, Button } from 'react-bootstrap';
import ReactPaginate from 'react-paginate';
import { ChevronLeft, ChevronRight } from 'react-feather';
import moment from 'moment';
import axios from 'axios';

import styles from './styles/styles.css';
import UserImage from 'assets/images/avatar/user.png';
import UserMenu from 'assets/images/icons/menu-pontos.png';
import ProfileLayout from 'components/marketing/student/ProfileLayout';

const MyRequest = () => {
    const [currentPage, setCurrentPage] = useState(0);
    const [requests, setRequest] = useState([]);
    const [quantityItems, setQuantityItems] = useState(0);
    const [dropDownOpen, setDropDownOpen] = useState(-1);
    const popupRef = useRef(null); // Referência ao elemento do popup

    const urgencyLow = '1';
    const urgencyMid = '2';

    const itemsPage = 12;

    const pageCount = Math.ceil(quantityItems / itemsPage);

    const url = process.env.REACT_APP_API_URL;

    const openDropDown = (index) => {
        if (index === dropDownOpen) {
            setDropDownOpen(-1);
        } else {
            setDropDownOpen(index);
        }
    };

    const getMyRequests = async () => {
        const hash = localStorage.getItem('hash');
        const config = {
            headers: {
                Authorization: `Bearer ${hash}`,
            },
        };

        currentPage;

        await axios
            .get(
                `${url}accounts/service-requests/my?limit=${itemsPage}&skip=${
                    itemsPage * currentPage
                }&sortColumn=createdAt&sortDirection=DESC`,
                config
            )
            .then((response) => {
                setQuantityItems(response.data.metadata.total);
                setRequest(response.data.result);
            })

            .catch((error) => {
                setLoading(false);
            });
    };

    useEffect(() => {
        getMyRequests();
    }, [currentPage]);

    const changePage = ({ selected }) => {
        setCurrentPage(selected);
    };

    // Função para fechar o popup quando o usuário clica fora dele
    const handleOutsideClick = (e) => {
        if (popupRef.current && !popupRef.current.contains(e.target)) {
            setDropDownOpen(-1);
        }
    };

    useEffect(() => {
        // Adiciona um ouvinte de eventos para detectar cliques fora do popup
        document.addEventListener('mousedown', handleOutsideClick);

        return () => {
            // Remove o ouvinte de eventos ao desmontar o componente
            document.removeEventListener('mousedown', handleOutsideClick);
        };
    }, []);

    const viewService = (id) => {
        'Visualizando Serviço: ', id;

        const url = `/solicitacoes/${id}`;
        window.open(url); // '_blank' abre a URL em uma nova aba
    };

    return (
        <ProfileLayout>
            <Card>
                <Card.Header>
                    <div className="mb-3 mb-lg-0">
                        <h3 className="mb-0">Solicitações de serviço recebidas</h3>
                        <p className="mb-0">
                            Nesta página você pode ver todas as solicitações de serviço enviadas
                        </p>
                    </div>
                </Card.Header>
                <Card.Body className="my-requests-card-body">
                    <div className="my-requestes-container">
                        <div className="my-requests-information-wrapper">
                            <div className="my-requests-information-space col"></div>
                            <Col className="my-requests-information-item">
                                <h5 className="mb-0">Informações do usuário</h5>
                            </Col>
                            <Col className="my-requests-information-item">
                                <h5 className="mb-0">Data de atendimento</h5>
                            </Col>
                            <Col className="my-requests-information-item">
                                <h5 className="mb-0">Urgência</h5>
                            </Col>
                            <div className="my-requests-information-space-two col"></div>
                        </div>
                        {requests.map((values, index) => (
                            <div className="my-requests-user-wrapper" key={index}>
                                <div className="my-requests-col-image">
                                    <Image
                                        className="my-requests-user-image"
                                        alt=""
                                        src={
                                            values?.requested.profilePicture
                                                ? values.requested.profilePicture.url
                                                : UserImage
                                        }
                                    />
                                </div>
                                <div className="my-requests-user-information-wrapper">
                                    <Col className="my-requests-col">
                                        <h5 className="my-requests-user-name mb-0">
                                            {values.requested.firstName} {values.requested.lastName}
                                        </h5>
                                        <span className="my-requests-user-job mb-0">
                                            {values.requested.portfolioTitle}
                                        </span>
                                        <span className="my-requests-user-email mb-0">
                                            {values.requested?.occupationArea}
                                        </span>
                                    </Col>
                                    <Col className="my-requests-user-data-job my-requests-col">
                                        {moment(values.dateForService).format('DD/MM/YYYY')}
                                    </Col>
                                    <Col className="my-requests-col">
                                        {values.requestUrgency === urgencyLow ? (
                                            <span className="my-requests-urgency-low">
                                                Pouco Urgente
                                            </span>
                                        ) : values.requestUrgency === urgencyMid ? (
                                            <span className="my-requests-urgency-mid">Urgente</span>
                                        ) : (
                                            <span className="my-requests-urgency-high">
                                                Muito Urgente
                                            </span>
                                        )}
                                    </Col>
                                    <div className="">
                                        <Button
                                            variant="primary"
                                            type="submit"
                                            disabled={false}
                                            onClick={() => viewService(values._id)}
                                        >
                                            Visualizar
                                        </Button>
                                    </div>
                                </div>

                                {/* Mobile popup */}
                                <div
                                    className="my-requests-user-menu-wrapper-mobile"
                                    onClick={() => openDropDown(index)}
                                >
                                    <Image
                                        className="my-requests-user-menu"
                                        alt=""
                                        src={UserMenu}
                                    />
                                    {dropDownOpen === index && (
                                        <div
                                            className="dropdown-menu show popup-my-requests"
                                            ref={popupRef}
                                        >
                                            <a className="dropdown-item" href="/">
                                                link aqui
                                            </a>
                                        </div>
                                    )}
                                </div>
                            </div>
                        ))}
                    </div>
                </Card.Body>
            </Card>
            <ReactPaginate
                previousLabel={<ChevronLeft size="14px" />}
                nextLabel={<ChevronRight size="14px" />}
                pageCount={pageCount}
                onPageChange={changePage}
                containerClassName={'justify-content-center mb-0 pagination mt-5'}
                previousLinkClassName={'page-link mx-1 rounded'}
                nextLinkClassName={'page-link mx-1 rounded'}
                pageClassName={'page-item'}
                pageLinkClassName={'page-link mx-1 rounded'}
                disabledClassName={'paginationDisabled'}
                activeClassName={'active'}
            />
        </ProfileLayout>
    );
};

export default MyRequest;
