import React, { useEffect } from 'react';

const RedirectLink = () => {
    useEffect(() => {
        // Obtenha o link da URL
        const link = window.location.href;

        // Armazene o link no localStorage
        localStorage.setItem('redirectLink', link);

        link;
    }, []);
};

export default RedirectLink;
