// import node module libraries
import { Col, Row, Container, Card } from 'react-bootstrap';

import {
    Clipboard2Check,
    EnvelopeCheck,
    Magic,
    ChatDots,
    HandThumbsUp,
} from 'react-bootstrap-icons';

import './styles/styles.css';

const HowItWorks = () => {
    return (
        <section id="como-funciona" className="py-lg-14 pb-8 pt-8 blue">
            <Container className="bg-primary rounded-3 blue">
                <Row className="d-flex flex-direction-column blue">
                    <div className="blue d-flex flex-direction-column container-howitworks">
                        <div className="container-title d-flex align-items-center justify-content-center mb-4">
                            <h3 className="home-page-title-certainty-text text-secondary blue">
                                Para Prestadores
                            </h3>
                        </div>
                        <div className="d-flex flex-direction-row justify-content-center gap-3">
                            <Row>
                                <Col
                                    lg={4}
                                    xl={4}
                                    sm={12}
                                    className="d-flex justify-content-center align-items-center mb-2"
                                >
                                    <Card style={{ width: '18rem' }}>
                                        <Card.Body className="card-b">
                                            <div className="d-flex card-icon mb-4">
                                                <Clipboard2Check
                                                    size={60}
                                                    className="text-primary"
                                                />
                                            </div>
                                            <Card.Title className="d-flex justify-content-center">
                                                <h3>Cadastre-se</h3>
                                            </Card.Title>
                                            <Card.Text className="text-black">
                                                Faça o seu cadastro, preencha o seu portfólio e
                                                destaque o seu trabalho.
                                            </Card.Text>
                                        </Card.Body>
                                    </Card>
                                </Col>
                                <Col
                                    lg={4}
                                    xl={4}
                                    sm={12}
                                    className="d-flex justify-content-center align-items-center mb-2"
                                >
                                    <Card style={{ width: '18rem' }}>
                                        <Card.Body className="card-b">
                                            <div className="d-flex card-icon mb-4">
                                                <EnvelopeCheck size={60} className="text-primary" />
                                            </div>
                                            <Card.Title className="d-flex">
                                                <h3>Receba solicitações</h3>
                                            </Card.Title>
                                            <Card.Text className="text-black">
                                                Vamos exibir o seu portfólio. Receba solicitações de
                                                serviço de contratantes
                                            </Card.Text>
                                        </Card.Body>
                                    </Card>
                                </Col>
                                <Col
                                    lg={4}
                                    xl={4}
                                    sm={12}
                                    className="d-flex justify-content-center align-items-center mb-2"
                                >
                                    <Card style={{ width: '18rem' }}>
                                        <Card.Body className="card-b">
                                            <div className="d-flex align-items-center justify-content-center mb-4">
                                                <Magic size={60} className="text-primary" />
                                            </div>
                                            <Card.Title className="d-flex justify-content-center">
                                                <h3>Realize o Serviço</h3>
                                            </Card.Title>
                                            <Card.Text className="text-black">
                                                Converse com o solicitante, combine a data para a
                                                realização do serviço
                                            </Card.Text>
                                        </Card.Body>
                                    </Card>
                                </Col>
                            </Row>
                        </div>
                        <br></br>
                        <div className="container-title d-flex align-items-center justify-content-center mb-4">
                            <h3 className="home-page-title-certainty-text text-secondary blue">
                                Para Contratantes
                            </h3>
                        </div>
                        <div className="d-flex flex-direction-row justify-content-center gap-3">
                            <Row className="d-flex justify-content-center align-items-center">
                                <Col
                                    lg={4}
                                    xl={4}
                                    sm={12}
                                    className="d-flex justify-content-center align-items-center mb-2"
                                >
                                    <Card
                                        style={{
                                            width: '18rem',
                                            height: '300px',
                                        }}
                                    >
                                        <Card.Body className="card-b">
                                            <div className="d-flex card-icon mb-4">
                                                <Clipboard2Check
                                                    size={60}
                                                    className="text-primary"
                                                />
                                            </div>
                                            <Card.Title className="d-flex justify-content-center">
                                                <h3>Cadastre-se</h3>
                                            </Card.Title>
                                            <Card.Text className="text-black">
                                                Descubra Prestadores na sua cidade e faça
                                                solicitações de serviço para quem você preferir!
                                            </Card.Text>
                                        </Card.Body>
                                    </Card>
                                </Col>
                                <Col
                                    lg={4}
                                    xl={4}
                                    sm={12}
                                    className="d-flex justify-content-center align-items-center mb-2"
                                >
                                    <Card
                                        style={{
                                            width: '18rem',
                                            height: '300px',
                                        }}
                                    >
                                        <Card.Body className="card-b">
                                            <div className="d-flex card-icon mb-4">
                                                <ChatDots size={60} className="text-primary" />
                                            </div>
                                            <Card.Title className="d-flex justify-content-center">
                                                <h3>Interaja</h3>
                                            </Card.Title>
                                            <Card.Text className="text-black">
                                                Explore o Portfólio do Prestador escolhido, solicite
                                                serviços, interaja, faça avaliações e efetue
                                                pagamentos.
                                            </Card.Text>
                                        </Card.Body>
                                    </Card>
                                </Col>
                                <Col
                                    lg={4}
                                    xl={4}
                                    sm={12}
                                    className="d-flex justify-content-center align-items-center mb-2"
                                >
                                    <Card
                                        style={{
                                            width: '18rem',
                                            height: '300px',
                                        }}
                                    >
                                        <Card.Body className="card-b">
                                            <div className="d-flex card-icon mb-4">
                                                <HandThumbsUp size={60} className="text-primary" />
                                            </div>
                                            <Card.Title className="d-flex justify-content-center">
                                                <h3>Avalie</h3>
                                            </Card.Title>
                                            <Card.Text className="text-black">
                                                Após a conclusão do serviço, solicitamos que avalie
                                                o Prestador. Isso melhora o ranqueamento do mesmo.
                                            </Card.Text>
                                        </Card.Body>
                                    </Card>
                                </Col>
                            </Row>
                        </div>
                    </div>
                </Row>
            </Container>
        </section>
    );
};

export default HowItWorks;
