// import node module libraries
import { Fragment } from 'react';
import { Container, Row, Col } from 'react-bootstrap';

// Import Style Component
import styled from 'styled-components';

const Span = styled.span`
	font-size: 16px;
	line-height: 1.8;
`;

const H3 = styled.h3`
	font-size: 23px;
	font-weight: bold;
	margin-top: 40px;
`;

const H5 = styled.h5`
	font-size: 18px;
	font-weight: bold;
	margin-top: 40px;
`;

const CookiesPolicy = () => {
	return (
		<Fragment>
			<Container>
				<Col>
					<Row>
						<H3 className="cookies-policy-title">Política de Cookies</H3>
					</Row>
					<Row>
						<Span className="cookies-policy-description">
							Nossa Política de Cookies explica como usamos cookies e tecnologias similares para
							melhorar sua experiência na Prestadio. Ao usar nosso site, você concorda com o uso de
							cookies de acordo com esta política.
						</Span>
					</Row>
					<Row>
						<H5 className="cookies-policy-title">Cookies Necessários</H5>
					</Row>
					<Row>
						<Span className="cookies-policy-description">
							Essenciais para o funcionamento básico da plataforma, esses cookies permitem que você
							navegue e utilize os recursos.
						</Span>
					</Row>
					<Row>
						<H5 className="cookies-policy-title">Cookies de Desempenho</H5>
					</Row>
					<Row>
						<Span className="cookies-policy-description">
							Permitem que analisemos o desempenho do nosso site, aprimorando sua usabilidade e
							eficiência.
						</Span>
					</Row>
					<Row>
						<H5 className="cookies-policy-title">Cookies de Funcionalidade</H5>
					</Row>
					<Row>
						<Span className="cookies-policy-description">
							Aprimoram a sua experiência ao lembrar suas preferências e personalizações.
						</Span>
					</Row>
					<Row>
						<H5 className="cookies-policy-title">Cookies de Publicidade</H5>
					</Row>
					<Row>
						<Span className="cookies-policy-description">
							São utilizados para apresentar anúncios relevantes para você, tanto dentro quanto fora
							da plataforma Prestadio.
						</Span>
					</Row>
					<Row>
						<Span className="cookies-policy-description">
							Você pode gerenciar as preferências de cookies em suas configurações de navegador,
							permitindo que você opte por não aceitar certos tipos de cookies. No entanto, isso
							pode afetar a funcionalidade e a experiência geral em nossa plataforma.
						</Span>
					</Row>
					<Row>
						<H5 className="cookies-policy-title">Utilização de Cookies para o Usuário</H5>
					</Row>
					<Row>
						<Span className="cookies-policy-description">
							A plataforma Prestadio utiliza cookies para melhorar a sua experiência como usuário.
							Cookies são pequenos arquivos de texto que são armazenados em seu dispositivo quando
							você visita nosso site. Eles desempenham um papel importante em fornecer uma
							experiência personalizada e eficiente em nossa plataforma.
						</Span>
					</Row>
					<Row>
						<H5 className="cookies-policy-title">Tipos de Cookies Utilizados</H5>
					</Row>
					<Row>
						<Span className="cookies-policy-description">
							<b>Cookies Necessários:</b> Essenciais para o funcionamento básico da plataforma. Eles
							permitem que você navegue pelo site e utilize os recursos, como acessar áreas seguras
							e concluir transações.
							<br />
							<br />
							<b>Cookies de Desempenho:</b> Esses cookies nos ajudam a entender como os usuários
							interagem com nosso site, coletando informações sobre as páginas visitadas, o tempo
							gasto nelas e eventuais mensagens de erro. Isso nos ajuda a melhorar a usabilidade e a
							eficiência do Prestadio.
							<br />
							<br />
							<b>Cookies de Funcionalidade:</b> Estes cookies permitem que a plataforma se lembre
							das escolhas que você fez (como nome de usuário, idioma ou região) para proporcionar
							uma experiência mais personalizada. Eles também podem ser usados para lembrar
							alterações que você fez no tamanho do texto, fontes e outras partes das páginas web
							que podem ser personalizadas.
							<br />
							<br />
							<b>Cookies de Publicidade:</b> Utilizados para apresentar anúncios relevantes para
							você, tanto dentro da plataforma Prestadio quanto em sites de terceiros. Eles ajudam a
							tornar os anúncios mais direcionados e significativos para seus interesses.
							<br />
						</Span>
					</Row>
					<Row>
						<H5 className="cookies-policy-title">Gerenciamento de Cookies</H5>
					</Row>
					<Row>
						<Span className="cookies-policy-description">
							Você tem o controle sobre o uso de cookies em nosso site. Você pode ajustar suas
							preferências de cookies nas configurações do seu navegador. Lembre-se de que desativar
							certos cookies pode afetar a funcionalidade do Prestadio e sua experiência como
							usuário.
						</Span>
					</Row>
					<Row>
						<H5 className="cookies-policy-title">Mais Informações</H5>
					</Row>
					<Row>
						<Span className="cookies-policy-description">
							Para obter mais informações sobre como usamos cookies e como protegemos sua
							privacidade, consulte nossa Política de Privacidade completa.
							<br />
							<br />
							Se tiver alguma dúvida ou preocupação sobre o uso de cookies ou nossa política de
							privacidade, entre em contato conosco através dos canais fornecidos na plataforma.
							<br />
							<br />
							Data de vigência: 01/09/2024
							<br />
							<br />
							<br />
							<br />
						</Span>
					</Row>
				</Col>
			</Container>
		</Fragment>
	);
};

export default CookiesPolicy;
