/* eslint-disable import/no-unused-modules */

import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Form } from 'react-bootstrap';
import axios from 'axios';
import { FaRegTrashAlt } from 'react-icons/fa';
import { FaRegCopy } from 'react-icons/fa';

import inputNumberMask, {
    cpfMask,
    creditCardMask,
    cvvMask,
    expirationDateMask,
    unMask,
} from 'helper/masks';

import './styles.css';

const Payment = ({ onClose, name, recipientId, serviceValue, isService }) => {
    const url = process.env.REACT_APP_API_URL;
    const cardToken = process.env.REACT_APP_API_STRAPI;
    const hash = localStorage.getItem('hash');

    const [steps, setSteps] = useState(0);
    const [errors, setErrors] = useState([]);
    const [type, setType] = useState('');
    const [cardId, setCardToken] = useState('');
    const [saveCard, setSaveCard] = useState(false);
    const [copy, setCopy] = useState(false);
    const [cardSelected, setCard] = useState();
    const [response, setResponse] = useState();
    const [cards, setCards] = useState([]);
    const [value, setValue] = useState(
        serviceValue ? inputNumberMask(String(serviceValue.toFixed(2))) : ''
    );

    const { register, handleSubmit, watch } = useForm();

    const getCards = async () => {
        const config = {
            headers: {
                Authorization: `Bearer ${hash}`,
            },
        };

        const response = await axios.get(`${url}stripe/cards`, config);
        setCards(response.data);
    };

    useEffect(() => {
        getCards();
    }, []);

    const onSubmit = async (form) => {
        try {
            const config = {
                headers: {
                    Authorization: `Bearer ${hash}`,
                },
            };

            const methods = {
                boleto: 'boleto',
                credit: 'card',
                pix: 'pix',
            };

            const response = await axios.post(
                `${url}stripe/generate-payment`,
                {
                    name: form.name,
                    value: Number(value.replace(',', '.')),
                    recipientId: recipientId,
                    method: methods[type],
                    cardId:
                        cardSelected?._id !== 'outher' ? cardSelected?._id : undefined || undefined,
                    cardToken: cardSelected?._id === 'outher' ? cardId : undefined,
                },
                config
            );

            setResponse(response.data);
            setSteps(3);
        } catch (error) {
            if (error?.response?.data?.errorCode.includes('was declined')) {
                setErrors(['declined']);
            }

            if (error?.response?.data?.errorCode.includes('been used')) {
                setErrors(['used']);
            }

            if (error?.response?.data?.errorCode.includes('invalid')) {
                setErrors(['invalid']);
            }
        }
    };

    const onDeleteCard = async (id) => {
        const config = {
            headers: {
                Authorization: `Bearer ${hash}`,
            },
        };

        await axios.delete(`${url}stripe/cards/${id}`, config);

        getCards();
    };

    const registerCard = async () => {
        const values = watch();

        const config = {
            headers: {
                Authorization: `Bearer ${cardToken}`,
                'Content-Type': 'application/x-www-form-urlencoded',
            },
        };

        if (!values.number || !values.nameCard || !values.cpf || !values.cvv || !values.validate) {
            setErrors(['card']);
            return;
        }

        await axios
            .post(
                'https://api.stripe.com/v1/tokens',
                {
                    'card[name]': values.nameCard,
                    'card[number]': unMask(values.number),
                    'card[exp_month]': Number(values.validate.split('/')[0]),
                    'card[exp_year]': Number(`20${values.validate.split('/')[1]}`),
                    'card[cvc]': values.cvv,
                },
                config
            )
            .then(async (response) => {
                if (saveCard) {
                    const responseCard = await axios.post(
                        `${url}stripe/cards`,
                        {
                            cardToken: response.data.id,
                        },
                        {
                            headers: {
                                Authorization: `Bearer ${hash}`,
                            },
                        }
                    );

                    setCard({ _id: responseCard.data });
                }

                setCardToken(response.data.id);
                setErrors([]);
                setSteps(2);
            })
            .catch((error) => {
                if (error.response.data.error.message.includes('invalid')) {
                    setErrors(['invalid']);
                }
            });
    };

    const nextFirstForm = () => {
        setErrors([]);
        const values = watch();

        if (!values.name || value === '') {
            if (!values.name) {
                setErrors((props) => [...props, 'name']);
            }

            if (value === '') {
                setErrors((props) => [...props, 'value']);
            }

            return;
        }

        setSteps(1);
    };

    const nextSecondForm = () => {
        setErrors([]);

        if (type === '') {
            setErrors(['type']);
            return;
        }

        if (type === 'credit' && !cardSelected) {
            setErrors(['type']);
            return;
        }

        if (type === 'credit' && cardSelected?._id === 'outher') {
            setErrors([]);
            registerCard();
            return;
        }

        setSteps(2);
    };

    const methods = {
        boleto: 'Boleto bancário',
        credit: 'Cartão de crédito',
        pix: 'Pix',
    };

    return (
        <div className="content-modal">
            {steps === 0 && (
                <div className="modal">
                    <div className="header">
                        Pagar
                        <i className="fe fe-x" onClick={onClose} />
                    </div>

                    <div className="content">
                        <div className="text">
                            Qual valor você deseja enviar para{' '}
                            <span className="stronger">{name}</span>?
                        </div>

                        <input
                            type="text"
                            className="input"
                            placeholder="R$ 0,00"
                            style={errors.includes('value') ? { border: '1px solid #ff0000' } : {}}
                            value={value}
                            disabled={isService}
                            onChange={(e) => {
                                if (errors.includes('value')) {
                                    setErrors(errors.filter((item) => item !== 'value'));
                                }
                                setValue(inputNumberMask(e.target.value));
                            }}
                        />

                        <div className="text">Descreva o serviço</div>

                        <input
                            type="text"
                            className="input"
                            placeholder="Descrição"
                            style={errors.includes('name') ? { border: '1px solid #ff0000' } : {}}
                            {...register('name')}
                        />

                        <div className="section-button">
                            <button className="button" onClick={nextFirstForm}>
                                Próximo
                            </button>
                        </div>
                    </div>
                </div>
            )}

            {steps === 1 && (
                <div className="modal">
                    <div className="header">
                        Pagar
                        <i className="fe fe-x" onClick={onClose} />
                    </div>

                    <div className="content">
                        <div className="text">Qual o método de pagamento?</div>

                        <div className="row">
                            <div className="field">
                                <Form.Check type="checkbox" className="mb-2">
                                    <Form.Check.Input
                                        type="checkbox"
                                        checked={type === 'credit'}
                                        onChange={() => {
                                            setErrors([]);
                                            setType('credit');
                                        }}
                                    />
                                </Form.Check>
                                Cartão de crédito
                            </div>

                            <div className="field">
                                <Form.Check type="checkbox" className="mb-2">
                                    <Form.Check.Input
                                        type="checkbox"
                                        checked={type === 'boleto'}
                                        onChange={() => {
                                            setErrors([]);
                                            setType('boleto');
                                        }}
                                    />
                                </Form.Check>
                                Boleto bancário
                            </div>
                            {/* 
                            <div className="field">
                                <Form.Check type="checkbox" className="mb-2">
                                    <Form.Check.Input
                                        type="checkbox"
                                        checked={type === 'pix'}
                                        onChange={() => {
                                            setErrors([]);
                                            setType('pix');
                                        }}
                                    />
                                </Form.Check>
                                Pix
                            </div> */}
                        </div>

                        {type === 'credit' && (
                            <div
                                style={{ display: 'flex', flexDirection: 'column', gap: '0.3rem' }}
                            >
                                {cards.map((item) => (
                                    <div
                                        className="cards"
                                        style={
                                            cardSelected?._id === item._id
                                                ? { background: '#DCF0FF' }
                                                : {}
                                        }
                                    >
                                        <Form.Check type="checkbox" className="mb-2">
                                            <Form.Check.Input
                                                type="checkbox"
                                                checked={cardSelected?._id === item._id || false}
                                                style={{ marginRight: '10px' }}
                                                onChange={() => {
                                                    setErrors([]);
                                                    setCard(item);
                                                }}
                                            />
                                            {item.brand}, **** **** **** {item.lastDigits}
                                        </Form.Check>

                                        <FaRegTrashAlt
                                            size={20}
                                            color="#ff0000"
                                            className="icon"
                                            onClick={() => onDeleteCard(item._id)}
                                        />
                                    </div>
                                ))}
                                <div
                                    className="outher-wrapper"
                                    style={
                                        cardSelected?._id === 'outher'
                                            ? { background: '#DCF0FF' }
                                            : {}
                                    }
                                >
                                    <div className="outher" style={{ padding: '15px 10px 0 10px' }}>
                                        <Form.Check type="checkbox" className="mb-2">
                                            <Form.Check.Input
                                                type="checkbox"
                                                checked={cardSelected?._id === 'outher' || false}
                                                onChange={() => {
                                                    setErrors([]);
                                                    setCard({ _id: 'outher' });
                                                }}
                                            />
                                        </Form.Check>
                                        Outro cartão
                                    </div>
                                    {cardSelected?._id === 'outher' && (
                                        <>
                                            <div className="outher">
                                                <div className="outher-field">
                                                    Nome do titular
                                                    <input
                                                        type="text"
                                                        className="input"
                                                        placeholder="Insira o nome"
                                                        {...register('nameCard')}
                                                    />
                                                </div>
                                                <div className="outher-field">
                                                    CPF
                                                    <input
                                                        type="text"
                                                        className="input"
                                                        placeholder="Insira o CPF"
                                                        onInput={(e) => {
                                                            e.target.value = cpfMask(
                                                                e.target.value
                                                            );
                                                        }}
                                                        {...register('cpf')}
                                                    />
                                                </div>
                                            </div>

                                            <div className="outher-field">
                                                Número do cartão
                                                <input
                                                    type="text"
                                                    className="input"
                                                    placeholder="Insira o número do cartão"
                                                    onInput={(e) => {
                                                        e.target.value = creditCardMask(
                                                            e.target.value
                                                        );
                                                    }}
                                                    {...register('number')}
                                                />
                                            </div>

                                            <div className="outher">
                                                <div className="outher-field">
                                                    Código de segurança
                                                    <input
                                                        type="number"
                                                        className="input"
                                                        placeholder="Insira o CVV"
                                                        onInput={(e) => {
                                                            e.target.value = cvvMask(
                                                                e.target.value
                                                            );
                                                        }}
                                                        {...register('cvv')}
                                                    />
                                                </div>
                                                <div className="outher-field">
                                                    Vencimento
                                                    <input
                                                        type="text"
                                                        className="input"
                                                        placeholder="Insira a validade"
                                                        onInput={(e) => {
                                                            e.target.value = expirationDateMask(
                                                                e.target.value
                                                            );
                                                        }}
                                                        {...register('validate')}
                                                    />
                                                </div>
                                            </div>

                                            <div
                                                className="outher"
                                                style={{ padding: '15px 10px 0 10px' }}
                                            >
                                                <Form.Check type="checkbox" className="mb-2">
                                                    <Form.Check.Input
                                                        type="checkbox"
                                                        checked={saveCard}
                                                        onChange={() => setSaveCard(!saveCard)}
                                                    />
                                                </Form.Check>
                                                Salvar cartão para futuras compras
                                            </div>

                                            {errors.includes('card') && (
                                                <div
                                                    className="error"
                                                    style={{ paddingLeft: '10px' }}
                                                >
                                                    Preencha todos os campos
                                                </div>
                                            )}

                                            {errors.includes('invalid') && (
                                                <div
                                                    className="error"
                                                    style={{ paddingLeft: '10px' }}
                                                >
                                                    Dados inválidos.
                                                </div>
                                            )}
                                        </>
                                    )}
                                </div>
                            </div>
                        )}

                        {errors.includes('type') && (
                            <div className="error">Selecione uma opção</div>
                        )}

                        <div className="section-button">
                            <button className="button-outlined" onClick={() => setSteps(steps - 1)}>
                                Voltar
                            </button>
                            <button className="button" onClick={nextSecondForm}>
                                Próximo
                            </button>
                        </div>
                    </div>
                </div>
            )}

            {steps === 2 && (
                <div className="modal">
                    <div className="header">
                        Pagar
                        <i className="fe fe-x" onClick={onClose} />
                    </div>

                    <div className="content">
                        <div className="text">Confirme as informações</div>

                        <div className="text">Para</div>

                        <div className="stronger">{name}</div>

                        <hr className="divider" />

                        <div className="text">Método de pagamento</div>

                        <div className="stronger">{methods[type]}</div>

                        {type === 'credit' && (
                            <div className="text" style={{ marginTop: '-1rem' }}>
                                {cardSelected?.brand && `${cardSelected?.brand},`} final:{' '}
                                {cardSelected?.lastDigits || watch('number').slice(-4)}
                            </div>
                        )}

                        <hr className="divider" />

                        <div className="text">Valor</div>

                        <div className="stronger">R$ {String(value || 0)}</div>

                        <hr className="divider" />

                        {errors.includes('declined') && (
                            <div className="error">Cartão recusado.</div>
                        )}

                        {errors.includes('used') && <div className="error">Cartão utilizado.</div>}

                        <div className="section-button">
                            <button className="button-outlined" onClick={() => setSteps(steps - 1)}>
                                Voltar
                            </button>
                            <button className="button" onClick={handleSubmit(onSubmit)}>
                                Finalizar
                            </button>
                        </div>
                    </div>
                </div>
            )}

            {steps === 3 && (
                <div className="modal">
                    <div className="header">
                        Pagar
                        <i className="fe fe-x" onClick={onClose} />
                    </div>

                    {type === 'boleto' && (
                        <div className="content">
                            <div className="text">
                                Copie o código a baixo e realize o pagamento no banco de sua
                                preferência
                            </div>

                            <div className="boleto">
                                <img src="/barCode.png" alt="" className="image" />
                                {response.charge.next_action.boleto_display_details.number}
                            </div>

                            {copy && (
                                <div style={{ color: 'green', textAlign: 'center' }}>
                                    <FaRegCopy size={16} style={{ marginRight: '5px' }} />
                                    Código copiado com sucesso.
                                </div>
                            )}

                            <div className="section-button" style={{ justifyContent: 'center' }}>
                                <button
                                    className="button"
                                    onClick={() => {
                                        window.open(
                                            response.charge.next_action.boleto_display_details
                                                .hosted_voucher_url,
                                            '_blank'
                                        );
                                    }}
                                >
                                    Fazer download
                                </button>
                                <button
                                    className="button"
                                    onClick={() => {
                                        navigator.clipboard.writeText(
                                            response.charge.next_action.boleto_display_details
                                                .number
                                        );
                                        setCopy(true);
                                    }}
                                >
                                    Copiar código
                                </button>
                            </div>

                            <div className="section-button">
                                <button className="button" onClick={onClose}>
                                    Fechar
                                </button>
                            </div>
                        </div>
                    )}

                    {type === 'credit' && (
                        <div className="content">
                            <div className="text">
                                Pagamento <span className="stronger">confirmado!</span>
                            </div>

                            <div className="text">
                                Você enviou <span className="stronger">R$ {value}</span> para{' '}
                                <span className="stronger">{name}</span> com{' '}
                                <span className="stronger">sucesso!</span>
                            </div>

                            <div className="section-button">
                                <button className="button" onClick={onClose}>
                                    Fechar
                                </button>
                            </div>
                        </div>
                    )}
                </div>
            )}
        </div>
    );
};

export default Payment;
