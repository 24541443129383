// import node module libraries
import { Outlet } from 'react-router-dom';
import { Fragment, useEffect, useState } from 'react';

// import layouts
import NavbarMegaMenu from 'layouts/marketing/navbars/mega-menu/NavbarMegaMenu';
import FooterWithLinks from 'layouts/marketing/footers/FooterWithLinks';
import NavbarDefault from 'layouts/marketing/navbars/NavbarDefault';

import axios from 'axios';

const LandingPageLayout = (props) => {
    const hash = localStorage.getItem('hash');
    const url = process.env.REACT_APP_API_URL;
    const [login, setLogin] = useState(false);

    const getLoggedUser = async () => {
        const config = {
            headers: {
                Authorization: `Bearer ${hash}`,
            },
        };

        axios
            .get(`${url}accounts/authentication/logged-user`, config)
            .then((response) => {
                // Atualiza o estado com os dados da resposta da API
                setLogin(true);
            })
            .catch((error) => {
                setLogin(false);
            });
    };

    useEffect(() => {
        getLoggedUser();
    }, []);
    return (
        <Fragment>
            {/* Default Navbar */}
            {login == true ? <NavbarDefault /> : <NavbarMegaMenu />}
            {/*comentario*/}
            <main>
                {props.children}
                <Outlet />
            </main>
            {/* Footer section */}
            <FooterWithLinks />
        </Fragment>
    );
};

export default LandingPageLayout;
