// import node module libraries
import { Fragment } from 'react';
import { Col, Row, Container, Tab, Nav } from 'react-bootstrap';

import HowItWorks from './HowItWorks';

const HomeAcademy = () => {
    return (
        <Fragment>
            {/* Hero Academy banner section */}

            {/* Various acedamy statistics  */}
            {/* <AcademyStats /> */}

            {/* Cards */}
            {/* <HomeCards /> */}

            <HowItWorks></HowItWorks>

            {/* Most Popular Courses */}
            {/* <MostPopularCourses /> */}

            {/* Become an instructor */}
            {/*<BecomeAnInstructor /> */}

            {/* What our customers say
            <WhatCustomersSay /> */}
        </Fragment>
    );
};
export default HomeAcademy;
