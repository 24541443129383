import notificationActionTypes from './action-types';

const initialState = {
    refresh: 0, // Inicializa o estado como um número
};

const notificationReducer = (state = initialState, action) => {
    if (action.type === notificationActionTypes.REFRESH_MESSAGES) {
        // Incrementa o estado atual e retorna um novo estado
        ('eae');
        return { ...state, refresh: state.refresh + 1 };
    }

    return state;
};

export default notificationReducer;
