/* eslint-disable import/no-unused-modules */
const inputNumberMask = (value) => {
    let newValue = value.replace(/[^0-9]/g, '');
    return `${newValue
        .slice(0, newValue.length - 2)
        .replace(/^0+/, '')
        .padStart(1, '0')},${newValue
        .slice(newValue.length - 2, newValue.length)

        .padStart(2, '0')}`;
};

export default inputNumberMask;

export const inputNumberedMask = (value) => {
    return value.toLocaleString('pt-BR', {
        style: 'currency',
        currency: 'BRL',
    });
};

export const cpfMask = (value) => {
    return value
        .replace(/[^0-9.-]/g, '')
        .replace(/\D/g, '')
        .replace(/(\d{3})(\d)/, '$1.$2')
        .replace(/(\d{3})(\d)/, '$1.$2')
        .replace(/(\d{3})(\d)/, '$1-$2')
        .slice(0, 14);
};

export const creditCardMask = (value) => {
    return value
        .replace(/[^0-9]/g, '')
        .replace(/(.{4})/g, '$1 ')
        .trim()
        .slice(0, 19);
};

export const cvvMask = (value) => {
    return value.replace(/[^0-9]/g, '').slice(0, 3);
};

export const expirationDateMask = (value) => {
    return value
        .replace(/[^0-9]/g, '')
        .replace(/^(\d{2})(\d)/, '$1/$2')
        .replace(/(\d{2})(\d)/, '$1/$2')
        .slice(0, 5);
};

export const unMask = (value) => {
    return value?.replace(/\D/g, '');
};
