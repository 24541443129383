// import node module libraries
import { Fragment, useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Col, Row, Card, Form, Button, Image, Alert } from 'react-bootstrap';
import axios from 'axios';
// import media files
import Logo from 'assets/images/brand/logo/logo_prestadio_grande.png';

const EmailSent = () => {
    const navigate = useNavigate();

    useEffect(() => {
        const timer1 = setTimeout(() => navigate('/'), 20000);

        return () => {
            clearTimeout(timer1);
        };
    }, []);

    return (
        <Fragment>
            <Row className="align-items-center justify-content-center g-0 min-vh-100">
                <Col lg={5} md={5} className="py-8 py-xl-0 signin">
                    <Card>
                        <Card.Body className="p-6">
                            <div className="mb-4">
                                <Link to="/">
                                    <div id="div-logo-grande">
                                        <Link to="/">
                                            <Image
                                                src={Logo}
                                                className="mb-1"
                                                alt=""
                                                id="logo-grande"
                                            />
                                        </Link>
                                    </div>
                                    <hr className="my-4" />
                                </Link>
                                <h2 className="mb-1 fw-bold">E-mail de recuperação enviado</h2>
                                <span>
                                    Verifique sua caixa de email e acesse o link enviado para
                                    redefinir sua senha
                                </span>
                            </div>
                            <Form>
                                <hr className="my-4" />
                                <span>
                                    Voltar para o <Link to="/authentication/sign-in">login</Link>
                                </span>
                            </Form>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </Fragment>
    );
};

export default EmailSent;