// import node module libraries
import React, { useEffect, useState, useContext, useRef } from 'react';
import { Col, Row, Container, Tab, Modal, Button } from 'react-bootstrap';
import { Fragment } from 'react';
import { Avatar } from 'components/elements/bootstrap/Avatar';
import axios from 'axios';

// import styles
import './styles/styles.css';
import { CameraVideo, CameraVideoOff, Mic, MicMute } from 'react-bootstrap-icons';

const VideoChat = () => {
    const isMobileDevice = /Mobi|Android/i.test(navigator.userAgent);
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);

    const handleResize = () => {
        setWindowWidth(window.innerWidth);
    };

    useEffect(() => {
        // Adiciona um listener para o evento de redimensionamento da janela
        window.addEventListener('resize', handleResize);

        // Remove o listener quando o componente é desmontado
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    const [error, setError] = useState(null);

    const [haveMicrophone, setHaveMicrophone] = useState(false);
    const [haveCamera, setHaveCamera] = useState(false);
    const [cameraError, setCameraError] = useState('');
    const [microphoneError, setMicrophoneError] = useState('');

    const videoRef = useRef(null);
    const videoRef2 = useRef(null);
    const audioRef = useRef(null);
    const [stream, setStream] = useState(null);
    const [micPermission, setMicPermission] = useState(false);
    const [cameraPermission, setCameraPermission] = useState(false);
    const [isMicrophoneMuted, setIsMicrophoneMuted] = useState(false);
    const [isCameraMuted, setIsCameraMuted] = useState(false);

    useEffect(() => {
        const getMedia = async () => {
            console.log('getting media');
            try {
                // Obtemos tanto vídeo quanto áudio
                const mediaStream = await navigator.mediaDevices.getUserMedia({
                    video: cameraPermission,
                    audio: micPermission,
                });
                setStream(mediaStream);
                videoRef.current.srcObject = mediaStream;
                audioRef.current.srcObject = mediaStream;
            } catch (error) {
                console.error('Error accessing media devices:', error);
                // Trate o erro aqui conforme necessário
                setCameraPermission(false);
            }
        };

        if (cameraPermission || micPermission) {
            getMedia();
        }

        return () => {
            // Limpar todas as trilhas
            if (stream) {
                const tracks = stream.getTracks();
                tracks.forEach((track) => track.stop());
            }
        };
    }, [cameraPermission, micPermission]);

    const toggleMicrophoneMute = () => {
        setMicPermission(!micPermission);
        if (stream) {
            const audioTracks = stream.getAudioTracks();
            audioTracks.forEach((track) => {
                track.enabled = !isMicrophoneMuted;
            });
            setIsMicrophoneMuted((prevState) => !prevState); // Atualização do estado corrigida
        }
    };

    const toggleCameraMute = () => {
        setCameraPermission(!cameraPermission);
        if (stream) {
            const videoTracks = stream.getVideoTracks();
            videoTracks.forEach((track) => {
                track.enabled = !isCameraMuted;
            });
            setIsCameraMuted((prevState) => !prevState); // Atualização do estado corrigida
        }
        console.log(isCameraMuted);
    };

    console.log(isCameraMuted);

    const url = process.env.REACT_APP_API_URL;
    const hash = localStorage.getItem('hash');
    const [loggedUser, setLoggedUser] = useState();
    const getLoggedUser = async () => {
        const config = {
            headers: {
                Authorization: `Bearer ${hash}`,
            },
        };

        await axios
            .get(`${url}accounts/authentication/logged-user`, config)
            .then(function (response) {
                setLoggedUser(response.data);
                console.log(response.data);
                // response.data;
            })
            .catch(function (error) {});
    };

    useEffect(() => getLoggedUser(), []);

    const [showAlertModal, setShowModalAlert] = useState(false);
    const handleClose = () => setShowModalAlert(false);
    const handleShow = () => setShowModalAlert(true);
    const ModalAlert = () => {
        return (
            <Modal show={showAlertModal} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Modal title</Modal.Title>
                </Modal.Header>
                <Modal.Body>Woohoo, you're reading this text in a modal!</Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Close
                    </Button>
                    <Button variant="primary" onClick={handleClose}>
                        Save Changes
                    </Button>
                </Modal.Footer>
            </Modal>
        );
    };

    return (
        // <MaintenanceComponent />
        <Fragment>
            <ModalAlert />
            <section className="videochat">
                <Row className="videochat-container">
                    <Col md={12} sm={12} lg={12}>
                        <div className="grid-container">
                            <div className="grid-item user-1">
                                <div className="user-info">
                                    <span>Nome de usuário</span>
                                    {micPermission == true ? <Mic /> : <MicMute />}
                                </div>
                                {cameraPermission == true ? (
                                    <video
                                        ref={videoRef}
                                        autoPlay
                                        playsInline
                                        className="video-container"
                                        muted={isCameraMuted}
                                    />
                                ) : (
                                    <div className="circulo-usuario">
                                        <Avatar
                                            size="lg"
                                            className="rounded-circle foto-usuario"
                                            type="image"
                                            src={loggedUser?.profilePicture?.url}
                                        />
                                    </div>
                                )}
                            </div>
                            <div className="grid-item user-2">
                                <div className="user-info">
                                    <span>Nome de usuário</span>
                                    {micPermission == true ? <Mic /> : <MicMute />}
                                </div>
                                <audio ref={audioRef} autoPlay />
                                <video
                                    ref={videoRef2}
                                    autoPlay
                                    playsInline
                                    className="video-container"
                                />{' '}
                            </div>
                        </div>
                        <div className="videochat-actions">
                            <div className="videochat-buttons">
                                {cameraPermission == false ? (
                                    <CameraVideoOff
                                        className="camera-inactive"
                                        onClick={toggleCameraMute}
                                    />
                                ) : (
                                    <CameraVideo
                                        className="camera-active"
                                        onClick={toggleCameraMute}
                                    />
                                )}
                                {micPermission == false ? (
                                    <MicMute
                                        className="mic-inactive"
                                        onClick={toggleMicrophoneMute}
                                    />
                                ) : (
                                    <Mic className="mic-active" onClick={toggleMicrophoneMute} />
                                )}
                            </div>
                            <div></div>
                            <div>
                                <span>
                                    <strong>f47ac10b-58cc-4372-a567-0e02b2c3d479</strong>
                                </span>
                            </div>
                        </div>
                    </Col>
                </Row>
            </section>
        </Fragment>
    );
};

export default VideoChat;
