/* eslint-disable import/no-unused-modules */
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Image, ListGroup, Spinner } from 'react-bootstrap';
import { BellFill, ChatDotsFill, CheckAll, FileEarmark } from 'react-bootstrap-icons';
import axios from 'axios';

import './styles.css';

import UserImage from 'assets/images/avatar/user.png';

const AllChatsBottom = () => {
    const dispatch = useDispatch();

    const url = process.env.REACT_APP_API_URL;

    const [chats, setChats] = useState();
    const [loading, setLoading] = useState(true);
    const [expanded, setExpanded] = useState(false);
    const [minimize, setMinimize] = useState(true);

    const { currentChat } = useSelector((rootReducer) => rootReducer.chatReducer);
    const { refresh } = useSelector((rootReducer) => rootReducer.notificationReducer);

    const hash = localStorage.getItem('hash');

    const [loggedUser, setLoggedUser] = useState();

    const getLoggedUser = async () => {
        const config = {
            headers: {
                Authorization: `Bearer ${hash}`,
            },
        };

        await axios
            .get(`${url}accounts/authentication/logged-user`, config)
            .then(function (response) {
                setLoggedUser(response.data);
            })
            .catch(function () {});
    };

    const sortByCreatedAt = (a, b) => {
        return new Date(b.createdAt) - new Date(a.createdAt);
    };

    const listAllChats = async () => {
        const config = {
            headers: {
                Authorization: `Bearer ${hash}`,
            },
        };

        await axios
            .get(`${url}messages/v2/list-all-chats`, config)
            .then(function (response) {
                setChats(response.data.sort(sortByCreatedAt));
            })
            .catch();
    };

    const handleChangeChat = (itemId, item) => {
        ('Trocando chat');
        dispatch({ type: 'chat/changeChat', payload: item });
        localStorage.setItem('CurrentChat', JSON.stringify(itemId._id));
    };

    useEffect(() => {
        setLoading(true);
        getLoggedUser();
        listAllChats();
        setLoading(false);
    }, [currentChat, expanded]);

    useEffect(() => {
        listAllChats();
    }, [refresh]);

    const minimizeChat = () => {
        setMinimize(!minimize);
        setExpanded(!expanded);
    };

    const loadingElement = () => {
        return (
            <div className="text-center">
                <Spinner animation="border" role="status"></Spinner>
            </div>
        );
    };

    return (
        <div className="mensagens-container">
            <div
                className={`mensagens-header ${
                    expanded ? 'expanded' : ''
                } align-items-center cursor-pointer`}
                onClick={minimizeChat}
            >
                <div className="my-messages-left ">
                    <div className="avatar avatar-sm">
                        <Image
                            alt="avatar"
                            src={
                                loggedUser?.profilePicture?.url
                                    ? loggedUser?.profilePicture?.url
                                    : UserImage
                            }
                            className="rounded-circle feed-avatar"
                        />
                    </div>
                    <h4>Mensagens</h4>
                </div>

                <div className="d-flex align-items-center gap-2">
                    {chats &&
                    chats.some(
                        (item) => item.seen === false && item?.userId !== loggedUser?._id
                    ) ? (
                        <div className="sininho">
                            <div className="sininho-bell">
                                <BellFill className="text-primary" />
                            </div>
                        </div>
                    ) : (
                        ''
                    )}

                    <i
                        className={`fe ${
                            minimize === false ? 'fe-chevron-down' : 'fe-chevron-up'
                        } cursor-pointer`}
                        style={{ fontSize: '24px' }}
                        onClick={minimizeChat}
                    ></i>
                </div>
            </div>

            {expanded &&
                (loading === false ? (
                    <div className="mensagens-lista">
                        <ListGroup variant="flush">
                            {chats &&
                                chats.map((item, index) => {
                                    const { user: originUser, destinationUser } = item;

                                    const chatUser =
                                        loggedUser._id === item.user._id
                                            ? destinationUser
                                            : originUser;

                                    return (
                                        <ListGroup.Item
                                            key={index}
                                            className={
                                                item?.seen === false &&
                                                item?.user._id !== loggedUser?._id
                                                    ? 'mensagem-item nova-mensagem'
                                                    : 'mensagem-item'
                                            }
                                            onClick={() => handleChangeChat(chatUser, item)}
                                        >
                                            <div className="mensagem-conteudo">
                                                <div className="d-flex align-items-center gap-2">
                                                    <div className="avatar avatar-lg">
                                                        <Image
                                                            alt="avatar"
                                                            src={
                                                                chatUser?.profilePicture
                                                                    ? chatUser?.profilePicture?.url
                                                                    : UserImage
                                                            }
                                                            className="rounded-circle feed-avatar"
                                                        />
                                                    </div>

                                                    <div className="chat-description">
                                                        <h4>
                                                            {chatUser?.firstName}{' '}
                                                            {chatUser?.lastName}
                                                        </h4>

                                                        <small className="chat-portfolioTitle">
                                                            {item?.seen === true ? (
                                                                <>
                                                                    <CheckAll className="text-primary" />{' '}
                                                                    {item?.last_message?.message ===
                                                                    null ? (
                                                                        <>
                                                                            Arquivo <FileEarmark />
                                                                        </>
                                                                    ) : (
                                                                        item?.last_message?.message
                                                                    )}
                                                                </>
                                                            ) : (
                                                                <h5 className="p-0">
                                                                    <strong>
                                                                        {' '}
                                                                        {item?.last_message
                                                                            ?.message === null ? (
                                                                            <>
                                                                                <strong>
                                                                                    Arquivo
                                                                                </strong>{' '}
                                                                                <FileEarmark />
                                                                            </>
                                                                        ) : (
                                                                            <div className="">
                                                                                <CheckAll className="text-dark m" />
                                                                                <span>
                                                                                    {
                                                                                        item
                                                                                            ?.last_message
                                                                                            ?.message
                                                                                    }
                                                                                </span>
                                                                            </div>
                                                                        )}
                                                                    </strong>
                                                                </h5>
                                                            )}
                                                        </small>
                                                    </div>
                                                </div>
                                                <div>
                                                    <span className="d-flex align-items-center">
                                                        {item?.seen === false &&
                                                        item?.user?._id !== loggedUser?._id ? (
                                                            <h3>
                                                                <ChatDotsFill className="text-primary" />
                                                            </h3>
                                                        ) : (
                                                            <h3>
                                                                <ChatDotsFill className="text-light" />
                                                            </h3>
                                                        )}
                                                    </span>
                                                </div>
                                            </div>
                                            <hr></hr>
                                        </ListGroup.Item>
                                    );
                                })}
                        </ListGroup>
                    </div>
                ) : (
                    loadingElement()
                ))}
        </div>
    );
};

export default AllChatsBottom;
