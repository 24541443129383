// import node module libraries
import { Fragment } from 'react';
import { Col, Row, Container, Tab, Nav } from 'react-bootstrap';

// images
import iconCerebro from '../../../../assets/images/homeCardsIcon/cerebro.png';
import iconAgilidade from '../../../../assets/images/homeCardsIcon/tempo-do-usuario.png';
import iconSeguranca from '../../../../assets/images/homeCardsIcon/verificacao-de-escudo.png';
import iconCirculo from '../../../../assets/images/homeCardsIcon/circulo-meio-curso.png';
import iconGrafico from '../../../../assets/images/homeCardsIcon/grafico-histograma.png';
import iconPesquisa from '../../../../assets/images/homeCardsIcon/pesquisar-alt.png';

// style
import Styles from './styles/styles.css';

const HomeCards = () => {
    return (
        <section className="py-lg-16 py-8 bg-gray-200">
            <Container className="container-home-cards-mobile">
                <div className="home-cards-card-container">
                    <div className="home-cards-card-wrapper">
                        <Row>
                            <img
                                alt=""
                                className="home-cads-icon"
                                src={iconAgilidade}
                            />
                        </Row>
                        <h3 className="home-cards-title">Agilidade</h3>
                        <span className="home-cards-description">
                            Rapidez e eficiência são essenciais. Conectamos
                            clientes e prestadores de serviço sem demora.
                        </span>
                    </div>
                    <div className="home-cards-card-wrapper">
                        <Row>
                            <img
                                alt=""
                                className="home-cads-icon"
                                src={iconSeguranca}
                            />
                        </Row>
                        <h3 className="home-cards-title">Segurança</h3>
                        <span className="home-cards-description">
                            Segurança e confiança em primeiro lugar. Protegemos
                            seus dados e transações com rigor e cuidado.
                        </span>
                    </div>
                    <div className="home-cards-card-wrapper">
                        <Row>
                            <img
                                alt=""
                                className="home-cads-icon"
                                src={iconCerebro}
                            />
                        </Row>
                        <h3 className="home-cards-title">
                            Visibilidade e Exposição
                        </h3>
                        <span className="home-cards-description">
                            Destaque-se na Prestadio. Mostre seu talento e
                            construa uma reputação sólida com avaliações
                            positivas.
                        </span>
                    </div>
                    <div className="home-cards-card-wrapper">
                        <Row>
                            <img
                                alt=""
                                className="home-cads-icon"
                                src={iconGrafico}
                            />
                        </Row>
                        <h3 className="home-cards-title">
                            Oportunidades de Negócio
                        </h3>
                        <span className="home-cards-description">
                            Encontre projetos perfeitos para suas habilidades.
                            Com a gente, novas oportunidades estão sempre ao seu
                            alcance.
                        </span>
                    </div>
                    <div className="home-cards-card-wrapper">
                        <Row>
                            <img
                                alt=""
                                className="home-cads-icon"
                                src={iconCirculo}
                            />
                        </Row>
                        <h3 className="home-cards-title">Facilidade de Uso</h3>
                        <span className="home-cards-description">
                            Simples e intuitivo. Aqui, criar seu perfil e
                            começar é fácil e rápido.
                        </span>
                    </div>
                </div>
            </Container>
        </section>
    );
};

export default HomeCards;
