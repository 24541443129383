// import node module libraries
import React, { useEffect, useState } from 'react';
import { Col, Row, Container, Tab } from 'react-bootstrap';
import { Fragment } from 'react';

// import sub components
import ServiceProvidersSearchBox from './ServiceProvidersSearchBox';
import ServiceProvidersFilters from './ServiceProvidersFilters';
import ServiceProvidersList from './ServiceProvidersList';

// import components
import CookiesConsent from 'components/elements/cookies-consent/CookiesConsent';
// import MaintenanceComponent from 'components/elements/maintenance/MaintenanceComponent';

// import styles
import './styles/styles.css';

const ListServiceProviders = () => {
	// const sortByOptions = [
	// 	{ value: 'newest', label: 'Mais Recentes' },
	// 	{ value: 'oldest', label: 'Mais Antigos' }
	// ];

	const isMobileDevice = /Mobi|Android/i.test(navigator.userAgent);

	const [windowWidth, setWindowWidth] = useState(window.innerWidth);

	const handleResize = () => {
		setWindowWidth(window.innerWidth);
	};

	useEffect(() => {
		// Adiciona um listener para o evento de redimensionamento da janela
		window.addEventListener('resize', handleResize);

		// Remove o listener quando o componente é desmontado
		return () => {
			window.removeEventListener('resize', handleResize);
		};
	}, []);

	return (
		// <MaintenanceComponent />
		<Fragment>
			<section className="py-4 bg-light p-1">
				<Container>
					<Row>
						<Col lg={12} md={12} xs={12} sm={12}>
							<div className="d-flex flex-column">
								<div className="d-flex align-items-center justify-content-start">
									<div className="mb-2 d-flex align-items-center justify-content-center">
										<h2 className="fw-bold mb-2 text-align-center">
											Faça acontecer <span className="text-primary">hoje</span>. Encontre o{' '}
											<span className="text-primary">Profissional</span> ideal para suas
											necessidades😁
										</h2>
									</div>
								</div>
							</div>
						</Col>
						<Col lg={6} md={6} xs={12} sm={12}>
							<div>
								<ServiceProvidersSearchBox />
							</div>
						</Col>
					</Row>
				</Container>
			</section>
			<section className="py-8 px-2 bg-white d-flex p-0">
				<Container className="service-container">
					<Row className="d-flex">
						<Col md={2} xl={2} xs={12} sm={12}>
							<ServiceProvidersFilters />
						</Col>
						<Col xl={10} md={10} lg={10} sm={10} className="mb-6 mb-md-0 d-flex ">
							<Tab.Container
								defaultActiveKey="list"
								style={{ maxWidth: isMobileDevice === true ? windowWidth : null }}
							>
								{/*<Col xs>
		                                <p className="mb-0">
		                                    Processo disponíveis
		                                </p>
		                            </Col>*/}
								{/*<Col className="col-auto">
		                                <div className="d-flex ">

		                                    <FormSelect
		                                        options={sortByOptions}
		                                        placeholder="Classificar"
		                                    />
		                                </div>
		                        </Col>*/}

								<Tab.Content
									style={{
										maxWidth: isMobileDevice === true ? windowWidth : null
									}}
								>
									<Tab.Pane
										eventKey="list"
										className="pb-0 px-0 py-0 "
										style={{
											maxWidth: isMobileDevice === true ? windowWidth : null
										}}
									>
										<ServiceProvidersList />
									</Tab.Pane>
								</Tab.Content>
							</Tab.Container>
						</Col>
					</Row>
				</Container>
			</section>

			{/* CookiesConsent component */}
			<CookiesConsent />
		</Fragment>
	);
};

export default ListServiceProviders;
