// import node module libraries
import { Fragment, useState } from 'react';
import { Card, Col, Image, Row } from 'react-bootstrap';
import { Link, useLocation, useParams } from 'react-router-dom';
import '../../../components/Style.css';

// import media files
import Logo from 'assets/images/brand/logo/logo_prestadio_grande.png';

import SignUp from './SignUp';

const FirstSignUpType = () => {
    const params = useLocation();
    const { userType, accountType } = useParams();
    const location = useLocation();
    const userData = location?.state ?? {};

    const googleData = params?.state;

    console.log(userData);

    return (
        <Fragment>
            <Row>
                <SignUp userType={userType} googleData={googleData}></SignUp>
            </Row>
        </Fragment>
    );
};

export default FirstSignUpType;
