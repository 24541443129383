import React, { useEffect, useState, useRef } from 'react';
import io from 'socket.io-client';
import { Toast, Image } from 'react-bootstrap';
import { Link, useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import Logo from '../../assets/images/brand/logo/logo_prestadio_icone.png';

const SocketIOComponent = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const [notification, setNotification] = useState('');
    const [isVisible, setIsVisible] = useState(false);
    const [isChatMessage, setIsChatMessage] = useState(false);

    const socketRef = useRef(null);
    const url = process.env.REACT_APP_API_URL;
    const hash = localStorage.getItem('hash');
    // const websocketUrl = process.env.WEBSOCKET_URL;
    const socketUrl = `${url}?Authorization=${hash}`;

    // const { currentChat } = useSelector((rootReducer) => rootReducer.chatReducer);
    // const { refresh } = useSelector((rootReducer) => rootReducer.notificationReducer);

    useEffect(() => {
        socketRef.current = io(socketUrl, {
            autoConnect: true,
            reconnectionDelay: 5000,
            debug: false,
        });
        socketRef.current.on('notification', handleNotification);

        return () => {
            socketRef.current.disconnect();
        };
    }, [socketUrl]);

    const handleNotification = (mensagem) => {
        setNotification(mensagem);
        if (mensagem.message.includes('Você possuí uma nova requisição de serviço')) {
            const audio = new Audio(soundFile);
            audio.play();
        }

        if (mensagem.message.includes('Você recebeu uma mensagem de')) {
            setIsChatMessage(true);
            dispatch({ type: 'notification/refreshMessages', payload: 1 });
        }

        setIsVisible(true);

        const timer = setTimeout(() => {
            setIsVisible(false);
        }, 4000);

        return () => clearTimeout(timer);
    };

    const changeChat = (notification) => {
        localStorage.setItem('CurrentChat', JSON.stringify(notification.metadata.authorId));
        const isMobileDevice = /Mobi|Android/i.test(navigator.userAgent);

        if (isMobileDevice) {
            navigate('/chat');
        }

        dispatch({ type: 'chat/changeChat', payload: notification });
    };

    return isVisible ? (
        <Link
            to={notification?.link || ''}
            onClick={() => (isChatMessage ? changeChat(notification) : null)}
        >
            <Toast show={isVisible} onClose={() => setIsVisible(false)}>
                <Toast.Header>
                    <Image src={Logo} className="rounded me-2" alt="" style={{ width: '20px' }} />
                    <strong className="me-auto">Prestadio</strong>
                    <small>Agora</small>
                </Toast.Header>
                <Toast.Body>{notification.message}</Toast.Body>
            </Toast>
        </Link>
    ) : null;
};

export default SocketIOComponent;
