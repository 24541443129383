import FilterActionTypes from './action-types';

const initialState = {
    occupationAreaFilter: null,
    statesFilter: null,
    citiesFilter: null,
    searchBox: null,
};

const filterReducer = (state = initialState, action) => {
    switch (action.type) {
        case FilterActionTypes.OCCUPATIONAREA:
            return { ...state, occupationAreaFilter: action.payload };

        case FilterActionTypes.STATES:
            // Se o estado não estiver selecionado, adicione-o ao filtro
            return { ...state, statesFilter: action.payload };

        case FilterActionTypes.CITIES:
            // Verificar se a cidade está no filtro de cidades

            return { ...state, citiesFilter: action.payload };

        case FilterActionTypes.SEARCH_BOX:
            // Verificar se a cidade está no filtro de cidades

            return { ...state, searchBox: action.payload };

        default:
            return state;
    }
};

export default filterReducer;
