import React, { useState, useRef, useEffect } from 'react';
import { useForm } from 'react-hook-form';

// import axios
import axios from 'axios';

// import bootstrap
import { Button, Form, Modal } from 'react-bootstrap';
import ClipLoader from 'react-spinners/ClipLoader';
import { X } from 'react-bootstrap-icons';

// import images/icons
import FileVideo from 'assets/images/feed/import-video.png';
import IconError from 'assets/images/icons/btn-error.png';

// import components
import Toasts from 'components/elements/toasts/Toasts';

// import styles
import './styles.css';

const VideoUpload = ({ newPostAtt }) => {
	const [videoUrl, setVideoUrl] = useState('');
	const [show, setShow] = useState(false);
	const [loading, setLoading] = useState(false);
	const fileInputRef = useRef(null);
	const [showModal, setShowModal] = useState(false);
	const [postError, setPostError] = useState(false);
	const videoIds = useRef([]);

	const { register, handleSubmit, reset } = useForm();

	const url = process.env.REACT_APP_API_URL;
	const hash = localStorage.getItem('hash');
	const config = {
		headers: {
			Authorization: `Bearer ${hash}`
		}
	};

	const handleVideoChange = (e) => {
		const file = e.target.files[0];
		const newFormData = new FormData();
		newFormData.append('file', file);
		const videoNewURL = URL.createObjectURL(file);

		setLoading(true);
		setShow(true);
		setVideoUrl(videoNewURL);

		axios
			.post(`${url}files/upload`, newFormData, config)
			.then((response) => {
				videoIds.current.push(response.data._id);
			})
			.catch((error) => {
				console.log('Error', error);
			})
			.finally(() => {
				setLoading(false);
			});
	};

	const createPostvideo = (data) => {
		const newData = {
			description: data.description,
			videoIds: videoIds.current
		};

		if (newData.description && newData.videoIds.length) {
			axios
				.post(`${url}social/posts`, newData, config)
				.then((response) => {
					// Adiciona o novo post atualizando o componente pai
					newPostAtt((newPost) => [response.data, ...newPost]);

					// Limpa os campos do formulário
					setVideoUrl('');
					videoIds.current = [];
					reset();
					handleClose();
				})
				.catch((error) => {
					console.error('Erro ao buscar dados da API:', error);
				});
		} else {
			setPostError(true);

			setTimeout(() => {
				setPostError(false);
			}, 6000);
		}
	};

	const handleDeleteVideo = () => {
		setVideoUrl('');
		videoIds.current = [];
	};

	const handleVideoClick = () => {
		if (fileInputRef.current) {
			fileInputRef.current.click();
		}
		setShowModal(true);
	};

	const handleClose = () => {
		setShowModal(false);
	};

	useEffect(() => {
		return () => {
			if (videoUrl) {
				URL.revokeObjectURL(videoUrl);
			}
		};
	}, [videoUrl]);

	return (
		<div className="container-video">
			<div className=" wrapper-btn-video" onClick={handleVideoClick}>
				<img className="image-video" src={FileVideo} alt="" />
				<Form.Control
					ref={fileInputRef}
					type="file"
					accept="video/*"
					onChange={handleVideoChange}
					size="sm"
					style={{ display: 'none' }}
				/>
				<h5 className="text-video">Vídeo</h5>
			</div>
			<Modal size="lg" show={showModal} onHide={handleClose}>
				<Form onSubmit={handleSubmit(createPostvideo)}>
					<Modal.Header closeButton>
						<Modal.Title>Editor</Modal.Title>
					</Modal.Header>
					<Modal.Body>
						<h5>Adicione um comentário em sua publicação</h5>
						<div className="flex-grow-1">
							<Form.Control
								as="textarea"
								rows={2}
								type="text"
								id="text"
								className="post-input-new"
								placeholder="Sobre o que você quer falar?"
								{...register('description')}
							/>
						</div>
						{show && videoUrl ? (
							<div className="mt-3">
								<h5>Prévia do Vídeo:</h5>
								<div className="position-relative">
									<video className="rounded-2" width="100%" controls>
										<source src={videoUrl} type="video/mp4" />
										Seu navegador não suporta o elemento de vídeo.
									</video>
									<X
										className="carousel-item-video-btn-close"
										onClick={() => handleDeleteVideo()}
									/>
								</div>
								{loading && (
									<div className="d-flex align-items-center gap-2">
										<ClipLoader color="#000" loading={loading} size={15} />
										<span>Carregando vídeo...</span>
									</div>
								)}
							</div>
						) : (
							<div className="mt-3">
								<h5>Adicione um vídeo</h5>
								<Form.Control
									ref={fileInputRef}
									type="file"
									accept="video/*"
									onChange={handleVideoChange}
									size="sm"
								/>
							</div>
						)}
						{postError && (
							<div className="mt-3 mb-0">
								<Toasts
									icon={IconError}
									title="Informação"
									description="Adicione um vídeo e comentário em sua publicação."
									color="#e50000"
									width="100%"
								/>
							</div>
						)}
					</Modal.Body>
					<Modal.Footer>
						<Button variant="secondary" onClick={handleClose}>
							Cancelar
						</Button>
						<Button type="submit" variant="primary">
							Publicar
						</Button>
					</Modal.Footer>
				</Form>
			</Modal>
		</div>
	);
};

export default VideoUpload;
