// import node module libraries
import axios from 'axios';
import { Fragment, useEffect, useState } from 'react';
import { Card, Col, Form, Image, Row } from 'react-bootstrap';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import Logo from 'assets/images/brand/logo/logo_prestadio_grande.png';

const ConfirmEmail = () => {
    const navigate = useNavigate();
    const [validation, setValidation] = useState('loading');
    const params = useParams();
    const dispatch = useDispatch();

    const url = process.env.REACT_APP_API_URL;

    function auth(result) {
        const userData = {
            email: result.email,
            firstName: result.firstName,
            lastName: result.lastName,
            id: result._id,
            profilePicture: result?.profilePicture?.url,
        };
        localStorage.setItem('hash', result.accessToken);
        localStorage.setItem('loggedUser', result);
        localStorage.setItem('userData', JSON.stringify(userData));

        dispatch({ type: 'chat/changeChat', payload: null });
        navigate('/prestadores/listagem');
    }

    useEffect(() => {
        async function validateEmail() {
            if (!params?.token) {
                setValidation('error');
            }

            try {
                const { data } = await axios.post(`${url}accounts/authentication/validate-email`, {
                    token: params.token,
                });

                setValidation('success');

                setTimeout(() => {
                    auth(data);
                }, 4000);
            } catch (error) {
                setValidation('error');
            }
        }

        validateEmail();
    }, []);

    return (
        <Fragment>
            <Row className="align-items-center justify-content-center g-0 min-vh-100">
                <Col lg={5} md={5} className="py-8 py-xl-0 signin">
                    <Card>
                        <Card.Body className="p-6">
                            <div className="mb-4">
                                <Link to="/">
                                    <div id="div-logo-grande">
                                        <Link to="/">
                                            <Image
                                                src={Logo}
                                                className="mb-1"
                                                alt=""
                                                id="logo-grande"
                                            />
                                        </Link>
                                    </div>
                                    <hr className="my-4" />
                                </Link>

                                {(() => {
                                    if (validation === 'loading') {
                                        return <h2 className="mb-4 fw-bold">Processando...</h2>;
                                    }

                                    if (validation === 'success') {
                                        return (
                                            <div>
                                                <h2 className="mb-4 fw-bold">
                                                    E-mail validado com sucesso!
                                                </h2>

                                                <span className="mt-4">
                                                    Sua conta foi ativada, você será direcionado
                                                    para a plataforma em instantes..
                                                </span>
                                            </div>
                                        );
                                    }

                                    return <h2 className="mb-4 fw-bold">Token inválido!</h2>;
                                })()}
                            </div>

                            {validation === 'error' && (
                                <Form>
                                    <hr className="my-4" />
                                    <span>
                                        Voltar para o<Link to="/authentication/sign-in">login</Link>
                                    </span>
                                </Form>
                            )}
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </Fragment>
    );
};

export default ConfirmEmail;
