// import node module libraries
import axios from 'axios';
import ProfileLayout from 'components/marketing/student/ProfileLayout';
import { useEffect, useRef, useState } from 'react';
import { Button, Card, Col, Form, Row, Table, Image, Modal } from 'react-bootstrap';
import '../../../components/Style.css';
import Pix from './Pix';

// import components
import Toasts from 'components/elements/toasts/Toasts';

// import image and icon
import IconePix from '../../../assets/images/png/logo-pix-icone-512.webp';
import information from '../../../assets/images/icons/botao-de-informacao.png';
import { Check2Circle, HandThumbsUp, HandThumbsUpFill } from 'react-bootstrap-icons';

const url = process.env.REACT_APP_API_URL;
const hash = localStorage.getItem('hash');
const config = {
	headers: {
		Authorization: `Bearer ${hash}`
	}
};

const Withdraw = () => {
	const [payments, setPayments] = useState(null);
	const [withdraws, setWithdraws] = useState([]);
	const [isError, setIsError] = useState(false);
	const [isLoading, setLoading] = useState(false);
	const [refresh, setRefresh] = useState(false);
	const [validateKeyPix, setValidateKeyPix] = useState('');
	const [pixKeys, setPixKeys] = useState([]);
	const [pix, setPix] = useState();
	const inputRef = useRef(null);

	const getLoggedUserData = async () => {
		await axios.get(`${url}accounts/authentication/logged-user`, config).then((response) => {
			// salva lista do pix
			setPixKeys(response?.data?.pix);
			setPix(response?.data?.pix[0]?.pix);
		});
	};

	const getPaymentsData = async () => {
		try {
			const response = await axios.get(`${url}mercadopago/user-payments`, config);
			setPayments(response.data);
		} catch (error) {
			setIsError(true);
		}
	};

	const getWithdrawsData = async () => {
		try {
			const response = await axios.get(`${url}withdraw`, config);
			setWithdraws(response.data);
		} catch (error) {
			setIsError(true);
		}
	};

	const confirmWithdraw = () => {
		handleCreateWithdraw();
		savePix();
	};

	const handleCreateWithdraw = async () => {
		await axios
			.post(`${url}withdraw`, { value: Number(payments.realizado) }, config)
			.then(function (response) {
				setShowConfirm(false);
				setShow(true);
				getLoggedUserData();
				getWithdrawsData();
				getPaymentsData();
			})
			.catch(function (error) {});
	};

	const savePix = async () => {
		await axios
			.patch(`${url}accounts/users/pix/${pix}`, {}, config)
			.then(function (response) {})
			.catch(function (error) {
				newSavePix();
			});
	};

	const newSavePix = async () => {
		await axios
			.post(`${url}accounts/users/pix`, { active: true, pix }, config)
			.then(function (response) {})
			.catch(function (error) {});
	};

	const returnStatus = (status) => {
		const statusMap = {
			PENDING: <h5 className="text-warning mb-0">Pendente</h5>,
			APPROVED: <h5 className="text-success mb-0">Aprovado</h5>,
			REJECTED: <h5 className="text-danger mb-0">Rejeitado</h5>
		};
		return statusMap[status] || null;
	};

	const childToParent = (pixKey) => {
		setValidateKeyPix(pixKey);
	};

	useEffect(() => {
		getPaymentsData();
		getWithdrawsData();
		getLoggedUserData();
		paymentButton();
	}, [refresh, validateKeyPix]);

	const paymentButton = (payments) => {
		if (payments?.realizado <= 0) {
			return (
				<Button className="w-100" onClick={setShowConfirm} disabled={true}>
					{isLoading && (
						<span
							className="spinner-border spinner-border-sm me-2"
							role="status"
							aria-hidden="true"
						></span>
					)}
					Saldo insuficiente
				</Button>
			);
		} else if (pix == null && payments?.realizado >= 0) {
			return (
				<Button className="w-100" onClick={handleCreateWithdraw} disabled={true}>
					{isLoading && (
						<span
							className="spinner-border spinner-border-sm me-2"
							role="status"
							aria-hidden="true"
						></span>
					)}
					Cadastre uma chave PIX para receber pagamentos!
				</Button>
			);
		} else {
			return (
				<Button className="w-100" onClick={handleCreateWithdraw}>
					{isLoading && (
						<span
							className="spinner-border spinner-border-sm me-2"
							role="status"
							aria-hidden="true"
						></span>
					)}
					Solicitar
				</Button>
			);
		}
	};

	const [show, setShow] = useState();
	const handleClose = () => setShow(false);
	const handleShow = () => setShow(true);

	const [showConfirm, setShowConfirm] = useState(false);
	const handleCloseConfirmation = () => setShowConfirm(false);

	return (
		<ProfileLayout>
			<Card>
				<Card.Header>
					<div className="mb-3 mb-lg-0">
						<h3 className="mb-0">Requisições de saque</h3>
						<p className="mb-0">
							Aqui você pode visualizar e solicitar saques e difinir uma chave PIX para receber os
							pagamentos
						</p>
					</div>
				</Card.Header>
				<Card.Body>
					<Row className="mb-4 p-0 m-0 justify-content-between requests-card-toasts-wrapper">
						<Toasts
							icon={information}
							title="Informação"
							description="Utilize formatação brasileira no saque."
							color="#0f99f7"
							width="49%"
						/>
						<Toasts
							icon={information}
							title="Informação"
							description="Prazo de 7 dias para a conclusão do saque."
							color="#0f99f7"
							width="49%"
						/>
					</Row>
					<Row className="p-0 m-0 justify-content-between card-value-and-pix-wrapper">
						<Row className="card-value-container">
							<Row className="d-flex align-items-center m-0 p-0">
								<Col className="m-0 p-0">
									<Row className="m-0">
										<h2 className="text-success p-0 display-5 mb-3">
											{' '}
											R${' '}
											{payments?.realizado.toLocaleString('pt-BR', {
												minimumFractionDigits: 2
											})}
										</h2>
									</Row>
								</Col>
							</Row>
							<Row className="m-0 p-0">
								<Form.Group className="m-0 p-0 d-block" controlId="formLastName">
									<p className="card-value-sub-title">
										Prazo de 7 dias úteis para concluir o saque
									</p>
									<Row className="d-flex flex-column p-0 m-0">
										{/*<Col className="w-100 p-0">
                                            <Form.Control
                                                type="number"
                                                placeholder="Ex: 10,00"
                                                className="mb-0"
                                                ref={inputRef}
                                            />
                                            {isError ? (
                                                <h5 className="text-danger mb-0">Valor inválido</h5>
                                            ) : null}
                                        </Col>*/}

										<Col className="w-100 p-0 mt-0">{paymentButton(payments)}</Col>
									</Row>
								</Form.Group>
							</Row>
						</Row>
						<Row className="card-pix-container">
							<div className="card-pix-title-wrapper mb-3">
								<Image className="card-pix-icon" alt="pix" src={IconePix} />
								<h4 className="mb-0 card-pix-title">Pix</h4>
							</div>
							<p className="mb-3 card-pix-sub-title">
								Chave PIX para onde serão enviados os saques
							</p>
							{/*<Pix childToParent={childToParent} />*/}

							<Form.Control
								type="text"
								className="mb-0"
								onChange={(e) => setPix(e.target.value)}
								value={pix}
							/>
						</Row>
					</Row>
				</Card.Body>
				<Card.Body className="border-top">
					<Row>
						<h3 className="mb-3">Histórico</h3>

						<Table responsive className="text-nowrap" style={{ minHeight: '200px' }}>
							<thead>
								<tr>
									<th scope="col" className="text-start">
										Data solic.
									</th>
									<th scope="col" className="text-start">
										Valor
									</th>
									<th scope="col" className="text-start">
										Status
									</th>
									<th scope="col" className="text-start">
										Data final.
									</th>
								</tr>
							</thead>
							<tbody>
								{withdraws?.map((withd) => (
									<tr>
										<td>{new Intl.DateTimeFormat('pt-BR').format(new Date(withd.createdAt))}</td>
										<td>
											{' '}
											R${' '}
											{withd.value.toLocaleString('pt-BR', {
												minimumFractionDigits: 2
											})}
										</td>
										<td>{returnStatus(withd.status)}</td>
										<td>
											{' '}
											{withd.updatedAt
												? new Intl.DateTimeFormat('pt-BR').format(new Date(withd.updatedAt))
												: null}
										</td>
									</tr>
								))}
							</tbody>
						</Table>
					</Row>
				</Card.Body>
			</Card>
			<Modal show={show} onHide={handleClose}>
				<Modal.Header closeButton>
					<Modal.Title>Seu Saque Está a Caminho! 🚀</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<div className="mb-4">
						<Check2Circle className="icone-confirmacao" />
					</div>
					<p className="text-success text-center">
						<strong>Recebemos sua solicitação de saque 😉</strong>
					</p>{' '}
					<div className="mb-4">
						<strong>Nos próximos 7 dias</strong>, vamos mandar a grana direto para sua chave PIX
						cadastrada. Fique de olho na conta! Qualquer dúvida, estamos aqui para ajudar!
					</div>
					<div className="d-flex justify-content-center">
						<button
							className="btn btn-primary d-flex justify-content-center align-items-center gap-2"
							onClick={() => setShow(false)}
						>
							<HandThumbsUpFill />
							Legal!
						</button>
					</div>
				</Modal.Body>
			</Modal>

			<Modal show={showConfirm} onHide={handleCloseConfirmation}>
				<Modal.Header closeButton>
					<Modal.Title>Confirmação de Saque</Modal.Title>
				</Modal.Header>
				<Modal.Body className="d-flex justify-content-center align-items-center flex-column">
					<p className="text-center">A chave está correta?:</p>{' '}
					<h3 className="text-center mb-4">{pix}</h3>
					<div className="d-flex text-center gap-2">
						<button className="btn btn-danger" onClick={() => setShowConfirm(false)}>
							NÃO
						</button>
						<button className="btn btn-success" onClick={() => confirmWithdraw()}>
							SIM
						</button>
					</div>
				</Modal.Body>
			</Modal>
		</ProfileLayout>
	);
};

export default Withdraw;
