import { userLogout } from 'functions/logout';

export const DashboardMenu = [
    {
        id: 1,
        title: 'Meus trabalhos',
        link: '/marketing/student/student-subscriptions/',
        icon: 'home',
    },
];

export const AccountSettingsMenu = [
    {
        id: 1,
        title: 'Minha Carteira',
        link: '/usuario/meu-perfil/carteira',
        icon: 'book',
    },
    {
        id: 2,
        title: 'Saques',
        link: '/usuario/meu-perfil/saque',
        icon: 'dollar-sign',
    },
    {
        id: 3,
        title: 'Informações Pessoais',
        link: '/usuario/meu-perfil/editar',
        icon: 'settings',
    },
    {
        id: 4,
        title: 'Portfólio',
        link: '/usuario/meu-perfil/editar-porfolio',
        icon: 'user',
    },
    {
        id: 5,
        title: 'Notificações',
        link: '/usuario/meu-perfil/notificacoes',
        icon: 'bell',
    },
    {
        id: 6,
        title: 'Seguidores',
        link: '/usuario/meu-perfil/seguidores',
        icon: 'users',
    },
    {
        id: 7,
        title: 'Seguindo',
        link: '/usuario/meu-perfil/seguindo',
        icon: 'users',
    },
    {
        id: 8,
        title: 'Sair',
        icon: 'power',
    },
];

/*{
        id: 3,
        title: 'Minhas avaliações',
        link: '/usuario/meu-perfil/minhas-avaliacoes',
        icon: 'star',
    },
    {
        id: 6,
        title: 'Segurança',
        link: '/marketing/student/student-security/',
        icon: 'user',
    },

    {
        id: 8,
        title: 'Perfil de Privacidade',
        link: '/marketing/student/student-profile-privacy/',
        icon: 'lock',
    },
    {
        id: 9,
        title: 'Excluir conta',
        link: '/marketing/student/student-delete-profile/',
        icon: 'trash',
    },
*/

export const InstructorDashboardMenu = [DashboardMenu, AccountSettingsMenu];

export default InstructorDashboardMenu;
