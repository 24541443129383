// import node module libraries
import { Fragment, useEffect, useState } from 'react';

import {
	Col,
	Row,
	Card,
	ListGroup,
	OverlayTrigger,
	Tooltip,
	CloseButton,
	Image
} from 'react-bootstrap';
import { ThumbsUp, Award, MessageSquare } from 'react-feather';
import { Link } from 'react-router-dom';
import ReactPaginate from 'react-paginate';
import { ChevronLeft, ChevronRight } from 'react-feather';

// import Boomerang from 'assets/images/loadings/boomerang.gif';

import LoadingIndicator from 'functions/loading';

import IconPedido from '../../../assets/images/icons/pedido.png';

// import custom components
import DotBadge from 'components/elements/bootstrap/DotBadge';

// import data files
// import NotificationData from 'data/dashboard/NotificationData';

import ProfileLayout from 'components/marketing/student/ProfileLayout';

import axios from 'axios';

import { format, parseISO } from 'date-fns';

const Notifications = () => {
	function NotificationsIcon(icon, color) {
		if (icon === 'ThumbsUp') {
			return <ThumbsUp className={`text-${color} me-1`} size="12px" />;
		}
		if (icon === 'Award') {
			return <Award className={`text-${color} me-1`} size="12px" />;
		}
		if (icon === 'MessageSquare') {
			return <MessageSquare className={`text-${color} me-1`} size="12px" />;
		}
	}

	function MarkAsRead(removable) {
		if (removable) {
			return (
				<Fragment>
					<OverlayTrigger
						placement="top"
						overlay={<Tooltip id="tooltip-top">Mark as read</Tooltip>}
					>
						<Link to="#">
							<DotBadge bg="info"></DotBadge>
						</Link>
					</OverlayTrigger>

					<OverlayTrigger placement="top" overlay={<Tooltip id="tooltip-top">Remove</Tooltip>}>
						<CloseButton className="btn-close fs-6 d-block me-1" />
					</OverlayTrigger>
				</Fragment>
			);
		} else {
			return (
				<OverlayTrigger
					placement="top"
					overlay={<Tooltip id="tooltip-top">Mark as unread</Tooltip>}
				>
					<Link to="#">
						<DotBadge bg="secondary"></DotBadge>
					</Link>
				</OverlayTrigger>
			);
		}
	}

	const hash = localStorage.getItem('hash');
	// const urlLogout = process.env.REACT_APP_API_URL;
	const url = process.env.REACT_APP_API_URL;
	const [notifications, setNotifications] = useState();
	const [loggedIn, setLoggedIn] = useState(false);
	const [loggedUserData, setLoggedUserData] = useState();
	const [totalRegisters, setTotalRegisters] = useState();
	const [pageNumber, setPageNumber] = useState();
	const [loading, setLoading] = useState(true);

	const getLoggedUser = async () => {
		setLoading(true);
		const config = {
			headers: {
				Authorization: `Bearer ${hash}`
			}
		};

		axios
			.get(`${url}accounts/authentication/logged-user`, config)
			.then((response) => {
				// Atualiza o estado com os dados da resposta da API
				setLoggedUserData(response.data);
				setLoggedIn(true);
				setLoading(false);
			})
			.catch((error) => {
				setLoggedIn(false);
				setLoading(false);
			});
	};

	const getNotifications = () => {
		setLoading(true);
		axios({
			method: 'get',
			url: `${url}notifications?limit=20&skip=${pageNumber * 20}`,
			headers: {
				Authorization: `Bearer ${hash}`
			}
		})
			.then((response) => {
				setNotifications(response.data.result);
				setTotalRegisters(response.data.metadata.total);
				setLoading(false);
			})
			.catch((error) => {
				console.error(error);
				setLoading(false);
			});
	};

	const handleViewNotification = (item) => {
		axios({
			method: 'patch',
			url: `${url}notifications/read/${item._id}`,
			headers: {
				Authorization: `Bearer ${hash}`
			}
		})
			.then((response) => {})
			.catch((error) => {});
	};

	function changePage({ selected }) {
		setPageNumber(selected);
	}

	const RecordsPerPage = 20;
	const pageCount = Math.ceil(totalRegisters / RecordsPerPage);

	useEffect(() => {
		getLoggedUser();
		getNotifications();
	}, [pageNumber]);

	return (
		<ProfileLayout>
			<Fragment>
				<Card>
					<Card.Header>
						<div className="mb-3 mb-lg-0">
							<h3 className="mb-0">Notificações</h3>
							<p className="mb-0">Aqui você pode visualizar todas as notificações recebidas</p>
						</div>
					</Card.Header>

					<Card.Body>
						<div className="d-flex justify-content-center">
							{loading === true ? <LoadingIndicator /> : ''}
						</div>
						<ListGroup className="mb-4">
							{notifications &&
								notifications.map((item, index) => {
									return (
										<ListGroup.Item
											className={item.read === false ? 'bg-light' : ''}
											key={index}
											onClick={() => handleViewNotification(item)}
										>
											<Row className="align-items-center">
												<Col>
													<div className="d-flex align-items-center">
														<Link to={item.link}>
															<Image src={IconPedido} alt="" className="avatar-md rounded-circle" />
														</Link>
														<div className="ms-3">
															<Link to={item.link}>
																<p className="mb-0 text-body">
																	<span className="fw-bold mb-0 h5">
																		{'Solicitação de Serviço'}:
																	</span>{' '}
																	{item.message}
																</p>
															</Link>
															<span className="fs-6 text-muted">
																<span>
																	{NotificationsIcon(item.icon, item.colorClass)}
																	{format(parseISO(item.createdAt), 'dd/MM/yyyy HH:MM')},
																</span>
																<span className="ms-1">{item.time}</span>
															</span>
														</div>
													</div>
												</Col>
												<Col className="col-auto text-center p-2">{MarkAsRead(item.removable)}</Col>
											</Row>
										</ListGroup.Item>
									);
								})}
						</ListGroup>
						<ReactPaginate
							previousLabel={<ChevronLeft size="14px" />}
							nextLabel={<ChevronRight size="14px" />}
							pageCount={pageCount}
							onPageChange={changePage}
							containerClassName={'justify-content-center mb-0 pagination'}
							previousLinkClassName={'page-link mx-1 rounded'}
							nextLinkClassName={'page-link mx-1 rounded'}
							pageClassName={'page-item'}
							pageLinkClassName={'page-link mx-1 rounded'}
							disabledClassName={'paginationDisabled'}
							activeClassName={'active'}
						/>
					</Card.Body>
				</Card>
			</Fragment>
		</ProfileLayout>
	);
};

export default Notifications;
