// import node module libraries
import { Fragment, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Col, Row, Card, Form, Button, Image, Alert } from 'react-bootstrap';
import axios from 'axios';
// import media files
import Logo from 'assets/images/brand/logo/logo_prestadio_grande.png';

const ForgetPassword = () => {
    const url = process.env.REACT_APP_API_URL;

    const navigate = useNavigate();

    const [email, setEmail] = useState();
    const [loading, setLoading] = useState();
    const [submitMessage, setSubmitMessage] = useState();

    const shippingData = () => {
        setLoading(true);
        const dataForm = {
            email: email,
        };

        axios
            .post(`${url}accounts/users/recover-password`, dataForm)

            .then((response) => {
                setLoading(false);
                navigate('/authentication/email-sent');
            })

            .catch((error) => {
                error;

                setLoading(false);
                setSubmitMessage({
                    type: 'success',
                    message: 'Erro ao redefinir sua senha, verifique seu e-mail e tente novamente.',
                });
            });
    };

    return (
        <Fragment>
            <Row className="align-items-center justify-content-center g-0 min-vh-100">
                <Col lg={5} md={5} className="py-8 py-xl-0 signin">
                    <Card>
                        <Card.Body className="p-6">
                            <div className="mb-4">
                                <Link to="/">
                                    <div id="div-logo-grande">
                                        <Link to="/">
                                            <Image
                                                src={Logo}
                                                className="mb-1"
                                                alt=""
                                                id="logo-grande"
                                            />
                                        </Link>
                                    </div>
                                    <hr className="my-4" />
                                </Link>
                                <h2 className="mb-1 fw-bold">Redefinir Senha</h2>
                                <span>Insira seu e-mail para recuperar sua senha.</span>
                            </div>
                            {/* Form */}
                            <Form>
                                <Row>
                                    <Col lg={12} md={12} className="mb-3">
                                        {/*  email */}

                                        <Form.Control
                                            type="email"
                                            id="email"
                                            placeholder="E-mail"
                                            onChange={(e) => setEmail(e.target.value)}
                                        />
                                    </Col>
                                    <Col lg={12} md={12} className="mb-3 d-grid gap-2">
                                        {/* Button */}

                                        <Button
                                            variant="primary"
                                            disable={loading}
                                            style={{ color: 'white' }}
                                            onClick={() => shippingData()}
                                        >
                                            {loading && (
                                                <span
                                                    className="spinner-border spinner-border-sm me-2"
                                                    role="status"
                                                    aria-hidden="true"
                                                ></span>
                                            )}
                                            Enviar
                                        </Button>

                                        {submitMessage &&
                                            submitMessage.message !== '' &&
                                            (submitMessage.type === 'success' ? (
                                                <div>
                                                    <Alert variant="primary">
                                                        {submitMessage.message}
                                                    </Alert>
                                                </div>
                                            ) : (
                                                <div>
                                                    <Alert variant="danger">
                                                        {submitMessage.message}
                                                    </Alert>
                                                </div>
                                            ))}
                                    </Col>
                                </Row>
                                <hr className="my-4" />
                                <span>
                                    Voltar para o <Link to="/authentication/sign-in">login</Link>
                                </span>
                            </Form>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </Fragment>
    );
};

export default ForgetPassword;
