import { v4 as uuid } from 'uuid';

const HomeRoutes = [
    {
        id: uuid(),
        menuitem: 'Prestadores',
        link: '/prestadores/listagem',
    },
    {
        id: uuid(),
        menuitem: 'Como funciona',
        link: '/prestadio',
    },
    {
        id: uuid(),
        menuitem: 'Blog',
        link: 'https://blog.prestadio.com.br/',
    },

    {
        /*{
        id: uuid(),
        menuitem: 'Sobre',
        link: '#',
        children: [
            {
                id: uuid(),
                menuitem: 'Sobre',
                link: '/marketing/pages/about/',
            },
            {
                id: uuid(),
                menuitem: 'Ajuda',
                link: '#',
                children: [
                    {
                        id: uuid(),
                        menuitem: 'Contato',
                        link: '/marketing/help-center/',
                    },
                    {
                        id: uuid(),
                        menuitem: "FAQ's",
                        link: '/marketing/help-center/faq/',
                    },
                    {
                        id: uuid(),
                        menuitem: 'Guia',
                        link: '/marketing/help-center/guide/',
                    },
                    {
                        id: uuid(),
                        menuitem: 'Suporte',
                        link: '/marketing/help-center/support/',
                    },
                ],
            },
            {
                id: uuid(),
                menuitem: 'Nossos planos',
                link: '/marketing/pages/pricing/',
            },
        ],
    },*/
    },
];

export default HomeRoutes;
