// Import Style Component
import styled from 'styled-components';

export const Title = styled.h3`
	font-size: 23px;
	font-weight: bold;
	margin-top: 60px;
	margin-bottom: 25px;

	@media screen and (max-width: 991px) {
		margin-top: 30px;
	}

	@media screen and (max-width: 768px) {
		margin-top: 20px;
		font-size: 20px;
	}
`;

export const Paragraph = styled.p`
	width: 480px;
	font-size: 40px;
	color: #00ccff;
	font-weight: bold;
	font-style: italic;
	line-height: 1.2;
	margin-bottom: 25px;

	@media screen and (max-width: 768px) {
		font-size: 30px;
	}
`;

export const SecondParagraph = styled.p`
	display: none;
	width: 640px;
	font-size: 24px;
	color: #000;
	padding-top: 20px;
	margin-bottom: 25px;

	@media screen and (max-width: 991px) {
		display: block;
		font-size: 18px;
		width: 280px;
	}

	@media screen and (max-width: 768px) {
		font-size: 16px;
		width: 90%;
		padding-top: 0;
		margin-bottom: 105px;
	}
`;

export const SubParagraph = styled.p`
	width: 640px;
	font-size: 24px;
	color: #000;
	padding-top: 25px;

	@media screen and (max-width: 1199px) {
		width: 530px;
	}

	@media screen and (max-width: 991px) {
		display: none;
	}
`;

export const MainImageWrapper = styled.div`
	max-width: 510px;
	position: absolute;
	top: -180px;
	right: 0;

	@media screen and (max-width: 1199px) {
		max-width: 440px;
	}

	@media screen and (max-width: 991px) {
		max-width: 395px;
		top: -167px;
		right: 10px;
	}

	@media screen and (max-width: 768px) {
		max-width: 305px;
		top: -85px;
	}
`;

export const Span = styled.span`
	color: #000;
`;

export const WrapperCards = styled.div`
	display: grid !important;
	grid-template-columns: repeat(6, 1fr);
	grid-column-gap: 10px;
	place-items: center;
	padding-top: 9.5rem;
	padding-bottom: 8rem;

	@media screen and (max-width: 1630px) {
		grid-template-columns: repeat(3, 1fr);
		grid-row-gap: 210px;
		max-width: 970px;
		margin: 0 auto;
	}

	@media screen and (max-width: 991px) {
		padding-top: 12.5rem;
	}

	@media screen and (max-width: 890px) {
		grid-template-columns: repeat(1, 1fr);
		grid-row-gap: 40px;
		padding-top: 5.5rem;
	}

	@media screen and (max-width: 768px) {
		grid-row-gap: 20px;
		padding-bottom: 3rem;
	}
`;

export const CardContractor = styled.div`
	margin: 10px;
	padding: 25px;
	border: solid 2px #fff;
	border-radius: 15px;
	position: relative;
	text-align: center;
	max-width: 240px;
	max-height: 340px;
	min-height: 340px;
	margin-top: -210px;

	@media screen and (max-width: 890px) {
		max-width: 530px;
		min-width: 530px;
		min-height: 210px;
		margin-top: 0;
		display: flex;
		align-items: center;
	}

	@media screen and (max-width: 768px) {
		min-width: 70%;
		margin: 20px;
	}
`;

export const CardTitleContractor = styled.h5`
	margin: 0;
	position: absolute;
	top: -19px;
	right: 71px;
	font-size: 16px;
	padding: 5px;
	color: #fff;
	background-color: #6ae0f2;

	@media screen and (max-width: 890px) {
		right: unset;
		left: 60px;
	}
`;

export const CardSubTitleContractor = styled.h5`
	margin-top: 25px;
	font-size: 16px;
	font-weight: 400;
	color: #000;
	line-height: 1.5;

	@media screen and (max-width: 890px) {
		max-width: 300px;
		margin-top: 0;
	}

	@media screen and (max-width: 768px) {
		margin-right: 120px;
	}
`;

export const ImageWrapperContractor = styled.div`
	max-width: ${(props) => props.width};
	width: ${(props) => props.width};
	position: absolute;
	bottom: -130px;
	left: 50%;
	transform: translate(-50%, 0%);

	@media screen and (max-width: 890px) {
		bottom: -20px;
		left: 90%;
	}

	@media screen and (max-width: 768px) {
		width: 130px !important;
		left: unset;
		right: 0;
		transform: translate(10%, 0%);
	}
`;

export const CardProvider = styled.div`
	margin: 10px;
	padding: 20px;
	border: solid 2px #000;
	border-radius: 15px;
	position: relative;
	text-align: center;
	display: flex;
	align-items: center;
	max-width: 240px;
	min-height: 340px;

	@media screen and (max-width: 890px) {
		max-width: 530px;
		min-width: 530px;
		min-height: 210px;
		justify-content: end;
	}

	@media screen and (max-width: 768px) {
		min-width: 70%;
		margin: 20px;
	}
`;

export const CardTitleProvider = styled.h5`
	margin: 0;
	position: absolute;
	bottom: -16px;
	right: 77px;
	font-size: 16px;
	padding: 5px;
	color: #000;
	background-color: #6ae0f2;

	@media screen and (max-width: 890px) {
		top: -19px;
		bottom: unset;
	}
`;

export const CardSubTitleProvider = styled.h5`
	margin-top: 135px;
	font-size: 16px;
	font-weight: 400;
	color: #000;
	line-height: 1.5;

	@media screen and (max-width: 890px) {
		max-width: 300px;
		margin-top: 0;
	}

	@media screen and (max-width: 768px) {
		margin-left: 120px;
	}
`;

export const ImageWrapperProvider = styled.div`
	max-width: ${(props) => props.width};
	width: ${(props) => props.width};
	position: absolute;
	top: -130px;
	left: 50%;
	transform: translate(-50%, 0%);

	@media screen and (max-width: 890px) {
		top: -40px;
		left: 10%;
		transform: translate(-40%, 0%);
	}

	@media screen and (max-width: 768px) {
		width: 130px !important;
	}
`;

export const HowItWorksBackground = styled.div`
	background-color: #6ae0f2;
`;

export const HowItWorksContainer = styled.div`
	max-width: 1600px !important;
	margin: 0 auto;
`;

export const Bold = styled.span`
	font-weight: bold;
`;
