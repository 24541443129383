import React, { useState, useRef } from 'react';
import axios from 'axios';
import { useForm } from 'react-hook-form';

//import bootstrsp
import { Button, Carousel, Form, Modal } from 'react-bootstrap';
import { X } from 'react-bootstrap-icons';

// import components
import Toasts from 'components/elements/toasts/Toasts';

// import images/icons
import FileImage from 'assets/images/feed/import-image.png';
import IconError from 'assets/images/icons/btn-error.png';

// import styles
import './styles.css';

const ImageUpload = ({ newPostAtt }) => {
	const [imagePreviews, setImagePreviews] = useState([]);
	const [showModal, setShowModal] = useState(false);
	const [carouselKey, setCarouselKey] = useState(0);
	const [imageIds, setImageIds] = useState([]);
	const [postError, setPostError] = useState(false);
	const fileInputRef = useRef(null);

	const { register, handleSubmit, reset } = useForm();

	const url = process.env.REACT_APP_API_URL;
	const hash = localStorage.getItem('hash');
	const config = {
		headers: {
			Authorization: `Bearer ${hash}`
		}
	};

	const handleImageChange = async (e) => {
		const files = Array.from(e.target.files);
		const newPreviews = files.map((file) => URL.createObjectURL(file));

		// Atualiza os previews das imagens
		setImagePreviews((prevPreviews) => [...prevPreviews, ...newPreviews]);

		for (let i = 0; i < files.length; i++) {
			const newFormData = new FormData();
			newFormData.append('file', files[i]);

			await axios
				.post(`${url}files/upload`, newFormData, config)
				.then((response) => {
					const uploadedImageId = response.data._id;
					setImageIds((prevImageIds) => [...prevImageIds, uploadedImageId]);
				})
				.catch((error) => {
					console.error('Erro ao buscar dados da API:', error);
				})
				.finally(() => {
					// Limpa a URL temporária após o uso
					// URL.revokeObjectURL(newPreviews[i]);
				});
		}
	};

	const createPostImage = async (data) => {
		const newData = {
			description: data.description,
			photosIds: imageIds
		};

		if (newData.description && newData.photosIds.length) {
			await axios
				.post(`${url}social/posts`, newData, config)
				.then((response) => {
					// Adiciona o novo post atualizando o componente pai
					newPostAtt((newPost) => [response.data, ...newPost]);

					// Limpa os campos do formulário
					reset();
					setImagePreviews([]);
					setImageIds([]);
					setPostError(false);
					handleClose();
				})
				.catch((error) => {
					console.error('Erro ao buscar dados da API:', error);
				})
				.finally(() => {});
		} else {
			setPostError(true);

			setTimeout(() => {
				setPostError(false);
			}, 6000);
		}
	};

	const handleDeleteImage = (index) => {
		const newPreviews = [...imagePreviews];
		newPreviews.splice(index, 1);
		setImagePreviews(newPreviews);

		const newImageIds = [...imageIds];
		newImageIds.splice(index, 1);
		setImageIds(newImageIds);

		// Força uma atualização do Carousel ao mudar a chave
		setCarouselKey((prevKey) => prevKey + 1);
	};

	const handleImageClick = () => {
		if (fileInputRef.current) {
			fileInputRef.current.click();
		}
		setShowModal(true);
	};

	const handleClose = () => {
		setShowModal(false);
	};

	return (
		<div className="container-midia">
			<div className="wrapper-btn-midia" onClick={handleImageClick}>
				<img className="image-midia" src={FileImage} alt="" />
				<Form.Control
					ref={fileInputRef}
					type="file"
					accept="image/*"
					multiple
					onChange={handleImageChange}
					size="sm"
					style={{ display: 'none' }}
				/>
				<h5 className="text-midia">Imagem</h5>
			</div>
			<Modal size="lg" show={showModal} onHide={handleClose}>
				<Form onSubmit={handleSubmit(createPostImage)}>
					<Modal.Header closeButton>
						<Modal.Title>Editor</Modal.Title>
					</Modal.Header>
					<Modal.Body>
						<h5>Adicione um comentário em sua publicação</h5>
						<div className="flex-grow-1">
							<Form.Control
								as="textarea"
								rows={2}
								type="text"
								id="text"
								className="post-input-new"
								placeholder="Sobre o que você quer falar?"
								{...register('description')}
							/>
						</div>
						{imagePreviews.length > 0 ? (
							<Carousel
								key={carouselKey}
								className="carousel-midia"
								indicators={true}
								variant="dark"
							>
								{imagePreviews.map((preview, index) => (
									<Carousel.Item key={index}>
										<div className="carousel-item-midia-wrapper">
											<img className="carousel-item-image" src={preview} alt={`preview-${index}`} />
											<X
												className="carousel-item-midia-btn-close"
												onClick={() => handleDeleteImage(index)}
											/>
										</div>
									</Carousel.Item>
								))}
							</Carousel>
						) : (
							<div className="mt-3">
								<h5>Adicione imagens</h5>
								<Form.Control
									ref={fileInputRef}
									type="file"
									accept="image/*"
									multiple
									onChange={handleImageChange}
									size="sm"
								/>
							</div>
						)}
						{postError && (
							<div className="mt-5 mb-0">
								<Toasts
									icon={IconError}
									title="Informação"
									description="Adicione imagem e comentário em sua publicação."
									color="#e50000"
									width="100%"
								/>
							</div>
						)}
					</Modal.Body>
					<Modal.Footer>
						<Button variant="secondary" onClick={handleClose}>
							Cancelar
						</Button>
						<Button variant="primary" type="submit">
							Publicar
						</Button>
					</Modal.Footer>
				</Form>
			</Modal>
		</div>
	);
};

export default ImageUpload;
