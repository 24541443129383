import { Card, Dropdown, Image, Button, Form, Modal, Carousel } from 'react-bootstrap';

import { Chat, HandThumbsUp } from 'react-bootstrap-icons';

import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import ReactPhotoGrid from 'react-photo-grid';
import { isMobile } from 'react-device-detect';
import axios from 'axios';

import moment from 'moment';
import 'moment/locale/pt-br';

import UserImage from 'assets/images/avatar/user.png';
import { useForm } from 'react-hook-form';

import './styles.css';

const VideoPlayer = ({ url }) => (
	<div>
		<video controls className="react-video-player">
			<source src={url} type="video/mp4" />
			Seu navegador não suporta o elemento de vídeo.
		</video>
	</div>
);

const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
	<Link
		to=""
		ref={ref}
		onClick={(e) => {
			e.preventDefault();
			onClick(e);
		}}
		className="btn-icon btn btn-ghost btn-sm rounded-circle"
	>
		{children}
	</Link>
));

const Posts = ({ item, index, onDeletePost, updatePostComments, handleDeleteComment }) => {
	moment.locale('pt-br');

	const [comment, setComment] = useState(false);
	const [loadingComment, setLoadingComment] = useState(false);
	const [editCommentId, setEditCommentId] = useState();
	const [likedPosts, setLikedPosts] = useState([]);
	const [unlikedPosts, setUnlikedPosts] = useState([]);
	const [editComment, setEditComment] = useState();
	const [editingComment, setEditingComment] = useState('');
	const [deletedComments, setDeletedComments] = useState([]);
	const [updatedComment, setUpdatedComment] = useState();
	const [loggedUser, setLoggedUser] = useState();
	const [loading, setLoading] = useState(false);
	const [showModal, setShowModal] = useState(false);

	const { register, handleSubmit, reset } = useForm();

	const url = process.env.REACT_APP_API_URL;
	const hash = localStorage.getItem('hash');
	const config = {
		headers: {
			Authorization: `Bearer ${hash}`
		}
	};

	useEffect(() => {
		getLoggedUser();
	}, []);

	const getLoggedUser = () => {
		setLoading(true);
		axios
			.get(`${url}accounts/authentication/logged-user`, config)
			.then((response) => {
				setLoggedUser(response.data);
			})
			.catch((error) => {
				console.error('Erro ao buscar dados da API:', error);
			})
			.finally(() => {
				setLoading(false);
			});
	};

	const likePost = (postId) => {
		setLikedPosts((prevLikedPosts) => {
			if (!prevLikedPosts.includes(postId)) {
				axios.post(`${url}social/posts-likes/${postId}`, '', config).catch((error) => {
					console.error('Erro ao dar like no post:', error);
				});
				return [...prevLikedPosts, postId];
			}
			return prevLikedPosts;
		});
	};

	const unlikePost = (postId) => {
		setLikedPosts((prevLikedPosts) => {
			if (prevLikedPosts.includes(postId)) {
				axios.delete(`${url}social/posts-likes/${postId}`, config).catch((error) => {
					console.error('Erro ao remover like do post:', error);
				});
				return prevLikedPosts.filter((id) => id !== postId);
			}
			return prevLikedPosts;
		});
	};

	const isPostLikedByUser = (post, userId, likedPosts, unlikedPosts) => {
		return (
			!unlikedPosts.includes(post._id) &&
			((post.likes &&
				post.likes.some((like) => like.userId === userId && like.postId === post._id)) ||
				likedPosts.includes(post._id))
		);
	};

	const handleSubmitComment = async (dataComment) => {
		setLoadingComment(true);

		const data = { comment: dataComment.postsComment };

		try {
			const response = await axios.post(`${url}social/posts-comments/${item._id}`, data, config);
			const commentObj = response.data;

			const newCommentObj = {
				...commentObj,
				user: loggedUser,
				userId: loggedUser._id
			};

			const updatedComments = [newCommentObj, ...item.comments];
			updatePostComments(item._id, updatedComments);

			setComment(false);
			reset();
		} catch (error) {
			console.error('Erro ao comentar no post:', error);
		} finally {
			setLoadingComment(false);
		}
	};

	const handleRemoveComment = async (commentId) => {
		setLoadingComment(true);

		try {
			await axios.delete(`${url}social/posts-comments/${commentId}`, config);
			handleDeleteComment(item._id, commentId);
		} catch (error) {
			console.error('Erro ao remover o comentário:', error);
		} finally {
			setLoadingComment(false);
		}
	};

	const updateComment = (commentId) => {
		const newCommentData = { comment: editingComment };
		axios
			.put(`${url}social/posts-comments/${commentId}`, newCommentData, config)
			.then((response) => {
				setEditComment(false);
				setUpdatedComment(response.data);
			})
			.catch((error) => {
				console.error('Erro ao atualizar comentário:', error);
			});
	};

	const deletePost = async (itemId) => {
		try {
			await axios.delete(`${url}social/posts/${itemId}`, config);
			onDeletePost(itemId);
		} catch (error) {
			console.error('Erro ao deletar post:', error);
		}
	};

	const handleImageClick = () => {
		setShowModal(true);
	};

	const handleClose = () => {
		setShowModal(false);
	};

	return (
		<>
			<Card className="mb-2 " key={index}>
				<Card.Body className="post-card">
					<div className="d-flex justify-content-between post-header align-items-start">
						<a className="post-owner-link" href={`/prestadores/listagem/${item.owner._id}`}>
							<div className="d-flex align-items-start gap-2">
								<div className="avatar avatar-md">
									<Image
										alt="avatar"
										src={
											item?.owner?.profilePicture?.url ? item?.owner?.profilePicture.url : UserImage
										}
										className="rounded-circle"
									/>
								</div>
								<div className="post-author-name">
									<h5 className="mb-0 post-author-name-text">
										{item?.owner?.firstName} {item?.owner?.lastName}
									</h5>
									<div className="d-flex flex-column">
										{item?.owner?.portfolioTitle && (
											<small className="mb-0 text-muted">{item?.owner.portfolioTitle}</small>
										)}

										<small className="mb-0 text-muted">{moment(item?.createdAt).fromNow()}</small>
									</div>
								</div>
							</div>
						</a>
						<div className="d-flex">
							{' '}
							{loggedUser && item?.ownerId === loggedUser?._id && (
								<div className="edit-post">
									<Dropdown>
										<Dropdown.Toggle as={CustomToggle}>
											<i className="fe fe-more-horizontal text-muted"></i>
										</Dropdown.Toggle>

										<Dropdown.Menu>
											<Dropdown.Item eventKey="3" onClick={() => deletePost(item?._id)}>
												<i className="fe fe-trash dropdown-item-icon"></i>
												Excluir
											</Dropdown.Item>
										</Dropdown.Menu>
									</Dropdown>
								</div>
							)}
						</div>
					</div>

					<div className="post-body">
						<div>
							<p>{item?.description}</p>
						</div>
						<div className=" d-flex justify-content-center react-grid mb-2">
							{item.photos.length === 1 && (
								<div className="post-image-container" onClick={() => handleImageClick(item.photos)}>
									<div className="post-one-image-wrapper">
										<img alt="" className="post-image" src={item.photos[0]?.url} />
									</div>
								</div>
							)}
							{item.photos.length === 2 && (
								<div className="post-image-container" onClick={() => handleImageClick(item.photos)}>
									<div className="post-two-image-wrapper">
										<img alt="" className="post-image" src={item.photos[0]?.url} />
										<img alt="" className="post-image" src={item.photos[1]?.url} />
									</div>
								</div>
							)}
							{item.photos.length === 3 && (
								<div className="post-image-container" onClick={() => handleImageClick(item.photos)}>
									<div className="post-three-image-wrapper-triple">
										<div className="post-three-first-image-wrapper">
											<img alt="" className="post-image" src={item.photos[0]?.url} />
										</div>
										<div className="post-three-second-image-wrapper">
											<img alt="" className="post-image" src={item.photos[1]?.url} />
											<img alt="" className="post-image" src={item.photos[2]?.url} />
										</div>
									</div>
								</div>
							)}
							{item.photos.length === 4 && (
								<div className="post-image-container" onClick={() => handleImageClick(item.photos)}>
									<div className="post-four-image-wrapper">
										<div className="post-four-first-image-wrapper">
											<img alt="" className="post-image" src={item.photos[0]?.url} />
										</div>
										<div className="post-four-second-image-wrapper">
											<img alt="" className="post-image" src={item.photos[1]?.url} />
											<img alt="" className="post-image" src={item.photos[2]?.url} />
											<img alt="" className="post-image" src={item.photos[3]?.url} />
										</div>
									</div>
								</div>
							)}
							{item.photos.length > 4 && (
								<div
									className="post-image-container four-more"
									onClick={() => handleImageClick(item.photos)}
								>
									<div className="post-four-more-image-wrapper">
										<div className="post-four-more-first-image-wrapper">
											<img alt="" className="post-image" src={item.photos[0]?.url} />
										</div>
										<div className="post-four-more-second-image-wrapper">
											<img alt="" className="post-image" src={item.photos[1]?.url} />
											<img alt="" className="post-image" src={item.photos[2]?.url} />
											<div className="post-four-more-image-length">
												<img alt="" className="post-image" src={item.photos[3]?.url} />
												<div className="post-four-background-image-length">
													<span className="post-four-text-image-length">
														+ {item.photos.length - 4}
													</span>
												</div>
											</div>
										</div>
									</div>
								</div>
							)}
						</div>
						{item?.videos.length > 0 && (
							<div className="video-player">
								<VideoPlayer url={item?.videos[0]?.url}></VideoPlayer>
							</div>
						)}
					</div>
					<div className="likes-and-comments mb-3 mt-3">
						<div className="view-likes">
							<HandThumbsUp size={16} className="text-primary" />
							<p>{item?.likes?.length}</p>
						</div>
						<div className="d-flex align-items-center gap-1">
							<Chat size={16} className="text-dark" />
							<p className="d-flex align-items-center mb-0 ms-1">
								{item?.comments?.length > 1 ? (
									<span className="mb-0 ms-1">{item?.comments?.length} comentários</span>
								) : (
									<span className="mb-0 ms-1">{item?.comments?.length} comentário</span>
								)}
							</p>
						</div>
					</div>
				</Card.Body>

				<Card.Footer className="action-footer">
					<div className="post-actions mb-2 mt-2 gap-4 justify-content-around">
						<div
							className="action-buttons p-3"
							onClick={() =>
								isPostLikedByUser(item, loggedUser?._id, likedPosts, unlikedPosts)
									? unlikePost(item?._id)
									: likePost(item?._id)
							}
						>
							<HandThumbsUp
								size={18}
								className={
									isPostLikedByUser(item, loggedUser?._id, likedPosts, unlikedPosts)
										? 'text-primary'
										: 'text-dark'
								}
							/>
							<p
								className={
									isPostLikedByUser(item, loggedUser?._id, likedPosts, unlikedPosts)
										? 'text-primary mb-0'
										: 'mb-0'
								}
							>
								{isPostLikedByUser(item, loggedUser?._id, likedPosts, unlikedPosts)
									? 'Curtido'
									: 'Curtir'}
							</p>
						</div>
						<div className="action-buttons p-3" onClick={() => setComment(true)}>
							<Chat size={18} className="text-dark" />
							<p className="mb-0">Comentar</p>
						</div>
					</div>
					{comment && (
						<div className="d-flex mb-2 comment-container">
							<div className="avatar avatar-md">
								<Image
									alt="avatar"
									src={loggedUser?.profilePicture?.url ? loggedUser?.profilePicture.url : UserImage}
									className="rounded-circle"
								/>
							</div>

							<div className="flex-grow-1 d-flex flex-direction-row align-items-center gap-1">
								{' '}
								<Form
									onSubmit={handleSubmit(handleSubmitComment)}
									className="d-flex w-100 align-items-center gap-2"
								>
									<Form.Control
										type="text"
										id="text"
										className="post-input-comment"
										placeholder="Comentar"
										multiple={true}
										{...register('postsComment')}
									/>
									<div>
										{loadingComment ? (
											<span
												className="spinner-border spinner-border-sm me-2"
												role="status"
												aria-hidden="true"
											></span>
										) : (
											<button type="submit" className="btn p-0 border-0 bg-transparent">
												<i className="fe fe-send send-comment text-primary"></i>
											</button>
										)}
									</div>
								</Form>
							</div>
						</div>
					)}
					<div className="post-comments">
						{item.comments &&
							item.comments
								.filter((comment) => !deletedComments.includes(comment._id))
								.map((comment, index) => (
									<div key={index} className="unique-comment">
										<div className="avatar-md comment-avatar">
											<Image
												alt="avatar"
												src={
													comment.user?.profilePicture?.url
														? comment.user.profilePicture.url
														: UserImage
												}
												className="rounded-circle comment-image w-100"
											/>
										</div>
										<div className="unique-comment-comment">
											<div className="d-flex flex-direction-row align-items-center justify-content-between gap-2 mb-0">
												<h5 className="mb-0">
													{comment.user?.firstName} {comment.user?.lastName}
												</h5>
												<div className="d-flex align-items-center gap-2">
													<small className="mb-0">{moment(comment.createdAt).fromNow()}</small>
													{comment.userId === loggedUser?._id && (
														<div className="edit-comment">
															<Dropdown>
																<Dropdown.Toggle as={CustomToggle}>
																	<i className="fe fe-more-horizontal text-muted"></i>
																</Dropdown.Toggle>

																<Dropdown.Menu>
																	<Dropdown.Item
																		eventKey="1"
																		onClick={() => {
																			setEditComment(true);
																			setEditCommentId(comment._id);
																			setEditingComment(comment.comment);
																		}}
																	>
																		<i className="fe fe-edit dropdown-item-icon"></i>
																		Editar
																	</Dropdown.Item>
																	<Dropdown.Item
																		eventKey="3"
																		onClick={() => handleRemoveComment(comment._id)}
																	>
																		<i className="fe fe-trash dropdown-item-icon"></i>
																		Excluir
																	</Dropdown.Item>
																</Dropdown.Menu>
															</Dropdown>
														</div>
													)}
												</div>
											</div>
											{editComment === true && editCommentId === comment._id ? (
												<div>
													<div className="d-flex align-items-center justify-content-between mb-2">
														<Form.Control
															className="mb-0"
															as="textarea"
															multiple={true}
															value={editingComment}
															onChange={(e) => setEditingComment(e.target.value)}
														></Form.Control>
													</div>
													<div>
														<Button
															variant="light"
															size="xs"
															className="me-1"
															onClick={() => setEditComment(false)}
														>
															Cancelar
														</Button>
														<Button
															variant="primary"
															size="xs"
															className="me-1"
															disabled={editingComment === ''}
															onClick={() => updateComment(comment._id)}
														>
															Salvar
														</Button>
													</div>
												</div>
											) : (
												<div className="d-flex align-items-center justify-content-between">
													{updatedComment != null && editCommentId === comment._id ? (
														<p className="mb-0">{updatedComment?.comment}</p>
													) : (
														<p className="mb-0">{comment?.comment}</p>
													)}
												</div>
											)}
										</div>
									</div>
								))}
					</div>
				</Card.Footer>
			</Card>

			<Modal size="lg" show={showModal} onHide={handleClose}>
				<Modal.Header closeButton>
					<a className="post-owner-link" href={`/prestadores/listagem/${item.owner._id}`}>
						<div className="d-flex align-items-start gap-2">
							<div className="avatar avatar-md">
								<Image
									alt="avatar"
									src={
										item?.owner?.profilePicture?.url ? item?.owner?.profilePicture.url : UserImage
									}
									className="rounded-circle"
								/>
							</div>
							<div className="post-author-name">
								<h5 className="mb-0 post-author-name-text">
									{item?.owner?.firstName} {item?.owner?.lastName}
								</h5>
								<div className="d-flex flex-column">
									{item?.owner?.portfolioTitle && (
										<small className="mb-0 text-muted">{item?.owner.portfolioTitle}</small>
									)}

									<small className="mb-0 text-muted">{moment(item?.createdAt).fromNow()}</small>
								</div>
							</div>
						</div>
					</a>
				</Modal.Header>
				<Modal.Body>
					<div className="modal-view-single-post-description">
						<p className="modal-view-single-post-description-text">{item?.description}</p>
					</div>
					{item.photos.length > 1 && (
						<div className="modal-view-single-post-images-container">
							<Carousel className="carousel-midia" indicators={true} variant="dark">
								{item.photos.map((photo, index) => (
									<Carousel.Item key={index}>
										<div className="carousel-item-midia-wrapper">
											<img
												className="carousel-item-image"
												src={photo.url}
												alt={`preview-${index}`}
											/>
										</div>
									</Carousel.Item>
								))}
							</Carousel>
						</div>
					)}
					{item?.photos?.length === 1 && (
						<div className="modal-view-single-post-images-container">
							<div className="carousel-midia">
								<div>
									<div className="carousel-item-midia-wrapper">
										<img className="carousel-item-image" src={item.photos[0]?.url} alt="img" />
									</div>
								</div>
							</div>
						</div>
					)}
				</Modal.Body>
			</Modal>
		</>
	);
};

export default Posts;
