// import node module libraries
import { Fragment, useState, useContext, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { format, parseISO } from 'date-fns';
import { Link, useNavigate } from 'react-router-dom';
import {
    Row,
    Col,
    Image,
    Navbar,
    Nav,
    Container,
    Form,
    Dropdown,
    ListGroup,
    OverlayTrigger,
    Tooltip,
} from 'react-bootstrap';

import { useMediaQuery } from 'react-responsive';

import SimpleBar from 'simplebar-react';

// import styles
import './styles/styles.css';

// import custom components
import DotBadge from 'components/elements/bootstrap/DotBadge';

// import media files
import Logo from 'assets/images/brand/logo/prestadio.svg';
import UserImage from 'assets/images/avatar/user.png';

// import data files
import NavbarDefaultRoutes from 'routes/marketing/NavbarDefault';
import NavDropdownMain from 'layouts/marketing/navbars/NavDropdownMain';

// Import de função logout
import axios from 'axios';

import SocketIOComponent from 'components/notifications/MenuNotifications';

import IconPedido from '../../../assets/images/icons/pedido.png';

import { useDispatch, useSelector } from 'react-redux';

const NavbarDefault = ({ headerstyle, login }) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const hash = localStorage.getItem('hash');
    const urlLogout = process.env.REACT_APP_API_URL;
    const url = process.env.REACT_APP_API_URL;
    const [notifications, setNotifications] = useState();
    const [loggedIn, setLoggedIn] = useState(false);
    const [loggedUserData, setLoggedUserData] = useState();

    const { currentChat } = useSelector((rootReducer) => rootReducer.chatReducer);

    const getLoggedUser = async () => {
        const config = {
            headers: {
                Authorization: `Bearer ${hash}`,
            },
        };

        axios
            .get(`${url}accounts/authentication/logged-user`, config)
            .then((response) => {
                // Atualiza o estado com os dados da resposta da API
                setLoggedUserData(response.data);
                setLoggedIn(true);
            })
            .catch((error) => {
                setLoggedIn(false);
            });
    };

    {
        loggedIn == true ? (login = true) : (login = false);
    }

    const userLogout = (e) => {
        axios({
            method: 'post',
            url: `${urlLogout}accounts/authentication/logout`,
            headers: {
                Authorization: `Bearer ${hash}`,
            },
        })
            .then((response) => {
                const token = localStorage.removeItem('hash');
                localStorage.removeItem('userData');
                navigate('/login');
            })
            .catch((error) => {
                console.error(error);
                const token = localStorage.removeItem('hash');
                localStorage.removeItem('userData');
                navigate('/login');
            });
    };

    const getNotifications = () => {
        axios({
            method: 'get',
            url: `${urlLogout}notifications?limit=5`,
            headers: {
                Authorization: `Bearer ${hash}`,
            },
        })
            .then((response) => {
                setNotifications(response.data.result);
                response.data.result;
            })
            .catch((error) => {
                console.error(error);
            });
    };

    // const exitApplication = (e) => {
    // 	('Sessão encerrada com sucesso!');
    // 	useEffect(NavbarDefault);
    // };

    const isDesktop = useMediaQuery({
        query: '(min-width: 1224px)',
    });
    const isLaptop = useMediaQuery({
        query: '(min-width: 1024px)',
    });

    const [expandedMenu, setExpandedMenu] = useState(false);

    useEffect(() => {
        getLoggedUser();
        getNotifications();
        'loggedUserData', loggedUserData;
    }, []);

    const handleViewNotification = (item) => {
        axios({
            method: 'patch',
            url: `${url}notifications/read/${item._id}`,
            headers: {
                Authorization: `Bearer ${hash}`,
            },
        })
            .then((response) => {
                getNotifications();
            })
            .catch((error) => {});
        ///

        if (item.message.includes('Você recebeu uma mensagem')) {
            dispatch({ type: 'chat/changeChat', payload: item });
            const isMobileDevice = /Mobi|Android/i.test(navigator.userAgent);
            item;
            localStorage.setItem('CurrentChat', JSON.stringify(item.metadata.authorId));

            if (isMobileDevice == true) {
                navigate('/chat');
            }
        }
    };

    const algumaNotificacaoNaoLida = notifications?.some((item) => item.read === false);

    const QuickMenu = () => {
        return (
            <Fragment>
                <Dropdown
                    as={Nav.Item}
                    className={`${isDesktop || isLaptop ? 'mt-2 me-0' : 'mt-2 me-2'}`}
                >
                    <Dropdown.Toggle
                        as={Nav.Link}
                        bsPrefix="dt"
                        className={
                            algumaNotificacaoNaoLida == true
                                ? 'text-dark icon-notifications me-lg-1  btn btn-light btn-icon rounded-circle indicator indicator-primary text-muted'
                                : 'text-dark icon-notifications me-lg-1  btn btn-light btn-icon rounded-circle indicator-primary text-muted'
                        }
                        id="dropdownNotification"
                    >
                        <i className="fe fe-bell"></i>
                    </Dropdown.Toggle>
                    <Dropdown.Menu
                        show={isDesktop ? true : false}
                        className="dashboard-dropdown notifications-dropdown dropdown-menu-lg dropdown-menu-end mt-4 py-0"
                        aria-labelledby="dropdownNotification"
                        align="end"
                    >
                        <div className="border-bottom px-3 pt-3 pb-3 d-flex justify-content-between align-items-end">
                            <span className="h4 mb-0">Notificações</span>
                            <Link to="# " className="text-muted">
                                <span className="align-middle">
                                    <i className="fe fe-settings me-1"></i>
                                </span>
                            </Link>
                        </div>
                        <SimpleBar style={{ maxHeight: '300px' }}>
                            <ListGroup variant="flush">
                                {notifications?.map(function (item, index) {
                                    return (
                                        <ListGroup.Item
                                            className={item.read === false ? 'bg-light' : ''}
                                            key={index}
                                        >
                                            <Row>
                                                <Col>
                                                    <Link
                                                        className="text-body"
                                                        to={item?.link}
                                                        onClick={() => handleViewNotification(item)}
                                                    >
                                                        <div className="d-flex">
                                                            <Image
                                                                src={IconPedido}
                                                                alt=""
                                                                className="avatar-md rounded-circle"
                                                            />
                                                            <div className="ms-3">
                                                                <h5 className="fw-bold mb-1">
                                                                    {item.sender}
                                                                </h5>
                                                                <p className="mb-3">
                                                                    {item.message}
                                                                </p>
                                                                <span className="fs-6 text-muted">
                                                                    <span>
                                                                        <span className="fe fe-thumbs-up text-success me-1"></span>
                                                                        {format(
                                                                            parseISO(
                                                                                item.createdAt
                                                                            ),
                                                                            'dd/MM/yyyy HH:MM'
                                                                        )}
                                                                    </span>
                                                                    <span className="ms-1">
                                                                        {item.time}
                                                                    </span>
                                                                </span>
                                                            </div>
                                                        </div>
                                                    </Link>
                                                </Col>
                                                <Col className="col-auto text-center me-2">
                                                    <OverlayTrigger
                                                        key="top"
                                                        placement="top"
                                                        overlay={
                                                            <Tooltip id="tooltip-top">
                                                                Marcar como lido
                                                            </Tooltip>
                                                        }
                                                    >
                                                        <Link to="#">
                                                            <DotBadge bg="secondary"></DotBadge>
                                                        </Link>
                                                    </OverlayTrigger>
                                                </Col>
                                            </Row>
                                        </ListGroup.Item>
                                    );
                                })}
                            </ListGroup>
                        </SimpleBar>
                        <div
                            className="border-top px-3 pt-3 pb-3"
                            onClick={() => navigate('/usuario/meu-perfil/notificacoes')}
                        >
                            <Link
                                to="/authentication/notifications"
                                className="text-link fw-semi-bold"
                            >
                                Ver todas as notificações
                            </Link>
                        </div>
                    </Dropdown.Menu>
                </Dropdown>

                <Dropdown as={Nav.Item}>
                    <Dropdown.Toggle
                        as={Nav.Link}
                        bsPrefix="dt"
                        className="rounded-circle border-bottom-0 icon-header-profile-mobile"
                        id="dropdownUser"
                    >
                        <div className="avatar avatar-md avatar-indicators avatar-online">
                            <Image
                                alt="avatar"
                                src={
                                    loggedUserData?.profilePicture?.url
                                        ? loggedUserData?.profilePicture.url
                                        : UserImage
                                }
                                className="rounded-circle"
                            />
                        </div>
                    </Dropdown.Toggle>
                    <Dropdown.Menu
                        show={isDesktop ? true : false}
                        className="dashboard-dropdown dropdown-menu-end mt-4 py-0"
                        aria-labelledby="dropdownUser"
                        align="end"
                    >
                        <Dropdown.Item className="mt-3">
                            <div className="d-flex">
                                <div className="avatar avatar-md avatar-indicators avatar-online">
                                    <Image
                                        alt="avatar"
                                        src={
                                            loggedUserData?.profilePicture?.url
                                                ? loggedUserData?.profilePicture?.url
                                                : UserImage
                                        }
                                        className="rounded-circle"
                                    />
                                </div>
                                <div className="ms-3 lh-1">
                                    <h5 className="mb-1">
                                        {loggedUserData?.firstName} {loggedUserData?.lastName}
                                    </h5>
                                    <p className="mb-0 text-muted">{loggedUserData?.email}</p>
                                </div>
                            </div>
                        </Dropdown.Item>
                        <Dropdown.Divider />
                        <Dropdown.Item eventKey="2" as={Link} to="/usuario/meu-perfil/editar">
                            <i className="fe fe-user me-2"></i> Perfil
                        </Dropdown.Item>
                        <Dropdown.Item eventKey="3">
                            <i className="fe fe-star me-2"></i> Assinatura
                        </Dropdown.Item>
                        <Dropdown.Item>
                            <i className="fe fe-settings me-2"></i> Configurações
                        </Dropdown.Item>
                        <Dropdown.Divider />
                        <Dropdown.Item className="mb-3" onClick={userLogout}>
                            <i className="fe fe-power me-2"></i> Sair
                        </Dropdown.Item>
                    </Dropdown.Menu>
                </Dropdown>
            </Fragment>
        );
    };
    return (
        <Fragment>
            <Navbar
                onToggle={(collapsed) => setExpandedMenu(collapsed)}
                expanded={expandedMenu}
                expand="lg"
                className="navbar p-2 navbar-default py-2"
            >
                <Container fluid className="px-0 ps-2">
                    <Navbar.Brand as={Link} to="/">
                        <Image src={Logo} alt="" width={120} />
                    </Navbar.Brand>

                    <div
                        className={`navbar-nav navbar-right-wrap ms-auto d-lg-none nav-top-wrap ${
                            login ? (isDesktop || isLaptop ? 'd-none' : 'd-flex') : 'd-none'
                        }`}
                    >
                        <QuickMenu />
                    </div>
                    <Navbar.Toggle aria-controls="basic-navbar-nav">
                        <span className="icon-bar top-bar mt-0"></span>
                        <span className="icon-bar middle-bar"></span>
                        <span className="icon-bar bottom-bar"></span>
                    </Navbar.Toggle>
                    <Navbar.Collapse id="basic-navbar-nav">
                        <Nav>
                            {NavbarDefaultRoutes.map((item, index) => {
                                if (item.children === undefined) {
                                    return (
                                        <Fragment>
                                            <Link
                                                key={index}
                                                to={item.link}
                                                style={{
                                                    fontSize: '0.875rem',
                                                    color: '#0f172a',
                                                    padding: '0.3125rem 0.35rem',
                                                    marginRight: '1rem',
                                                }}
                                            >
                                                {item.menuitem}
                                            </Link>
                                        </Fragment>
                                    );
                                } else {
                                    return (
                                        <NavDropdownMain
                                            item={item}
                                            key={index}
                                            onClick={(value) => setExpandedMenu(value)}
                                        />
                                    );
                                }
                            })}
                        </Nav>
                        {/* Search Form
                        <Form className="mt-3 mt-lg-0 ms-lg-3 d-flex align-items-center">
                            <span className="position-absolute ps-3 search-icon">
                                <i className="fe fe-search"></i>
                            </span>
                            <Form.Control
                                type="Search"
                                id="formSearch"
                                className="ps-6"
                                placeholder="Buscar trabalhos"
                            />
                        </Form>
                        {/* Right side quick / shortcut menu  */}

                        <Nav className="navbar-nav navbar-right-wrap ms-auto d-flex nav-top-wrap">
                            {/*<DarkLightMode className="mt-2 me-2" />*/}

                            <span className={`ms-auto mt-1  ${login ? 'd-none' : ''}`}>
                                <Nav.Link
                                    as={Link}
                                    to="/authentication/sign-in"
                                    bsPrefix="btn"
                                    className="btn btn-primary shadow-sm me-2"
                                >
                                    Login
                                </Nav.Link>
                            </span>

                            <span
                                className={`${
                                    login ? (isDesktop || isLaptop ? 'd-flex' : 'd-none') : 'd-none'
                                }`}
                            >
                                <QuickMenu />
                            </span>
                        </Nav>
                        {/* end of right side quick / shortcut menu  */}
                    </Navbar.Collapse>
                </Container>
            </Navbar>
            <div className="top-notifications">
                <SocketIOComponent />
            </div>
        </Fragment>
    );
};

// Specifies the default values for props
NavbarDefault.defaultProps = {
    headerstyle: 'navbar-default',
    login: false,
};

// Typechecking With PropTypes
NavbarDefault.propTypes = {
    headerstyle: PropTypes.string,
    login: PropTypes.bool,
};

export default NavbarDefault;
