// import node module libraries
import { Outlet } from 'react-router-dom';
import styles from './styles/styles.css';

const AuthLayout = (props) => {
    return (
        <main className="d-flex align-items-center justify-content-center bg">
            <section className="container d-flex flex-column">
                {props.children}
                <Outlet />
            </section>
        </main>
    );
};
export default AuthLayout;
