// import node module libraries
import { Fragment } from 'react';
import { Container, Row, Col } from 'react-bootstrap';

// Import Style Component
import styled from 'styled-components';

const Span = styled.span`
	font-size: 16px;
	line-height: 1.8;
`;

const H3 = styled.h3`
	font-size: 23px;
	font-weight: bold;
	margin-top: 40px;
`;

const H5 = styled.h5`
	font-size: 18px;
	font-weight: bold;
	margin-top: 40px;
`;

const PrivacyPolicy = () => {
	return (
		<Fragment>
			<Container>
				<Col>
					<Row>
						<H3 className="privacy-policy-title">Política de Privacidade</H3>
					</Row>
					<Row>
						<Span className="privacy-policy-description">
							Bem-vindo(a) ao Prestadio, sua plataforma confiável de prestação de serviços online.
							Estamos comprometidos em proteger a sua privacidade e os seus dados pessoais. Esta
							Política de Privacidade descreve como coletamos, usamos, armazenamos e compartilhamos
							as informações que você nos fornece ao utilizar a plataforma Prestadio. Ao utilizar
							nossos serviços, você concorda com os termos descritos nesta política.
						</Span>
					</Row>
					<Row>
						<Span className="privacy-policy-description">
							Sua privacidade é essencial para nós. Nossa Política de Privacidade descreve como
							coletamos, usamos, compartilhamos e protegemos suas informações pessoais ao utilizar a
							Prestadio. Mantemos seus dados seguros e usamos apenas para melhorar nossos serviços.
						</Span>
					</Row>
					<Row>
						<H5 className="privacy-policy-title">Coleta de Dados Pessoais</H5>
					</Row>
					<Row>
						<Span className="privacy-policy-description">
							Para fornecer uma experiência personalizada e eficiente em nossa plataforma, coletamos
							determinadas informações pessoais suas. Isso pode incluir nome, endereço de e-mail,
							informações de contato, detalhes de pagamento e outras informações relevantes para a
							prestação de serviços. Esses dados são utilizados para processar suas transações,
							gerenciar sua conta e oferecer suporte ao cliente.
						</Span>
					</Row>
					<Row>
						<H5 className="privacy-policy-title">Compartilhamento de Dados</H5>
					</Row>
					<Row>
						<Span className="privacy-policy-description">
							Nós valorizamos a sua privacidade e não compartilhamos suas informações pessoais com
							terceiros, exceto quando necessário para prestação de serviços, cumprimento legal ou
							proteção de nossos direitos e interesses.
						</Span>
					</Row>
					<Row>
						<H5 className="privacy-policy-title">Segurança</H5>
					</Row>
					<Row>
						<Span className="privacy-policy-description">
							Implementamos medidas de segurança rigorosas para proteger seus dados pessoais contra
							acesso não autorizado, alterações ou divulgação.
						</Span>
					</Row>
					<Row>
						<H5 className="privacy-policy-title">Alterações na Política de Privacidade</H5>
					</Row>
					<Row>
						<Span className="privacy-policy-description">
							Podemos atualizar esta Política de Privacidade de tempos em tempos. Notificaremos você
							sobre quaisquer mudanças significativas e solicitaremos o seu consentimento, se
							necessário.
							<br />
							Ao usar o Prestadio, você concorda com os termos desta Política de Privacidade. Se
							tiver alguma dúvida ou preocupação, entre em contato conosco através dos canais
							fornecidos na plataforma.
							<br />
							Data de vigência: 01/09/2024
						</Span>
					</Row>
					<Row>
						<H5 className="privacy-policy-title">
							Responsabilidade dos Usuários e Isenção de Garantias
						</H5>
					</Row>
					<Row>
						<Span className="privacy-policy-description">
							Os usuários da Prestadio são responsáveis por suas ações na plataforma. A Prestadio
							não oferece garantias expressas ou implícitas sobre os serviços prestados pelos
							profissionais, nem sobre os resultados obtidos. Recomendamos que os usuários
							verifiquem os perfis, avaliações e propostas antes de escolher um profissional.
						</Span>
					</Row>
					<Row>
						<H5 className="privacy-policy-title">
							Política de Cancelamento de Assinatura e Reembolso
						</H5>
					</Row>
					<Row>
						<Span className="privacy-policy-description">
							Você pode cancelar sua assinatura a qualquer momento. Se você cancelar durante um
							período de cobrança, não será reembolsado, mas continuará a ter acesso aos serviços
							até o final do período de cobrança atual. Não haverá reembolso por períodos não
							utilizados.
						</Span>
					</Row>
					<Row>
						<H5 className="privacy-policy-title">Alteração nos Termos de Uso</H5>
					</Row>
					<Row>
						<Span className="privacy-policy-description">
							Podemos atualizar nossos Termos de Uso periodicamente. Qualquer alteração
							significativa será notificada com antecedência. Continuar a usar a plataforma após as
							alterações significará que você concorda com os novos termos. Certifique-se de revisar
							os termos regularmente para se manter informado.
							<br />
							<br />
							<br />
						</Span>
					</Row>
				</Col>
			</Container>
		</Fragment>
	);
};

export default PrivacyPolicy;
