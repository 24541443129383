import axios from 'axios';
import { useEffect, useState } from 'react';
import UserImage from 'assets/images/avatar/user.png';
import { Image } from 'react-bootstrap';
import { Star, StarFill } from 'react-bootstrap-icons';

const FeedProviders = () => {
	const [loading, setLoading] = useState(true);
	const [providers, setProviders] = useState();

	const url = process.env.REACT_APP_API_URL;
	const hash = localStorage.getItem('hash');
	const config = {
		headers: {
			Authorization: `Bearer ${hash}`
		}
	};

	useEffect(() => {
		getProviders();
	}, []);

	const getProviders = () => {
		setLoading(true);

		axios
			.get(`${url}accounts/users?limit=25`, config)
			.then((response) => {
				// Atualiza o estado com os dados da resposta da API
				setProviders(response.data.result);
				setLoading(false);
			})
			.catch((error) => {
				console.error('Erro ao buscar dados da API:', error);
				setLoading(false);
			});
	};

	return (
		<div>
			{providers &&
				providers
					.slice() // Cria uma cópia da array original para evitar mutações indesejadas
					.sort((a, b) => b.rating - a.rating) // Ordena os elementos pelo campo rating de forma decrescente
					.map((provider, index) => {
						return (
							<div key={index}>
								<a
									className="recommendation-user-link"
									href={`/prestadores/listagem/${provider?._id}`}
								>
									<div className="d-flex align-items-start gap-2 mb-2">
										<div className="avatar-md">
											<Image
												alt="avatar"
												src={
													provider?.profilePicture?.url ? provider?.profilePicture.url : UserImage
												}
												className="rounded-circle feed-avatar w-100"
											/>
										</div>
										<div>
											<div className="d-flex align-items-center gap-1">
												<h5 className="mb-0 recommendation-user-namer">
													{provider?.firstName} {provider?.lastName.split(' ').pop()}
												</h5>
												{provider?.rating ? (
													<div className="d-flex gap-1 align-items-center">
														<StarFill
															style={{
																fontSize: '12px',
																color: '#faaf00'
															}}
														></StarFill>
														<span
															style={{
																fontSize: '12px',
																color: '#faaf00'
															}}
														>
															{provider?.rating}
														</span>
													</div>
												) : (
													<Star style={{ fontSize: '12px' }}></Star>
												)}
											</div>

											<div className="d-flex flex-column">
												<small className="recommendation-user-occupation-area">
													{provider?.occupationArea}
												</small>
												<small className="recommendation-user-city">
													{provider?.city}, {provider?.state}
												</small>
											</div>
										</div>
									</div>
								</a>
								<hr></hr>
							</div>
						);
					})}
		</div>
	);
};

export default FeedProviders;
