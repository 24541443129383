// ** Import from react dom
import { Route, Routes, Navigate } from 'react-router-dom';

// ** Import core SCSS styles
import 'assets/scss/theme.scss';

// ** Import Authentication components
import SignIn from 'components/dashboard/authentication/SignIn';
import ForgetPassword from 'components/dashboard/authentication/ForgetPassword';
import ChangePassword from 'components/dashboard/authentication/ChangePassword';
import Notifications from 'components/dashboard/authentication/Notifications';

/* IMPORTS FOR MARKETING PAGES - START */
import DefaultLayout from 'layouts/marketing/DefaultLayout';
import NotFound from 'layouts/marketing/NotFound';

import AuthLayout from './dashboard/AuthLayout';

/* IMPORTS FOR FRONT SPECIALTY SUBMENU  ROUTERS */
import Error404 from 'components/marketing/pages/specialty/Error404';

// Landing menu item pages
import HomeAcademy from 'components/marketing/landings/home-academy/HomeAcademy'; // new v2.0.0

//Service Providers Pages
import ServiceProvidersLayout from './marketing/ServiceProvidersLayout';
import ListServiceProviders from 'components/serviceProviders/serviceProviders/ListServiceProviders';
import PostARating from 'components/serviceProviders/serviceProvidersForms/PostARating';
import ServiceProviderProfile from 'components/serviceProviders/serviceProviders/ServiceProviderProfile';

//Service Requests Pages
import MyRequests from 'components/serviceRequests/MyRequests';
import MyRequestsSended from 'components/serviceRequestsSended/MyRequestsSended';
import ServiceReceived from 'components/marketing/account-settings/ServiceReceived';
import MyRequest from 'components/marketing/account-settings/MyRequest';

//Service Progress Pages
import SingleService from 'components/serviceProviders/serviceRequests/Service';

// Student Dashboard Pages
import EditProfile from 'components/marketing/account-settings/EditProfile';
//import MyRequest from 'components/marketing/account-settings/MyRequest';
import EditPortfolio from 'components/marketing/account-settings/EditPortfolio';

// Footer about
import TermsOfUse from '../components/marketing/landings/privacy-policy/TermsOfUse';
import HowItWorks from '../components/marketing/landings/privacy-policy/HowItWorks';
import PrivacyPolicy from '../components/marketing/landings/privacy-policy/PrivacyPolicy';
import CookiesPolicy from '../components/marketing/landings/privacy-policy/CookiesPolicy';
import Faq from 'components/marketing/pages/compare-plan/FAQs';

import FirstSignUp from 'components/dashboard/authentication/FirstSignUp';
import FirstSignUpType from 'components/dashboard/authentication/FirstSignUpType';
import HomeLayout from './marketing/HomeLayout';
import Followers from 'components/dashboard/authentication/Followers';
import MyFollows from 'components/dashboard/authentication/MyFollows';
import PostAComplain from 'components/serviceProviders/serviceProvidersForms/PostAComplain';
import LandingPageLayout from './marketing/LandingPageLayout';

import Feed from 'components/feed/Feed';
import FinishSignUp from 'components/dashboard/authentication/FinishSignUp';
import FeedLayout from './marketing/FeedLayout';
import MyWallet from 'components/marketing/account-settings/MyWallet';
import CheckoutPage from 'components/serviceRequests/payment/CheckoutPage';

import EmailSent from 'components/dashboard/authentication/EmailSent';
import PasswordSet from 'components/dashboard/authentication/PasswordSet';

import Withdraw from 'components/marketing/account-settings/Withdraw';
import EmailConfirmationSent from 'components/dashboard/authentication/EmailConfirmationSent';
import ConfirmEmail from 'components/dashboard/authentication/ConfirmEmail';
import VideoChatLayout from './marketing/VideoChatLayout';
import VideoChat from 'components/videochat/VideoChat';

const AllRoutes = () => {
    return (
        <Routes>
            {/* Routes with privacy policy and cookies */}
            <Route element={<HomeLayout />}>
                <Route element={<TermsOfUse />} path="/termos-de-uso" />
                <Route element={<HowItWorks />} path="/como-funciona" />
                <Route element={<PrivacyPolicy />} path="/politica-de-privacidade" />
                <Route element={<CookiesPolicy />} path="/politica-de-cookies" />
            </Route>

            {/* Rota de perguntas frequentes */}
            <Route element={<HomeLayout />}>
                <Route element={<Faq />} path="/perguntas-frequentes" />
            </Route>

            {/* Routes with AuthLayout */}
            <Route element={<AuthLayout />}>
                <Route path="/login" element={<SignIn />} />
                <Route path="/authentication/sign-in" element={<SignIn />} />
                <Route path="/authentication/first-sign-up" element={<FirstSignUp />} />
                <Route
                    path="/authentication/sign-up/:userType/:accountType"
                    element={<FirstSignUpType />}
                />
                <Route path="/authentication/finish-sign-up/:userType" element={<FinishSignUp />} />
                <Route path="/authentication/forget-password" element={<ForgetPassword />} />
                <Route path="/authentication/email-sent" element={<EmailSent />} />
                <Route
                    path="/authentication/confirmation-email-sent"
                    element={<EmailConfirmationSent />}
                />
                <Route path="/authentication/confirm-email/:token" element={<ConfirmEmail />} />
                <Route path="/authentication/password-set" element={<PasswordSet />} />
                <Route path="/authentication/change-password" element={<ChangePassword />} />
            </Route>

            {/* Routes with AcademyLayout */}
            {/* <Route element={<AcademyLayout />}> */}
            <Route element={<LandingPageLayout />}>
                <Route path="/prestadio" element={<HomeAcademy />} />
            </Route>

            {/* Routes with DefaultLayout */}
            <Route element={<DefaultLayout />}>
                {/* PÁGINA DE EDIÇÃO DE DADOS PESSOAIS */}
                <Route path="/usuario/meu-perfil/editar" element={<EditProfile />} />

                {/* PÁGINA DE EDIÇÃO DE DADOS DO PORTFÓLIO */}
                <Route path="/usuario/meu-perfil/editar-porfolio" element={<EditPortfolio />} />

                <Route path="/usuario/meu-perfil/carteira" element={<MyWallet />} />
                <Route path="/usuario/saque" element={<MyWallet />} />

                <Route path="/usuario/meu-perfil/notificacoes" element={<Notifications />} />
                <Route path="/usuario/meu-perfil/seguidores" element={<Followers />} />
                <Route path="/usuario/meu-perfil/seguindo" element={<MyFollows />} />
                {/* MINHAS REQUISIÇÔES */}
                <Route path="/usuario/meu-perfil/solicitacoes-enviadas" element={<MyRequest />} />
                <Route path="/usuario/meu-perfil/saque" element={<Withdraw />} />

                <Route
                    path="/usuario/meu-perfil/solicitacoes-recebidas"
                    element={<ServiceReceived />}
                />
            </Route>

            {/* Routes with NotFound */}
            <Route element={<NotFound />}>
                <Route path="/pagina-nao-encontrada" element={<Error404 />} />
            </Route>

            {/*Routes SERVICE PROVIDERS */}
            <Route element={<ServiceProvidersLayout />}>
                <Route path="/" element={<ListServiceProviders />} />
                <Route path="/prestadores/listagem" element={<ListServiceProviders />} />
                <Route path="/prestadores/listagem/:id" element={<ServiceProviderProfile />} />
                <Route path="/prestadores/formulario-avaliacao/:id" element={<PostARating />} />
                <Route path="/prestadores/formulario-reclamacao/:id" element={<PostAComplain />} />
            </Route>

            {/*Routes VIDEOCHAT */}
            <Route element={<VideoChatLayout />}>
                <Route path="/videochat" element={<VideoChat />} />
            </Route>

            {/*Routes FEED */}
            <Route element={<FeedLayout />}>
                <Route path="/feed/postagens" element={<Feed />} />
                <Route path="/feed/minhas-postagens" element={<ServiceProviderProfile />} />
                <Route path="/feed/postagens/:id" element={<PostARating />} />
            </Route>

            {/*Routes SERVICE REQUESTS */}
            <Route element={<ServiceProvidersLayout />}>
                <Route path="/solicitacoes/enviadas/" element={<MyRequestsSended />} />
                <Route path="/solicitacoes/recebidas/" element={<MyRequests />} />
                <Route path="/solicitacoes" element={<MyRequests />} />
                <Route path="/solicitacoes/:id" element={<SingleService />} />
                <Route path="/checkout/:serviceId" element={<CheckoutPage />} />
            </Route>

            {/*Redirect*/}
            <Route path="*" element={<Navigate to="/pagina-nao-encontrada" replace />} />
        </Routes>
    );
};

export default AllRoutes;
