// import styles
import './styles.css';
import styled from 'styled-components';

const ToastsContainer = styled.div`
	width: ${(props) => props.width};
	background-color: #f1f5f9;
	padding: 15px;
	border-radius: 10px;
	display: flex;
	flex-direction: column;
	border-left: solid 4px ${(props) => props.bordercolor};
	position: relative;

	@media screen and (max-width: 1200px) {
		width: 100%;
	}
`;

const Title = styled.span`
	color: ${(props) => props.color};
	font-weight: bold;
	font-size: 1rem;
`;

const Toasts = ({ icon, title, description, color, width }) => {
	return (
		<ToastsContainer bordercolor={color} width={width}>
			<img className="payments-icon" alt="img" src={icon} />
			<div className="message-payments-wrapper">
				<Title className="message-payments" color={color}>
					{title}
				</Title>
				<p className="message-payments-information">{description}</p>
			</div>
		</ToastsContainer>
	);
};

export default Toasts;
