import { useNavigate } from 'react-router-dom';

//############################## REDUX ##############################

import axios from 'axios';

const auth = async () => {
    const navigate = useNavigate();
    const hash = localStorage.getItem('hash');
    const url = process.env.REACT_APP_API_URL;


    const config = {
        headers: {
            Authorization: `Bearer ${hash}`,
        },
    };

    axios
        .get(`${url}accounts/authentication/logged-user`, config)
        .then((response) => {
            // Atualiza o estado com os dados da resposta da API
        })
        .catch((error) => {
            navigate('/login');
        });
};

export default auth;
