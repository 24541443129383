// import node module libraries
import { Fragment, useState } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Image, Navbar, Nav, Container, Dropdown } from 'react-bootstrap';

// import sub components
import NavMegaDropdown from './NavMegaDropdown';
import HomeRoutes from 'routes/marketing/HomeRoutes';

// import media files
import Logo from 'assets/images/brand/logo/logo_prestadio_grande.png';

const NavbarMegaMenu = () => {
    const [expandedMenu, setExpandedMenu] = useState(false);

    const handleLinkClick = (e, targetId) => {
        e.preventDefault();
        smoothScroll(targetId);
    };

    const smoothScroll = (targetId) => {
        const targetElement = document.getElementById(targetId);

        if (targetElement) {
            window.scrollTo({
                top: targetElement.offsetTop,
                behavior: 'smooth',
            });
        }
    };

    return (
        <Fragment>
            <Navbar
                onToggle={(collapsed) => setExpandedMenu(collapsed)}
                expanded={expandedMenu}
                expand="lg"
                className="navbar-default"
            >
                <Container fluid className="px-0 ps-2">
                    <div className="d-flex">
                        <Navbar.Brand as={Link} to="/">
                            <Image src={Logo} alt="" style={{ width: '120px' }} />
                        </Navbar.Brand>
                        {/*<CategoriesDropDown />*/}
                    </div>

                    <Navbar.Toggle aria-controls="basic-navbar-nav">
                        <span className="icon-bar top-bar mt-0"></span>
                        <span className="icon-bar middle-bar"></span>
                        <span className="icon-bar bottom-bar"></span>
                    </Navbar.Toggle>
                    <Navbar.Collapse id="basic-navbar-nav">
                        <Nav className="ms-auto">
                            {HomeRoutes.slice(0, 100).map((item, index) => {
                                if (item.children === undefined) {
                                    return (
                                        <Nav.Link
                                            key={index}
                                            as={Link}
                                            to={item.link}
                                            onClick={(e) => handleLinkClick(e, to.substring(1))}
                                        >
                                            {item.menuitem}
                                        </Nav.Link>
                                    );
                                } else {
                                    return (
                                        <NavMegaDropdown
                                            item={item}
                                            key={index}
                                            onClick={(value) => setExpandedMenu(value)}
                                        />
                                    );
                                }
                            })}
                            {/*<MegaMenu />*/}
                        </Nav>

                        {/* Right side quick / shortcut menu  */}
                        <div className="ms-auto mt-3 mt-lg-0">
                            <div className="d-flex align-items-center">
                                {/*<DarkLightMode />*/}
                                <Link
                                    to="/login"
                                    className="btn btn-primary ms-3"
                                    style={{ color: 'white' }}
                                >
                                    Login
                                </Link>
                                <Link
                                    to="/authentication/first-sign-up"
                                    className="btn btn-dark ms-1"
                                >
                                    Cadastre-se
                                </Link>
                            </div>
                        </div>
                        {/* end of right side quick / shortcut menu  */}
                    </Navbar.Collapse>
                </Container>
            </Navbar>
        </Fragment>
    );
};

// Specifies the default values for props
NavbarMegaMenu.defaultProps = {
    headerstyle: 'navbar-default',
    login: false,
};

// Typechecking With PropTypes
NavbarMegaMenu.propTypes = {
    headerstyle: PropTypes.string,
    login: PropTypes.bool,
};

export default NavbarMegaMenu;
