// import styles
import './styles.css';

const PostSkeleton = () => {
	return (
		<div className="post-wrapper-skeleton">
			<div className="post-top-person-wrapper-skeleton">
				<img alt="" className="post-img-person-skeleton skeleton-loader" />
				<div>
					<span className="post-name-text-skeleton skeleton-loader"></span>
					<span className="post-skills-text-skeleton skeleton-loader"></span>
				</div>
			</div>
			<div className="post-bottom-person-wrapper-skeleton">
				<div className="post-description-wrapper-skeleton">
					<span className="post-description-text-skeleton skeleton-loader"></span>
				</div>
				<div className="post-image-skeleton skeleton-loader"></div>
			</div>
		</div>
	);
};

export default PostSkeleton;
