/* eslint-disable import/no-unused-modules */
// Import the functions you need from the SDKs you need
import { initializeApp } from 'firebase/app';
import { getFirestore } from 'firebase/firestore';
import { getStorage } from 'firebase/storage';
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
    apiKey: 'AIzaSyADmoPgc8UDor7tjBjyjx1frzhrwj1Aznk',
    authDomain: 'prestadio-fcf32.firebaseapp.com',
    projectId: 'prestadio-fcf32',
    storageBucket: 'prestadio-fcf32.appspot.com',
    messagingSenderId: '128162185031',
    appId: '1:128162185031:web:f57e059fec56a8a997d662',
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const db = getFirestore(app);
export const storage = getStorage(app);
