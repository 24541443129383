// import node module libraries
import { Link } from 'react-router-dom';
import { Row, Col, Container } from 'react-bootstrap';

// imports react
import { useState } from 'react';

// import styles
import './styles/styles.css';

// import images
import FaqImage from '../../../../assets/images/faq/faq-image.png';
import FaqArrow from '../../../../assets/images/faq/faq-arrow.png';

const FAQs = () => {
    const [expandedItem, setExpandedItem] = useState(null);

    const toggle = (index) => {
        if (expandedItem === index) {
            setExpandedItem(null);
        } else {
            setExpandedItem(index);
        }
    };

    return (
        <section className="pb-14 pt-lg-8 bg-white">
            <Container>
                <Row>
                    <Col>
                        <Row>
                            <Col className="w-100" xl={6} lg={10} xs={12}>
                                <div className="mb-8 pe-8 faq-title-main-wrapper">
                                    {/* heading */}
                                    <h1 className="display-4 fw-bold mb-4 faq-title-main">
                                        Perguntas frequentes,
                                        <span className="text-primary"> respondidas.</span>
                                    </h1>
                                    {/* para */}
                                    {/* <p className="fs-4">
										Mais perguntas? Visite o <Link to="#">Centro de ajuda.</Link>
									</p> */}
                                </div>
                            </Col>
                        </Row>

                        <Row className="flex-column">
                            {/* FAQs  */}
                            {FAQsData.map((item, index) => {
                                return (
                                    <Col
                                        className="w-100 pe-10 faq-item-card"
                                        md={6}
                                        xs={12}
                                        key={index}
                                    >
                                        <div
                                            className="mb-4 faq-item-container"
                                            onClick={() => toggle(index)}
                                        >
                                            <div className="faq-title-wrapper">
                                                <h3 className="mb-0 pe-4">{item.question}</h3>
                                                {expandedItem === index ? (
                                                    <img
                                                        className="faq-icon-arrow-top"
                                                        alt="faqIconArrowBottom"
                                                        src={FaqArrow}
                                                    />
                                                ) : (
                                                    <img
                                                        className="faq-icon-arrow-bottom"
                                                        alt="faqIconArrowBottom"
                                                        src={FaqArrow}
                                                    />
                                                )}
                                            </div>
                                            {expandedItem === index && (
                                                <div className="mt-4 answer-item-wrapper">
                                                    {item.answers.map((answer, answerIndex) => (
                                                        <p className="fs-4" key={answerIndex}>
                                                            {answer}
                                                        </p>
                                                    ))}
                                                </div>
                                            )}
                                        </div>
                                    </Col>
                                );
                            })}
                        </Row>
                    </Col>
                    <Col className="p-0 faq-image-container">
                        <div className="faq-image-wrapper">
                            <img className="faq-image" alt="faqImage" src={FaqImage} />
                        </div>
                    </Col>
                </Row>
            </Container>
        </section>
    );
};

export default FAQs;
