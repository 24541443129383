import chatActionTypes from './action-types';

const initialState = {
    currentChat: null,
};

const chatReducer = (state = initialState, action) => {
    if (action.type === chatActionTypes.CHANGE_CHAT) {
        return { ...state, currentChat: action.payload };
    }

    return state;
};
export default chatReducer;
