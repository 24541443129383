import FilterActionTypes from './action-types';

export const filterOccupationArea = (payload) => ({
    type: FilterActionTypes.OCCUPATIONAREA,
    payload,
});

export const filterStates = (payload) => ({
    type: FilterActionTypes.STATES,
    payload,
});

export const filterCities = (payload) => ({
    type: FilterActionTypes.CITIES,
    payload,
});

export const searchBoxFilter = (payload) => ({
    type: FilterActionTypes.SEARCH_BOX,
    payload,
});
