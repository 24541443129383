// import node module libraries
import { Fragment, useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { Container, Row, Col, Nav, Navbar } from 'react-bootstrap';
import axios from 'axios';

// import routes file
import { AccountSettingsMenu } from 'routes/marketing/StudentDashboardMenu';
import ProfileCover from 'components/marketing/common/headers/ProfileCover';

// import media files
import auth from 'functions/checkLogin';

// import styles
import './styles/styles.css';

const ProfileLayout = (props) => {
	auth();
	const url = process.env.REACT_APP_API_URL;
	const [loggedUserData, setLoggedUserData] = useState();

	const location = useLocation();

	const hash = localStorage.getItem('hash');

	const getLoggedUserData = async () => {
		const config = {
			headers: {
				Authorization: `Bearer ${hash}`
			}
		};

		try {
			const response = await axios.get(`${url}accounts/authentication/logged-user`, config);
			setLoggedUserData(response.data);
		} catch (error) {}
	};

	useEffect(() => {
		getLoggedUserData();
	}, []);

	return (
		<Fragment>
			<section className="pt-5 pb-5 px-2">
				<div className="container-wrapper">
					<Container>
						{/* User info */}
						<ProfileCover loggedUserData={loggedUserData} />

						{/* Content */}
						<Row className="mt-0 mt-md-4 portfolio-container">
							<Col className="portfolio-menu-container" lg={3} md={4} sm={12}>
								{/* User info footer */}
								<Navbar
									expand="lg"
									className="navbar navbar-expand-md navbar-light shadow-sm mb-4 mb-lg-0 sidenav"
								>
									<Link
										className="d-xl-none d-lg-none d-md-none text-inherit fw-bold fs-5 float-start py-1"
										to="#"
									>
										Menu
									</Link>
									<Navbar.Toggle
										aria-controls="basic-navbar-nav"
										className="p-0 focus-none border-0"
										label="Responsive Menu"
									>
										<span
											className="navbar-toggler d-md-none icon-shape icon-sm rounded bg-primary p-0 text-white float-end"
											data-bs-toggle="collapse"
											data-bs-target="#sidenav"
											aria-controls="sidenav"
											aria-expanded="false"
											aria-label="Toggle navigation"
										>
											<span className="fe fe-menu"></span>
										</span>
									</Navbar.Toggle>

									<Navbar.Collapse id="basic-navbar-nav">
										<Nav className="me-auto flex-column" as="ul" activeKey="0">
											<Nav.Item className="navbar-header mt-0" as="li">
												Sua conta
											</Nav.Item>
											{AccountSettingsMenu.map((item, index) => (
												<Nav.Item
													as="li"
													key={index}
													className={`${item.link === location.pathname ? 'active' : ''}`}
												>
													<Link className="nav-link" to={item.link}>
														<i className={`fe fe-${item.icon} nav-icon`}></i>
														{item.title}
													</Link>
												</Nav.Item>
											))}
										</Nav>
									</Navbar.Collapse>
								</Navbar>
								{/* End user info footer */}
							</Col>
							<div className="my-portfolio-children-wrapper">
								<Col className="my-portfolio-children-col" lg={9} md={8}>
									{props.children}
								</Col>
							</div>
						</Row>
					</Container>
				</div>
			</section>
		</Fragment>
	);
};
export default ProfileLayout;
