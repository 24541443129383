// import node module libraries
import { Fragment, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Col, Row, Card, Form, Button, Image, InputGroup } from 'react-bootstrap';
import axios from 'axios';
import { useState } from 'react';
import { useForm } from 'react-hook-form';

// import media files
import Logo from 'assets/images/brand/logo/logo_prestadio_icone.png';
import './styles/styles.css';

import { jwtDecode } from 'jwt-decode';

import { GoogleOAuthProvider } from '@react-oauth/google';
import { GoogleLogin } from '@react-oauth/google';

import { useDispatch } from 'react-redux';

const SignIn = () => {
    const navigate = useNavigate();
    const [formErrors, setFormErrors] = useState([]);

    const {
        register,
        handleSubmit,
        formState: { errors },
        formState: { isSubmitSuccessful },
    } = useForm();

    const [previousPath, setPreviousPath] = useState(null);

    const url = process.env.REACT_APP_API_URL;
    const googleClientId = process.env.REACT_APP_GOOGLE_CLIENTID;

    const [submitError, setSubmitError] = useState('');
    const [loading, setLoading] = useState(false);

    const dispatch = useDispatch();

    const handleNavigate = (path) => {
        setPreviousPath(window.location.pathname);
        //navigate(path);
    };

    useEffect(() => {
        handleNavigate();
    }, []);

    const submitForm = (e) => {
        e.preventDefault();
        if (formErrors.length > 0) {
            return;
        }
        shippingData();
    };

    const shippingData = (data) => {
        const dataForm = {
            email: data.email,
            password: data.password,
        };

        setSubmitError('');
        setLoading(true);

        axios
            .post(`${url}accounts/authentication/login`, dataForm)

            .then((response) => {
                auth(response.data);
            })

            .catch((error) => {
                setLoading(false);

                if (error?.response?.status === 403) {
                    navigate('/authentication/confirmation-email-sent', {
                        state: data.email,
                    });
                }

                if (error.response.data.errorCode === 401 || error.response.data.errorCode === 30) {
                    setSubmitError('E-mail e/ou senha inválidos');
                } else {
                    setSubmitError('Erro ao fazer login');
                }
                if (error.response.data.errorCode === 11) {
                    setSubmitError('Usuário não existe');
                } else {
                    setSubmitError('Erro ao realizar login');
                }
            })
            .finally(() => setLoading(false));
    };

    function auth(result) {
        const userData = {
            email: result.email,
            firstName: result.firstName,
            lastName: result.lastName,
            id: result._id,
            profilePicture: result?.profilePicture?.url,
        };
        localStorage.setItem('hash', result.accessToken);
        localStorage.setItem('loggedUser', result);
        localStorage.setItem('userData', JSON.stringify(userData));

        const redirectLink = localStorage.getItem('redirectLink');
        dispatch({ type: 'chat/changeChat', payload: null });

        if (redirectLink) {
            window.location.href = redirectLink;
        } else {
            navigate('/prestadores/listagem');
        }
    }

    const getDataFromGoogle = async (response) => {
        setLoading(true);

        const token = response.credential;

        const config = {
            headers: {
                token,
            },
        };

        await axios
            .post(`${url}accounts/authentication/google`, {}, config)
            .then((response) => {
                auth(response.data);
            })
            .catch((e) => {
                const decoded = jwtDecode(token);

                navigate('/authentication/first-sign-up', { state: decoded });
            })

            .finally(() => setLoading(false));
    };

    const [viewPassword, setViewPassword] = useState(false);

    return (
        <Fragment>
            <Row className="login_background"></Row>
            <Row className="align-items-center justify-content-center g-0 min-vh-100 background">
                <Col lg={5} md={5} className="py-8 py-xl-0 signin">
                    <Card>
                        <Card.Body className="p-5">
                            <div className="mb-4">
                                <div id="div-logo">
                                    <Link to="/">
                                        <Image src={Logo} className="mb-1" alt="" id="logo" />
                                    </Link>
                                </div>
                                <hr className="my-4" />
                                <h2 className="mb-1 fw-bold text-center">Login</h2>
                            </div>
                            {/* Form */}
                            <Form onSubmit={submitForm}>
                                <Row>
                                    <Col lg={12} md={12} className="mb-3">
                                        {/* Username or email */}
                                        {/*<Form.Label>E-mail </Form.Label>*/}
                                        <Form.Control
                                            type="text"
                                            id="email"
                                            placeholder="E-mail"
                                            {...register('email', {
                                                required: true,
                                            })}
                                        />
                                        {errors?.email?.type === 'required' && (
                                            <p className="errorMessageInput">Campo obrigatório*</p>
                                        )}
                                    </Col>

                                    <Col lg={12} md={12} className="mb-3">
                                        {/* Password */}
                                        {/*<Form.Label>Senha </Form.Label>*/}
                                        <InputGroup className="mb-3">
                                            <Form.Control
                                                type={viewPassword === true ? 'text' : 'password'}
                                                id="password"
                                                placeholder="Senha"
                                                {...register('password', {
                                                    required: true,
                                                })}
                                            />
                                            <InputGroup.Text
                                                id="basic-addon1"
                                                className="cursor-pointer"
                                                onClick={() => setViewPassword(!viewPassword)}
                                            >
                                                {viewPassword === true ? (
                                                    <svg
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        width="16"
                                                        height="16"
                                                        fill="currentColor"
                                                        className="bi bi-eye"
                                                        viewBox="0 0 16 16"
                                                    >
                                                        <path d="M16 8s-3-5.5-8-5.5S0 8 0 8s3 5.5 8 5.5S16 8 16 8M1.173 8a13 13 0 0 1 1.66-2.043C4.12 4.668 5.88 3.5 8 3.5s3.879 1.168 5.168 2.457A13 13 0 0 1 14.828 8q-.086.13-.195.288c-.335.48-.83 1.12-1.465 1.755C11.879 11.332 10.119 12.5 8 12.5s-3.879-1.168-5.168-2.457A13 13 0 0 1 1.172 8z" />
                                                        <path d="M8 5.5a2.5 2.5 0 1 0 0 5 2.5 2.5 0 0 0 0-5M4.5 8a3.5 3.5 0 1 1 7 0 3.5 3.5 0 0 1-7 0" />
                                                    </svg>
                                                ) : (
                                                    <svg
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        width="16"
                                                        height="16"
                                                        fill="currentColor"
                                                        className="bi bi-eye-slash"
                                                        viewBox="0 0 16 16"
                                                    >
                                                        <path d="M13.359 11.238C15.06 9.72 16 8 16 8s-3-5.5-8-5.5a7 7 0 0 0-2.79.588l.77.771A6 6 0 0 1 8 3.5c2.12 0 3.879 1.168 5.168 2.457A13 13 0 0 1 14.828 8q-.086.13-.195.288c-.335.48-.83 1.12-1.465 1.755q-.247.248-.517.486z" />
                                                        <path d="M11.297 9.176a3.5 3.5 0 0 0-4.474-4.474l.823.823a2.5 2.5 0 0 1 2.829 2.829zm-2.943 1.299.822.822a3.5 3.5 0 0 1-4.474-4.474l.823.823a2.5 2.5 0 0 0 2.829 2.829" />
                                                        <path d="M3.35 5.47q-.27.24-.518.487A13 13 0 0 0 1.172 8l.195.288c.335.48.83 1.12 1.465 1.755C4.121 11.332 5.881 12.5 8 12.5c.716 0 1.39-.133 2.02-.36l.77.772A7 7 0 0 1 8 13.5C3 13.5 0 8 0 8s.939-1.721 2.641-3.238l.708.709zm10.296 8.884-12-12 .708-.708 12 12z" />
                                                    </svg>
                                                )}
                                            </InputGroup.Text>
                                        </InputGroup>

                                        {errors?.password?.type === 'required' && (
                                            <p className="errorMessageInput">Campo obrigatório*</p>
                                        )}
                                    </Col>

                                    <Col lg={12} md={12} className="mb-3">
                                        {/* Checkbox */}
                                        <div className="d-md-flex justify-content-between align-items-center">
                                            <Form.Group
                                                className="mb-3 mb-md-0"
                                                controlId="formBasicCheckbox"
                                            >
                                                <span>
                                                    <Link to="/authentication/forget-password">
                                                        Esqueceu sua senha?
                                                    </Link>
                                                </span>
                                            </Form.Group>

                                            <Link
                                                to="/authentication/first-sign-up"
                                                className="ms-1"
                                            >
                                                Cadastre-se!
                                            </Link>
                                        </div>
                                    </Col>
                                    <Col lg={12} md={12} className="mb-0 d-grid gap-2">
                                        {/* Button */}
                                        <Button
                                            variant="primary"
                                            type="submit"
                                            disabled={loading}
                                            onClick={handleSubmit(shippingData)}
                                            style={{ color: 'white' }}
                                        >
                                            {loading && (
                                                <span
                                                    className="spinner-border spinner-border-sm me-2"
                                                    role="status"
                                                    aria-hidden="true"
                                                ></span>
                                            )}
                                            Login
                                        </Button>
                                        {submitError.length > 0 && (
                                            <Row className="submitError">{submitError}</Row>
                                        )}
                                    </Col>
                                </Row>
                            </Form>

                            <hr className="my-4" />

                            <div className="mt-4 text-center d-flex justify-content-center">
                                <GoogleOAuthProvider clientId={googleClientId}>
                                    <GoogleLogin
                                        text="continue_with"
                                        type="standard"
                                        useOneTap={true}
                                        onSuccess={(response) => {
                                            getDataFromGoogle(response);
                                        }}
                                        onError={() => {
                                            ('Login Failed');
                                        }}
                                    />
                                </GoogleOAuthProvider>
                            </div>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </Fragment>
    );
};

export default SignIn;
