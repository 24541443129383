import { Fragment } from 'react';
import { Link, useNavigate, useLocation, useParams } from 'react-router-dom';
import {
  Col,
  Row,
  Card,
  Form,
  Button,
  Image,
  Spinner,
  Alert,
} from 'react-bootstrap';
import { useState, useEffect } from 'react';
import axios from 'axios';
import '../../../components/Style.css';
import { useForm, Controller } from 'react-hook-form';
import { jwtDecode } from 'jwt-decode';

import { differenceInYears, parse } from 'date-fns';

// import media files
import Logo from 'assets/images/brand/logo/logo_prestadio_grande.png';
import AuthBackground from 'assets/images/background/auth_background.png';
import ReactInputMask from 'react-input-mask';
import PasswordStrengthBar from 'react-password-strength-bar';

import occupationAreas from 'components/serviceProviders/occupationAreas';
import { FormSelect } from 'components/elements/form-select/FormSelect';

import cep from 'cep-promise';
import { debounce } from 'lodash';

import { GoogleOAuthProvider } from '@react-oauth/google';
import { GoogleLogin } from '@react-oauth/google';

const FinishSignUp = props => {
  const navigate = useNavigate();
  const location = useLocation();
  const userData = location?.state ?? {};
  const [submitSuccefull, setSubmitSuccefull] = useState('');
  const [submitError, setSubmitError] = useState('');
  const [loading, setLoading] = useState(false);
  const [password, setPassword] = useState('');
  const [passwordConfirm, setPasswordConfirm] = useState('');
  const [passwordPass, setPasswordPass] = useState(false);
  const [idade, setIdade] = useState();
  const [countdown, setCountdown] = useState(5);
  const { userType } = useParams();
  console.log(userType);
  // const [cepValue, setCepValue] = useState();
  // const [cepError, setCepError] = useState();
  // const [city, setCity] = useState();
  // const [state, setState] = useState();
  const url = process.env.REACT_APP_API_URL;
  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
  } = useForm();

  // Defina a função que receberá o valor do CEP

  const handleCEPChange = async cepValue => {
    setSubmitSuccefull('');
    setLoading(true);
    try {
      // Faça o que precisar com o valor do CEP aqui
      const result = await axios.get(
        `https://viacep.com.br/ws/${cepValue}/json/`,
      );
      return result;
    } catch (error) {
      setSubmitError('CEP Inválido');
      setLoading(false);
    }
  };

  const onSubmit = async data => {
    setLoading(true);
    const cepResult = await handleCEPChange(data.cep);

    cepResult.data;

    const age = 0;
    if (data.birthday) {
      const dataNascimento = parse(data.birthday, 'dd/mm/yyyy', new Date());
      const idade = differenceInYears(new Date(), dataNascimento);

      setIdade(idade);

      cepResult.data;

      const age = idade;

      if (age < 18) {
        setSubmitError(
          'Você precisa ter mais de 18 anos para utilizar a Prestadio  :(',
        );
      }

      if (cepResult.data.erro == 'true') {
        setSubmitSuccefull('');
        setSubmitError('CEP Inválido!');
        setLoading(false);
      } else if (
        password === passwordConfirm &&
        cepResult.data.erro != 'true' &&
        age >= 18
      ) {
        ('Criando conta');
        setSubmitError('');
        setSubmitSuccefull('');
        setLoading(true);
        axios({
          method: 'post',
          url: `${url}accounts/users`,
          data: {
            firstName: userData?.given_name,
            lastName: userData?.family_name,
            email: userData?.email,
            phone: data.phone,
            occupationArea: data.occupationArea,
            password: password,
            cep: cepResult.data.cep,
            state: cepResult.data.uf,
            city: cepResult.data.localidade,
            //userType: userType,
            birthday: data.birthday,
            profilePicture: userData?.picture,
          },
        })
          .then(response => {
            const rdsation_data = {
              firstName: data.firstName,
              lastName: data.lastName,
              email: data.email,
              phone: data.phone,
              occupationArea: data.occupationArea,
              cep: cepResult.cep,
              state: cepResult.state,
              city: cepResult.city,
              //userType: userType,
              birthday: data.birthday,
            };

            setLoading(false);
            setSubmitSuccefull('Conta criada com sucesso!');
            //navigate('/authentication/sign-in');
            navigate('/authentication/confirmation-email-sent', {
              state: data.email,
            });
          })
          .catch(error => {
            if (error.response.data.errorCode === 72) {
              setSubmitError('E-mail já cadastrado!');
              setLoading(false);
            } else {
              setSubmitError('Erro ao cadastrar a conta :(');
              setLoading(false);
            }
          });
      } else {
        setLoading(false);
        setPasswordPass(false);
      }
    }
  };

  const options = occupationAreas.map(area => ({
    label: area.area,
    value: area.area,
  }));

  return (
    <Fragment>
      <Row className="align-items-center justify-content-center g-0 min-vh-100">
        <Col lg={5} md={5} className="py-8 py-xl-0">
          <Card>
            <Card.Body className="p-6">
              <div className="mb-0">
                <Link to="/">
                  <div id="div-logo-grande">
                    <Link to="/">
                      <Image
                        src={Logo}
                        className="mb-1"
                        alt=""
                        id="logo-grande"
                      />
                    </Link>
                  </div>
                  <hr className="my-4" />
                </Link>
                <h3 className="mb-1 fw-bold">Olá, {userData.given_name}!</h3>
                <h4 className="mb-1 fw-bold">
                  Vamos finalizar o seu cadastro 😁
                </h4>
                <span>
                  Já possui uma conta?{' '}
                  <Link to="/authentication/sign-in" className="ms-1">
                    Entrar
                  </Link>
                </span>
              </div>
              {/* Form */}
              <Form>
                <Row className="">
                  <Col lg={12} md={6} xl={6} className="mt-3">
                    {/* User Name */}

                    <ReactInputMask
                      className="form-control"
                      type="text"
                      id="birthday"
                      mask="99/99/9999"
                      placeholder="Data de Nascimento"
                      {...register('birthday', {
                        required: true,
                      })}
                    />
                  </Col>
                  {errors?.birthday?.type === 'required' && (
                    <p className="errorMessageInput">Campo obrigatório*</p>
                  )}
                  <Col lg={12} md={6} xl={6} className="mt-3">
                    {/* Telefone */}

                    <ReactInputMask
                      className="form-control"
                      type="text"
                      id="phone"
                      mask="(99) 99999-9999"
                      placeholder="Telefone"
                      {...register('phone', {
                        required: true,
                      })}
                    />
                  </Col>
                  {errors?.phone?.type === 'required' && (
                    <p className="errorMessageInput">Campo obrigatório*</p>
                  )}
                  <Col lg={12} md={12} xl={12} className="mt-3">
                    {/* email */}

                    <Form.Control
                      type="text"
                      id="email"
                      value={userData?.email}
                      placeholder="E-mail"
                      readOnly={true}
                      {...register('email', {
                        required: false,
                      })}
                    />
                  </Col>
                  {errors?.email?.type === 'required' && (
                    <p className="errorMessageInput">Campo obrigatório*</p>
                  )}

                  <Col lg={12} md={12} className="mt-3">
                    {/* Username or email */}
                    {/*<Form.Label>E-mail </Form.Label>*/}
                    <Form.Control
                      type="number"
                      id="cep"
                      placeholder="CEP"
                      {...register('cep', {
                        required: true,
                      })}
                    />
                    {errors?.cep?.type === 'required' && (
                      <p className="errorMessageInput">Campo obrigatório*</p>
                    )}
                  </Col>
                  {userType == 'provider' ? (
                    <Col lg={12} md={12} className="mt-3">
                      <Controller
                        name="occupationArea"
                        control={control}
                        rules={{ required: true }}
                        render={({
                          field: { onChange, onBlur, value, ref },
                        }) => (
                          <Form.Control
                            as={FormSelect}
                            options={options}
                            onBlur={onBlur}
                            onChange={onChange}
                            checked={value}
                            inputRef={ref}
                            placeholder="Selecione a sua área de atuação"
                          />
                        )}
                      />

                      {errors?.occupationArea?.type === 'required' && (
                        <p className="errorMessageInput">Campo obrigatório*</p>
                      )}
                    </Col>
                  ) : (
                    ''
                  )}

                  <Col lg={12} md={6} xl={6} className="mt-3">
                    {/* Password */}

                    <Form.Control
                      type="password"
                      id="password"
                      placeholder="Senha"
                      onChange={e => setPassword(e.target.value)}
                    />
                    <PasswordStrengthBar
                      className="password_strength"
                      scoreWords={[
                        'Fraca',
                        'Média',
                        'Forte',
                        'Muito Forte',
                        'Fortíssima',
                      ]}
                      minLenght={8}
                      shortScoreWord="Muito pequena"
                      password={password}
                    />
                    {password.length == undefined && (
                      <p className="errorMessageInput">Campo obrigatório*</p>
                    )}
                  </Col>

                  <Col lg={12} md={6} xl={6} className="mt-3">
                    <Form.Control
                      type="password"
                      id="password-confirm"
                      placeholder="Confirmar senha"
                      onChange={e => setPasswordConfirm(e.target.value)}
                    />
                  </Col>
                  {passwordConfirm != password && (
                    <p className="errorMessageInput">As senhas não conferem*</p>
                  )}
                  <Col lg={12} md={12} className="mt-3 mb-3">
                    <Form.Check type="checkbox" id="check-api-checkbox">
                      <Form.Check.Label>
                        Ao criar uma conta, concordo com os{' '}
                        <Link to="/termos-de-uso">Termos de Uso </Link> e{' '}
                        <Link to="/politica-de-privacidade">
                          Política de Privacidade.
                        </Link>
                      </Form.Check.Label>
                    </Form.Check>
                  </Col>
                  <Col lg={12} md={12} className="mb-0 d-grid gap-2">
                    <Button
                      variant="primary"
                      type="submit"
                      disabled={loading}
                      onClick={handleSubmit(onSubmit)}
                      style={{ color: 'white' }}
                    >
                      {loading && (
                        <span
                          className="spinner-border spinner-border-sm me-2"
                          role="status"
                          aria-hidden="true"
                        ></span>
                      )}
                      Criar conta
                    </Button>
                    {submitError.length > 0 && (
                      <Col className="submitError">
                        <Alert variant="danger">{submitError}</Alert>
                      </Col>
                    )}
                    {submitSuccefull.length > 0 && (
                      <Col className="submitError">
                        <Alert variant="success">
                          Conta criada com Sucesso!
                        </Alert>
                      </Col>
                    )}
                    {passwordPass == true && (
                      <Col className="submitError">
                        <p>As senhas não conferem!</p>
                      </Col>
                    )}
                  </Col>
                </Row>
              </Form>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Fragment>
  );
};

export default FinishSignUp;
