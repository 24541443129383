// import boostrap
import { Container, Button } from 'react-bootstrap';

// import hooks
import { useState, useEffect } from 'react';

// import styled-components
import {
	CookieBackground,
	CookieContainer,
	CookieTitle,
	CookieDescription,
	CookieContent,
	CookieInformation,
	Wrapperbuttons,
	ButtonReject
} from './CookiesConsentStyled';

// import cookies}
import Cookies from 'js-cookie';

const CookiesConsent = () => {
	const [showCookies, setShowCookies] = useState(true);

	const handleCookieAccept = () => {
		// Cookie aceito
		Cookies.set('cookieConsent', 'true', { expires: 14 });
		setShowCookies(false);
	};

	const handleCookieReject = () => {
		// Cookie rejeitado
		Cookies.set('cookieConsent', 'false', { expires: 2 });
		setShowCookies(false);
	};

	const validadeCookies = () => {
		// Verifica se o cookie foi aceito
		if (Cookies.get('cookieConsent') !== undefined) {
			setShowCookies(false);
		}
	};

	const validadeUserLogged = () => {
		// Verificar se o usuário está logado
		const userHash = localStorage.getItem('hash');
		if (userHash === null) {
			setShowCookies(false);
		}
	};

	useEffect(() => {
		validadeCookies();
		validadeUserLogged();
	}, []);

	return (
		showCookies && (
			<>
				<CookieBackground />
				<CookieContainer>
					<Container>
						<CookieContent>
							<CookieTitle>Consentimento de Cookies</CookieTitle>
							<CookieInformation>
								<CookieDescription>
									Nós utilizamos os cookies para personalizar anúncios, gerar dados estatísticos e
									garantir que você tenha a melhor experiência na Prestadio!. Conheça a{' '}
									<a href="/politica-de-privacidade">Politica de Privacidade</a> e a{' '}
									<a href="/politica-de-cookies">Política de Cookies da Prestadio!</a>
								</CookieDescription>
								<Wrapperbuttons>
									<Button onClick={handleCookieAccept}>Aceitar</Button>
									<ButtonReject onClick={handleCookieReject}>Rejeitar</ButtonReject>
								</Wrapperbuttons>
							</CookieInformation>
						</CookieContent>
					</Container>
				</CookieContainer>
			</>
		)
	);
};

export default CookiesConsent;
