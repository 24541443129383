// import node module libraries
import { Fragment } from 'react';
import { Container, Row, Col } from 'react-bootstrap';

// Import Style Component
import styled from 'styled-components';

const Span = styled.span`
    font-size: 16px;
    line-height: 1.8;
`;

const H3 = styled.h3`
    font-size: 23px;
    font-weight: bold;
    margin-top: 40px;
`;

const TermsOfUse = () => {
    return (
        <Fragment>
            <Container>
                <Col>
                    <Row>
                        <H3 className="terms-of-use-title">
                            TERMOS DE USO DA PRESTADIO
                        </H3>
                    </Row>
                    <Row>
                        <Span className="terms-of-use-description">
                            <br />
                            <br />
                            1. TERMOS GERAIS - Estes Termos de Uso (ou “Termos”)
                            se aplicam a todos os usuários da Plataforma
                            Prestadio, seja contratante ou seja contratado. Ao
                            usar ou criar uma conta na Prestadio, você concorda
                            com estes termos.
                            <br />
                            <br />
                            2. OBJETO - Os serviços objeto dos presentes Termos
                            consistem em:
                            <br />
                            <br />
                            I) Permitir aos usuários que utilizem a Plataforma
                            para livremente busquem contratantes e prestadores
                            de serviço; <br />
                            II) Os valores cobrados pelos serviços realizados
                            serão de inteira responsabilidade dos usuários;{' '}
                            <br />
                            III) Viabilizar o contato direto entre prestadores e
                            contratantes interessados em adquirir os serviços,
                            por meio da divulgação das informações de contato de
                            uma parte à outra. A Prestadio, portanto,
                            possibilita que os usuários contatem-se e negociem
                            entre si diretamente, sem intervir no contato, na
                            negociação ou na efetivação dos negócios, não sendo,
                            nesta qualidade, fornecedor de quaisquer serviços
                            anunciados por seus usuários na Plataforma; <br />
                            IV) Na qualidade de classificado de serviços, a
                            Prestadio não impõe ou interfere em qualquer
                            negociação sobre condição, valor, qualidade, forma
                            ou prazo da contratação entre os contratantes e
                            prestadores, tampouco garante a qualidade, ou
                            entrega dos serviços contratados entre os usuários;{' '}
                            <br />
                            V) Ao se cadastrar, o usuário poderá utilizar todos
                            os serviços disponibilizados na Plataforma
                            disponíveis para sua região, declarando, para tanto,
                            ter lido, compreendido e aceitado estes Termos.
                            <br />
                            <br />
                            <b>3. CAPACIDADE PARA CADASTRAR-SE:</b>
                            <br />
                            I) Os serviços da Prestadio estão disponíveis para
                            pessoas físicas e pessoas jurídicas regularmente
                            inscritas nos cadastros de contribuintes federal e
                            estaduais que tenham capacidade legal para
                            contratá-los. Não podem utilizá-los, assim, pessoas
                            que não gozem dessa capacidade, inclusive menores de
                            idade ou pessoas que tenham sido inabilitadas na
                            Prestadio, temporária ou definitivamente. Ficam,
                            desde já, os usuários advertidos das sanções legais
                            cominadas pelo Código Civil. <br />
                            II) É vedada a criação de mais de um cadastro por
                            usuário. Em caso de multiplicidade de cadastros
                            elaborados por um só usuário, a Prestadio reserva-se
                            o direito de, a seu exclusivo critério e sem
                            necessidade de prévia anuência ou comunicação aos
                            usuários, inabilitar todos os cadastros existentes e
                            impedir eventuais cadastros futuros vinculados a
                            estes; <br />
                            III) Somente será permitida a vinculação de um
                            cadastro por CPF, telefone ou e-mail, não podendo
                            haver duplicidade de dados em nenhum caso; <br />
                            IV) A Prestadio pode unilateralmente excluir o
                            cadastro dos usuários quando verificado que a
                            conduta do usuário é ou será prejudicial ou ofensiva
                            a outros usuários, a Prestadio e seus funcionários
                            ou a terceiros.
                            <br />
                            <br />
                            <b>4. DO CADASTRO</b>
                            <br />
                            I) É necessário o preenchimento completo de todos os
                            dados pessoais exigidos pela Prestadio no momento do
                            cadastramento, para que o usuário esteja habilitado
                            a utilizar a Plataforma; <br />
                            II) É de exclusiva responsabilidade dos usuários
                            fornecer, atualizar e garantir a veracidade dos
                            dados cadastrais, não cabendo à Prestadio qualquer
                            tipo de responsabilidade civil e/ou criminal
                            resultante de dados inverídicos, incorretos ou
                            incompletos fornecidos pelos usuários. <br />
                            III) A Prestadio se reserva o direito de utilizar
                            todos os meios válidos e possíveis para identificar
                            seus usuários, bem como solicitar dados adicionais e
                            documentos que entenda serem pertinentes, a fim de
                            conferir os dados pessoais informados. <br />
                            IV) Caso a Prestadio considere um cadastro, ou as
                            informações nele contidas, suspeito de conter dados
                            errôneos ou inverídicos, a Prestadio se reserva o
                            direito de suspender, temporária ou definitivamente,
                            o usuário responsável pelo cadastramento, assim como
                            impedir e bloquear qualquer publicidade ou cadastro
                            de serviços, bem como cancelar anúncios publicados
                            por este, sem prejuízo de outras medidas que entenda
                            necessárias e oportunas. No caso de aplicação de
                            quaisquer destas sanções, não assistirá aos usuários
                            direito a qualquer tipo de indenização ou
                            ressarcimento por perdas e danos, lucros cessantes
                            ou danos morais. <br />
                            V) O usuário acessará sua conta por meio de apelido
                            (login) e senha, comprometendo-se a não informar a
                            terceiros esses dados, responsabilizando-se
                            integralmente pelo uso que deles seja feito. <br />
                            VI) O usuário compromete-se a notificar a Prestadio
                            imediatamente, por meio dos canais de contato
                            mantidos pela Prestadio na Plataforma, a respeito de
                            qualquer uso não autorizado de sua conta. O usuário
                            será o único responsável pelas operações efetuadas
                            em sua conta, uma vez que o acesso só será possível
                            mediante a utilização de senha de seu exclusivo
                            conhecimento. <br />
                            VII) Em nenhuma hipótese será permitida a cessão,
                            venda, aluguel ou outra forma de transferência da
                            conta. Não se permitirá, ainda, a criação de novos
                            cadastros por pessoas cujos cadastros originais
                            tenham sido cancelados por infrações às políticas da
                            Prestadio. <br />
                            VIII) O apelido que o usuário utiliza na Prestadio
                            não poderá guardar semelhança com o nome Prestadio.
                            Tampouco poderá ser utilizado qualquer apelido que
                            insinue ou sugira que os Serviços serão prestados
                            pela Prestadio ou que façam parte de promoções suas.
                            Também serão eliminados apelidos considerados
                            ofensivos ou que infrinjam a legislação em vigor.
                            <br />
                            IX) Não é permitido aos prestadores se apresentarem
                            aos contratantes como funcionários, prestadores de
                            serviço ou contratados da Prestadio. Os contratantes
                            entendem que os prestadores não são subordinados à
                            Prestadio de nenhuma forma. A Prestadio se reserva o
                            direito de, unilateralmente e sem prévio aviso,
                            recusar qualquer solicitação de cadastro e de
                            cancelar um cadastro previamente aceito.
                            <br />
                            <br />
                            <b>5. MODIFICAÇÕES DOS TERMOS E CONDIÇÕES GERAIS</b>
                            <br />
                            I) A Prestadio poderá alterar, a qualquer tempo e a
                            seu único e exclusivo critério, estes termos. Os
                            novos termos entrarão em vigor imediatamente depois
                            de publicados na Plataforma. No prazo de 5 (cinco)
                            dias contados a partir da publicação das
                            modificações, o usuário deverá informar, por e-mail,
                            caso não concorde com os termos alterados. Nesse
                            caso, o vínculo contratual deixará de existir, desde
                            que não haja contas ou dívidas em aberto. Não
                            havendo manifestação no prazo estipulado,
                            entender-se-á que o usuário aceitou tacitamente os
                            novos termos, e o contrato continuará vinculando as
                            partes; <br />
                            II) As alterações não vigorarão em relação a
                            negociações entre prestador e contratante já
                            iniciados ao tempo em que tais alterações sejam
                            publicadas. Os serviços oferecidos pela Prestadio
                            poderão ser diferentes para cada região do país.
                            Estes termos deverão ser interpretados de acordo com
                            a região em que foi efetuado o cadastro do
                            prestador;
                            <br />
                            <br />
                            <b>6. DO ACEITE</b>
                            <br />
                            I) O prestador disponibilizará informações básicas
                            sobre os serviços que pretende disponibilizar, bem
                            como, após ser contactado por qualquer interessado,
                            informará o valor a ser cobrado pelo serviço; <br />
                            II) Eventual aumento na quantidade de serviço ou
                            horas de serviço a ser prestada será pactuada na
                            Plataforma da Prestadio e pago pelos meios por ela
                            disponibilizados. Em hipótese nenhuma poderá ser
                            realizada cobrança por serviço não contratado pela
                            Plataforma Prestadio; <br />
                            III) O prestador do serviço ao ser contratado está
                            ciente que não possui vínculo empregatício com o
                            contratante, tampouco com a Plataforma Prestadio,
                            razão pela qual fica excluído qualquer vínculo
                            fundado na CLT – Consolidação das Leis do Trabalho.
                            <br />
                            <br />
                            <b>7. PRIVACIDADE DA INFORMAÇÃO</b>
                            <br />
                            I) Todas as informações ou os dados pessoais
                            prestados pelo usuário da Prestadio são armazenados
                            em servidores ou meios magnéticos de alta segurança.
                            <br />
                            II) A Prestadio tomará todas as medidas possíveis
                            para manter a confidencialidade e a segurança
                            descritas nesta cláusula, mas não será responsável
                            por prejuízo que possa ser derivado da violação
                            dessas medidas por parte de terceiros que utilizem
                            de meios indevidos, fraudulentos ou ilegais para
                            acessar as informações armazenadas nos servidores ou
                            nos bancos de dados utilizados pela Prestadio.{' '}
                            <br />
                            III) A Prestadio não tem acesso ao conteúdo das
                            ligações ou comunicações entre os usuários. <br />
                            IV) A Prestadio, por motivos legais, manterá em seu
                            banco de dados todas as informações coletadas dos
                            usuários caso necessário, os direitos do titular
                            podem ser solicitados por meio de um dos canais de
                            atendimento. O apelido e a senha de cada usuário
                            servem para garantir a privacidade e a sua
                            segurança. A Prestadio recomenda a seus usuários que
                            não compartilhem essas informações com ninguém. A
                            Prestadio não se responsabiliza por danos ou
                            prejuízos causados ao usuário pelo compartilhamento
                            dessas informações. <br />
                            V) Toda informação prestada pelos usuários a
                            Prestadio é protegida por empresas especializadas.{' '}
                            <br />
                            VI) A Prestadio segue os padrões de segurança
                            comumente utilizados pelas empresas que trabalham
                            com transmissão e retenção de dados para garantir a
                            segurança de seus Usuários. No entanto, nenhum
                            método de transmissão ou retenção de dados
                            eletrônicos é plenamente seguro e pode estar sujeito
                            a ataques externos. Assim, apesar de utilizar todos
                            os meios possíveis e adequados, a Prestadio não pode
                            garantir a absoluta segurança das informações
                            prestadas. <br />
                            VII) A Prestadio coleta e guarda todas as
                            informações prestadas por seus usuários durante a
                            utilização de sua Plataforma, incluindo no momento
                            do cadastramento, para uso próprio e de seus
                            parceiros comerciais. <br />
                            VIII) O nome, e-mail e outros dados dos usuários
                            poderão ser utilizados para o envio de notificações,
                            informações sobre a conta ou serviços prestados,
                            avisos sobre violações ao termo e outras
                            comunicações que a Prestadio considerar necessárias.
                            Os usuários poderão requisitar a Prestadio a sua
                            exclusão de sua lista de envio de mensagens
                            (mailing). <br />
                            IX) A Prestadio prestará todas as informações
                            requisitadas por órgãos públicos, desde que
                            devidamente justificadas e compatíveis com a lei em
                            vigor. <br />
                            X) A Prestadio utiliza cookies e softwares de
                            monitoramento de seus usuários para prover a seus
                            usuários a melhor navegação possível, baseado em
                            suas necessidades, e para pesquisas internas. Esses
                            cookies não coletam informações pessoais, apenas
                            informam preferências de uso e de navegação de cada
                            usuário, além de prover a Prestadio estatísticas e
                            dados para aprimorar seus serviços.
                            <br />
                            <br />
                            <b>8. DA TARIFA</b>
                            <br />
                            I) Os valores cobrados por cada tarefa, hora de
                            serviço ou por trabalho prestado será estipulado
                            única e exclusivamente pelo prestador de serviço,
                            não tendo qualquer ingerência da Plataforma
                            Prestadio; <br />
                            II) O pagamento pelo serviço deverá ser realizado
                            pelos meios disponíveis na Plataforma Prestadio,
                            tais como cartão de crédito, cartão de débito,
                            boleto, transferências e outros constantes na
                            Plataforma. Qualquer pagamento realizado fora dos
                            meios disponibilizados na Plataforma Prestadio
                            poderá acarretar a exclusão do prestador e do
                            contratante da Plataforma, bem como isenta de
                            qualquer responsabilidade civil, administrativa ou
                            penal a referida Plataforma; <br />
                            III) A Plataforma Prestadio se reserva no direito de
                            cobrar (reter) o percentual de 15% (quinze por
                            cento) do valor cobrado pelo prestador de serviço,
                            que será lhe repassado, em conta previamente
                            designada pelo prestador, no prazo de 3 (três) dias
                            úteis. <br />
                            IV) No ato da contratação do prestador de serviço,
                            que deverá ser realizado por meio da Plataforma
                            Prestadio, o contratante pagará o valor por meio
                            disponibilizado pela Plataforma Prestadio, cujo
                            valor somente será disponibilizado para o prestador
                            de serviço após a confirmação da prestação do
                            serviço contratado pelo contratante. Após a
                            confirmação do serviço prestado pelo contratante, o
                            repasse do valor, mediante o desconto de 15% pela
                            utilização da Plataforma, será realizado em conta
                            previamente designada pelo prestador, no prazo de 3
                            (três) dias úteis; <br />
                            V) Caso o prestador de serviço não preste o serviço
                            previamente contratado, o contratante tem a opção de
                            proceder uma reclamação na Plataforma Prestadio, que
                            será avaliada e julgada no prazo de 03 (três) dias
                            úteis. Caso seja a reclamação procedente pela
                            ausência de prestação do serviço, o valor será
                            restituído ao contratante, no prazo de 03 (três)
                            dias úteis; <br />
                            VI) O contratante poderá avaliar o prestador de
                            serviço pelo meio disponibilizado na Plataforma
                            Prestadio, e poderá fixar comentários no perfil do
                            prestador do serviço contratado. A responsabilidade
                            pelos comentários é de inteira responsabilidade do
                            autor do escrito, e em caso de ilícito lhe renderá
                            responsabilidade civil, administrativa e penal;
                            <br />
                            <br />
                            <b>9. DO CANAL DE ATENDIMENTO</b>
                            <br />
                            I) A Prestadio disponibilizará um canal de
                            atendimento específico, para maiores dúvidas acerca
                            do procedimento que passará a ser adotado a partir
                            de novembro de 2023.
                            <br />
                            <br />
                            <b>10. POLÍTICA DE CANCELAMENTO</b>
                            <br />
                            I) Caso o prestador do serviço não compareça na data
                            e horário previamente pactuado, o contratante poderá
                            cancelar o serviço contratado e solicitar a
                            devolução do valor pago. Neste caso, a Plataforma
                            Prestadio comunicará o prestador do serviço sobre o
                            pedido de cancelamento realizado pelo contratante,
                            que poderá discordar. Após, em 3 (três) dias úteis,
                            a Prestadio julgará a disputa entre o contratante e
                            o prestador, passando o valor para a pessoa que
                            entender ter direito, no prazo de 3 (três) dias
                            úteis.
                            <br />
                            <br />
                            <b>11. SERVIÇOS E ANÚNCIOS </b>
                            <br />
                            I) A Prestadio é uma plataforma de classificados
                            online de serviços e, portanto, não é responsável
                            pela prestação do serviço, tampouco pela qualidade
                            deste. <br />
                            II) Estão proibidas também a veiculação de anúncios
                            de serviços ilegais de acordo com a legislação
                            vigente ou que possam ser considerados ofensivos a
                            terceiros. <br />
                            III) A Prestadio excluirá, unilateralmente e sem
                            qualquer comunicação prévia, aquele usuário que
                            desrespeitar as regras contidas nesta seção. <br />
                            IV) A Prestadio não realiza uma curadoria prévia dos
                            anúncios veiculados na Plataforma. <br />
                            V) Qualquer Usuário ou pessoa física ou jurídica que
                            se sentir ofendido por qualquer anúncio veiculado na
                            Plataforma poderá requisitar a Prestadio que exclua
                            aquele anúncio da Plataforma, pelos seus canais de
                            atendimento.
                            <br />
                            <br />
                            <b>12. SISTEMA DE QUALIFICAÇÃO DOS PRESTADORES</b>
                            <br />
                            I) Os prestadores e contratantes aceitam e se
                            submetem ao sistema de qualificação adotado pela
                            Prestadio. <br />
                            II) Os prestadores e contratantes concordam que a
                            Prestadio poderá cancelar, excluir ou suspender por
                            tempo indeterminado cadastros que apresentem
                            qualificações negativas de forma reiterada. <br />
                            III) Não assistirá aos prestadores e contratantes
                            qualquer tipo de indenização ou ressarcimento por
                            perdas e danos, lucros cessantes e danos morais, em
                            razão da qualificação atribuída aos serviços
                            anunciados. <br />
                            IV) Tendo em vista que os comentários postados são
                            opiniões pessoais dos usuários, estes serão
                            responsáveis pelas opiniões publicadas na
                            Plataforma, seja perante a Prestadio, perante os
                            prestadores, terceiros, órgãos governamentais ou
                            demais usuários do site, isentando a Prestadio de
                            qualquer responsabilidade relativa à veiculação dos
                            comentários. A Prestadio não excluíra comentários ou
                            qualificações sobre os Prestadores. Somente o
                            usuário responsável pelo comentário ou qualificação
                            poderá remover ou alterar os comentários ou
                            qualificações. <br />
                            V) A Prestadio se reserva o direito de excluir
                            unilateralmente e a seu exclusivo critério,
                            comentários que contenham e cadastros de usuários
                            que pratiquem: i. Ofensa à honra, imagem, reputação
                            e dignidade de terceiros; ii. Pornografia,
                            pedofilia, e outras modalidades de satisfação
                            sexual; iii. Racismo ou discriminação de qualquer
                            natureza; iv. Bullying, Stalking ou qualquer outra
                            espécie de constrangimento ilegal ou assédio; v.
                            Manifesta violação a direito autoral ou direito de
                            imagem; vi. Utilização de marcas, símbolos,
                            logotipos ou emblemas de terceiros; vii. Instigação
                            ou apologia à prática de crimes, como tráfico ou uso
                            de entorpecentes, estupro, homicídio, estelionato,
                            dentre outros; viii. Erros ou suspeita de equívocos.
                            <br />
                            <br />
                            <b>13. OBRIGAÇÕES DOS USUÁRIOS</b>
                            <br />
                            I) O prestador deve ter capacidade legal para
                            prestar o serviço. <br />
                            II) Em virtude da Prestadio não figurar como parte
                            nas transações de contratação dos serviços que se
                            realizam entre os usuários, a responsabilidade por
                            todas as obrigações delas decorrentes, sejam
                            fiscais, trabalhistas, consumeristas ou de qualquer
                            outra natureza, será exclusivamente do contratante,
                            do prestador ou de ambos, conforme o caso. Na
                            hipótese de interpelação judicial que tenha como ré
                            a Prestadio, cujos fatos fundem-se em ações do
                            prestador, este será chamado ao processo, devendo
                            arcar com todos os ônus que daí decorram, incluindo
                            despesas com taxas, emolumentos, acordos, honorários
                            advocatícios entre outros. Por não figurar como
                            parte nas transações que se realizam entre os
                            usuários, a Prestadio também não pode obrigar os
                            usuários a honrarem suas obrigações ou a efetivarem
                            a negociação. <br />
                            III) O prestador deverá ter em mente que, na medida
                            em que atue como um fornecedor de serviços, sua
                            oferta o vincula, nos termos do artigo 30 do Código
                            de Defesa do Consumidor e do artigo 429 do Código
                            Civil, cujo cumprimento poderá ser exigido
                            judicialmente pelo contratante; <br />
                            IV) A prestadio não se responsabiliza pelas
                            obrigações tributárias que recaiam sobre as
                            atividades dos usuários. Assim como estabelece a
                            legislação pertinente em vigor, o contratante deverá
                            exigir nota fiscal do prestador em suas transações.
                            O prestador, nos moldes da lei vigente,
                            responsabilizar-se-á pelo cumprimento da
                            integralidade das obrigações oriundas de suas
                            atividades, notadamente aquelas referentes a
                            tributos incidentes.
                            <br />
                            <br />
                            <b>14. PRÁTICAS VEDADAS</b>
                            <br />
                            I) É terminantemente vedado aos usuários, entre
                            outras atitudes previstas nestes termos, manipular,
                            direta ou indiretamente, os preços dos serviços
                            anunciados. <br />
                            II) É proibido aos prestadores divulgar o mesmo
                            anúncio, conteúdo, item ou serviço em mais de uma
                            categoria e/ou de forma repetida. A Prestadio se
                            reserva o direito de excluir anúncios ou serviços
                            repetidos, assim como suspender ou excluir o
                            cadastro do prestador responsável pela duplicidade.
                            <br />
                            III) Os Usuários não poderão: <br />
                            (i) Obter, guardar, divulgar, comercializar e/ou
                            utilizar dados pessoais sobre outros usuários para
                            fins comerciais ou ilícitos; <br />
                            (ii) Usar meios automáticos, incluindo spiders,
                            robôs, crawlers, ferramentas de captação de dados ou
                            similares para baixar dados do site (exceto
                            ferramentas de busca na Internet e arquivos públicos
                            não comerciais); <br />
                            (iii) Burlar, ou tentar burlar, de qualquer forma
                            que seja, o sistema, mecanismo e/ou a Plataforma do
                            site; e <br />
                            (iv) incluir meios de contato como telefone, e-mail,
                            endereço e outras formas de comunicação nas ofertas.
                            <br />
                            <br />
                            <b>15. VIOLAÇÃO NO SISTEMA OU DA BASE DE DADOS</b>
                            <br />
                            I) É vedada a utilização de dispositivo, software ou
                            outro recurso que possa interferir nas atividades e
                            nas operações de Plataforma, bem como nos anúncios,
                            nas descrições, nas contas ou em seus bancos de
                            dados. Qualquer intromissão, tentativa de, ou
                            atividade que viole ou contrarie as leis de direito
                            de propriedade intelectual e as proibições
                            estipuladas nestes termos tornará o responsável
                            passível de sofrer os efeitos das ações legais
                            pertinentes, bem como das sanções aqui previstas,
                            sendo ainda responsável por indenizar a Prestadio ou
                            seus usuários por eventuais danos causados.
                            <br />
                            <br />
                            <b>16. SANÇÕES</b>
                            <br />
                            I) Sem prejuízo de outras medidas, a Prestadio
                            poderá, a seu exclusivo critério e sem necessidade
                            de prévia anuência dos ou comunicação aos Usuários,
                            advertir, suspender ou cancelar, temporária ou
                            permanentemente, o cadastro ou os anúncios do
                            usuário, podendo aplicar sanção que impacte
                            negativamente em sua reputação, a qualquer tempo,
                            iniciando as ações legais cabíveis e suspendendo a
                            prestação de seus serviços, se: <br />
                            <br />
                            (i) o usuário não cumprir qualquer dispositivo
                            destes termos e as demais políticas da Prestadio;{' '}
                            <br />
                            (ii) descumprir com seus deveres de usuário; <br />
                            (iii) praticar atos delituosos ou criminais; <br />
                            (iv) não puder ser verificada a identidade do
                            usuário, qualquer informação fornecida por ele
                            esteja incorreta ou se as informações prestadas
                            levarem a crer que o cadastro seja falso ou de
                            pessoa diversa; <br />
                            (v) a Prestadio entender que os anúncios ou qualquer
                            outra atitude do usuário tenham causado algum dano a
                            terceiros ou a Prestadio ou tenham a potencialidade
                            de assim o fazer. <br />
                            <br />
                            II) Nos casos de suspensão, temporária ou
                            permanente, do cadastro do prestador, todos os
                            anúncios ativos e as ofertas realizadas serão
                            automaticamente canceladas. i. Em caso de suspensão
                            temporária, o plano contratado pelo prestador será
                            suspenso até a reabilitação do cadastro do
                            prestador, quando voltará a viger normalmente. ii. A
                            Prestadio se reserva o direito de, a qualquer
                            momento e a seu exclusivo critério, solicitar o
                            envio de documentação pessoal ou de qualquer
                            documento que comprove a veracidade das informações
                            cadastrais. iii. Em caso de requisição de
                            documentos, quaisquer prazos determinados nestes
                            termos só serão aplicáveis a partir da data de
                            recebimento dos documentos solicitados.
                            <br />
                            <br />
                            <b>17. RESPONSABILIDADES</b>
                            <br />
                            I) A Prestadio não se responsabiliza por vícios ou
                            defeitos técnicos e/ou operacionais oriundos do
                            sistema do usuário ou de terceiros. <br />
                            II) A Prestadio não é responsável pela entrega dos
                            serviços anunciados pelos prestadores na Plataforma.{' '}
                            <br />
                            III) A Prestadio tampouco se responsabiliza pela
                            existência, quantidade, qualidade, estado,
                            integridade ou legitimidade dos serviços oferecidos
                            ou contratados pelos usuários, assim como pela
                            capacidade para contratar dos usuários ou pela
                            veracidade dos dados pessoais por eles fornecidos. A
                            Prestadio, por não ser proprietária, depositante ou
                            detentora dos produtos oferecidos, não outorga
                            garantia por vícios ocultos ou aparentes nas
                            negociações entre os usuários. Cada usuário conhece
                            e aceita ser o único responsável pelos serviços que
                            anuncia ou pelas ofertas que realiza. <br />
                            IV) A Prestadio não será responsável por ressarcir
                            seus usuários por quaisquer gastos com ligações
                            telefônicas, pacotes de dados, SMS, mensagens,
                            emails, correspondência ou qualquer outro valor
                            despendido pelo usuário em razão de contato com a
                            Prestadio ou quaisquer outros usuário, por qualquer
                            motivo que o seja. <br />
                            V) A Prestadio não poderá ser responsabilizada pelo
                            efetivo cumprimento das obrigações assumidas pelos
                            usuários. Os usuários reconhecem e aceitam que, ao
                            realizar negociações com outros usuários, fazem-no
                            por sua conta e risco, reconhecendo a Prestadio como
                            mero fornecedor de serviços de disponibilização de
                            espaço virtual para anúncio dos serviços ofertados
                            por terceiros. <br />
                            VI) Em nenhum caso a Prestadio será responsável pelo
                            lucro cessante ou por qualquer outro dano e/ou
                            prejuízo que o usuário possa sofrer devido às
                            negociações realizadas ou não realizadas por meio de
                            Plataforma, decorrentes da conduta de outros
                            usuários. <br />
                            VII) Por se tratar de negociações realizadas por
                            meio eletrônico entre dois usuários que não se
                            conheciam previamente à negociação, a Prestadio
                            recomenda que toda transação seja realizada com
                            cautela e prudência. <br />
                            VIII) Caso um ou mais usuários ou algum terceiro
                            inicie qualquer tipo de reclamação ou ação legal
                            contra outro ou outros usuários, todos e cada um dos
                            usuários envolvidos nas reclamações ou ações eximem
                            de toda responsabilidade a Prestadio e seus
                            diretores, gerentes, empregados, agentes, operários,
                            representantes e procuradores. i. A Prestadio se
                            reserva o direito de auxiliar e cooperar com
                            qualquer autoridade judicial ou órgão governamental,
                            podendo enviar informações cadastrais ou negociais
                            de seus usuários, quando considerar que seu auxílio
                            ou cooperação sejam necessários para proteger seus
                            usuários, funcionários, colaboradores,
                            administradores, sócios ou qualquer pessoa que possa
                            ser prejudicada pela ação ou omissão combatida.
                            <br />
                            <br />
                            <b>18. ALCANCE DOS SERVIÇOS</b>
                            <br />
                            I) Estes termos não geram nenhum contrato de
                            sociedade, de mandato, de franquia ou relação de
                            trabalho entre a Prestadio e o usuário. O usuário
                            manifesta ciência de que a Prestadio não é parte de
                            nenhuma transação realizada entre usuários, nem
                            possui controle algum sobre a qualidade, a segurança
                            ou a legalidade dos serviços anunciados pelos
                            usuários, sobre a veracidade ou a exatidão dos
                            anúncios elaborados pelos usuários, e sobre a
                            capacidade dos usuários para negociar. <br />
                            II) A Prestadio não pode assegurar o êxito de
                            qualquer transação realizada entre Usuários,
                            tampouco verificar a identidade ou os dados pessoais
                            dos usuários. A Prestadio não garante a veracidade
                            da publicação de terceiros que apareça em sua
                            Plataforma e não será responsável pela
                            correspondência ou por contratos que o usuário
                            realize com terceiros. <br />
                            i. A Prestadio poderá oferecer aos Prestadores um
                            serviço de conferência, para garantir a sua
                            identidade e dados cadastrais. A Prestadio poderá
                            informar aos contratantes quando esse serviço foi
                            utilizado pelo prestador. <br />
                            ii. O prestador que burlar o sistema de conferência
                            a fim de receber vantagens ilícitas será excluído da
                            Plataforma e poderão ser tomadas medidas judiciais
                            cabíveis, especialmente de cunho criminal.
                            <br />
                            <br />
                            <b>19. PROBLEMAS DECORRENTES DO USO DO SISTEMA</b>
                            <br />
                            I) A Prestadio não se responsabiliza por qualquer
                            dano, prejuízo ou perda sofridos pelo usuário em
                            razão de falhas em sua conexão com a internet, com o
                            seu provedor, no sistema, com o sistema de SMS, com
                            a linha telefônica ou no servidor utilizados pelo
                            usuário, decorrentes de condutas de terceiros, caso
                            fortuito ou força maior. <br />
                            II) A Prestadio não é responsável pela
                            compatibilidade entre a sua Plataforma e hardwares
                            de propriedade do usuário. O usuário deverá manter o
                            seu equipamento atualizado e não poderá
                            responsabilizar a Prestadio caso a Plataforma não
                            seja acessível em equipamentos antiquados. <br />
                            III) A Prestadio também não será responsável por
                            qualquer vírus, trojan, malware, spyware ou qualquer
                            software que possa danificar, alterar as
                            configurações ou infiltrar o equipamento do usuário
                            em decorrência do acesso, da utilização ou da
                            navegação na internet, ou como consequência da
                            transferência de dados, informações, arquivos,
                            imagens, textos ou áudio.
                            <br />
                            <br />
                            <b>20. PROPRIEDADE INTELECTUAL E LINKS</b>
                            <br />
                            I) O uso comercial da expressão "Prestadio" como
                            marca, nome empresarial ou nome de domínio, bem como
                            os logos, marcas, insígnias, conteúdo das telas
                            relativas aos serviços da Plataforma e o conjunto de
                            programas, bancos de dados, redes e arquivos que
                            permitem que o Usuário acesse e use sua conta, são
                            propriedade da Prestadio e estão protegidos pelas
                            leis e pelos tratados internacionais de direito
                            autoral, de marcas, de patentes, de modelos e de
                            desenhos industriais. O uso indevido e a reprodução
                            total ou parcial dos referidos conteúdos são
                            proibidos, salvo com autorização expressa da
                            Prestadio.
                            <br />
                            <br />
                            <b>21. PROPRIEDADE INTELECTUAL E LINKS</b>
                            <br />
                            I) O usuário indenizará a Prestadio, suas filiais,
                            empresas controladas, controladores diretos ou
                            indiretos, diretores, administradores,
                            colaboradores, representantes e empregados,
                            inclusive quanto a honorários advocatícios, por
                            qualquer demanda promovida por outros usuários ou
                            terceiros, decorrentes das atividades do primeiro na
                            Plataforma, de quaisquer descumprimentos, por
                            aquele, dos Termos e das demais políticas da
                            Prestadio ou, ainda, de qualquer violação, pelo
                            usuário, de lei ou de direitos de terceiros.
                            <br />
                            <br />
                            <b>22. LEGISLAÇÃO APLICÁVEL E FORO DE ELEIÇÃO</b>
                            <br />
                            I) Todos os itens destes termos são regidos pelas
                            leis vigentes na República Federativa do Brasil.
                            Para todos os assuntos referentes à interpretação,
                            ao cumprimento ou a qualquer outro questionamento
                            relacionado a estes termos, as partes concordam em
                            se submeter ao Foro da Comarca de São Paulo.
                            <br />
                            <br />
                            <b>
                                23. SERVIÇOS E ANÚNCIOS E ORÇAMENTOS PROIBIDOS
                            </b>
                            <br />
                            I) A Prestadio é uma Plataforma de classificados
                            online de serviços, estando proibida a veiculação de
                            qualquer anúncio ou pedido de orçamento de venda,
                            aluguel, troca ou qualquer forma de transferência de
                            posse ou propriedade de qualquer bem móvel ou
                            imóvel. <br />
                            II) Estão proibidas também a veiculação de anúncios
                            de serviços ilegais de acordo com a legislação
                            vigente ou que possam ser considerados ofensivos a
                            terceiros. <br />
                            III) A Prestadio excluirá, unilateralmente e sem
                            qualquer comunicação prévia, aquele usuário que
                            desrespeitar as regras contidas nesta seção. A
                            Prestadio não realiza uma curadoria prévia dos
                            anúncios veiculados na Plataforma. <br />
                            IV) Qualquer usuário ou pessoa física ou jurídica
                            que se sentir ofendido por qualquer anúncio
                            veiculado na Plataforma poderá requisitar à
                            Prestadio que exclua aquele anúncio de sua
                            Plataforma, pelos seus canais de atendimento.
                        </Span>
                    </Row>
                </Col>
            </Container>
        </Fragment>
    );
};

export default TermsOfUse;
