// import node module libraries
import { Fragment } from 'react';
import { Link } from 'react-router-dom';
import { Row, Col, Image, Container, ListGroup } from 'react-bootstrap';

// import MDI icons
import Icon from '@mdi/react';
import { mdiFacebook, mdiInstagram, mdiLinkedin, mdiYoutube } from '@mdi/js';

// import styles
import './styles/styles.css';

// import media files
import FooterLogo from 'assets/images/brand/logo/prestadio.svg';
import MercadoPago from 'assets/images/png/mercado-pago.png';
import PlayStore from 'assets/images/png/playstore.png';
// import AppStore from 'assets/images/png/appstore.png';

const FooterWithLinks = () => {
	return (
		<Fragment>
			<footer className="pt-lg-10 pt-5 footer bg-white">
				<Container>
					<Row>
						<Col lg={4} md={6} sm={12}>
							{/* about company  */}
							<div className="mb-4">
								<Image src={FooterLogo} alt="" className="logo-inverse" width={180} />
								<div className="mt-4">
									<p>
										Priorizamos a eficiência na conexão entre prestadores e clientes para resolver
										suas necessidades rapidamente, acreditamos que a retenção dá-se por um
										atendimento diferenciado e resolutivo.
									</p>
									{/* social media */}
									<div className="fs-4 mt-4">
										<Link
											to="https://www.tiktok.com/@prestadio_br"
											className="mdi mdi-facebook text-muted me-2"
											target="_blank"
										>
											<svg
												xmlns="http://www.w3.org/2000/svg"
												width="12"
												height="12"
												fill="currentColor"
												className="bi bi-tiktok"
												viewBox="0 0 16 16"
											>
												<path d="M9 0h1.98c.144.715.54 1.617 1.235 2.512C12.895 3.389 13.797 4 15 4v2c-1.753 0-3.07-.814-4-1.829V11a5 5 0 1 1-5-5v2a3 3 0 1 0 3 3V0Z" />
											</svg>
										</Link>

										<Link
											to="https://www.youtube.com/channel/UCM1is2zv7fa5Mqc6auZgqnQ"
											className="mdi mdi-facebook text-muted me-2"
											target="_blank"
										>
											<Icon path={mdiYoutube} size={0.7} />
										</Link>
										<Link
											to="https://www.facebook.com/profile.php?id=61553003796372"
											className="mdi mdi-facebook text-muted me-2"
											target="_blank"
										>
											<Icon path={mdiFacebook} size={0.7} />
										</Link>
										<Link
											to="https://www.linkedin.com/in/prestadio-br-6aa26528a/"
											className="mdi mdi-twitter text-muted me-2"
											target="_blank"
										>
											<Icon path={mdiLinkedin} size={0.7} />
										</Link>
										<Link
											to="https://www.instagram.com/prestadiobr/"
											className="mdi mdi-instagram text-muted "
											target="_blank"
										>
											<Icon path={mdiInstagram} size={0.7} />
										</Link>
									</div>
								</div>
							</div>
						</Col>
						<Col className="ml-6" lg={{ span: 2 }} md={3} sm={6}>
							<div className="mb-4">
								{/* list */}
								<h3 className="fw-bold mb-3">Sobre</h3>
								<ListGroup
									as="ul"
									bsPrefix="list-unstyled"
									className="nav nav-footer flex-column nav-x-0"
								>
									<ListGroup.Item as="li" bsPrefix=" ">
										<Link to="/como-funciona" className="nav-link">
											Como funciona
										</Link>
									</ListGroup.Item>
									<ListGroup.Item as="li" bsPrefix=" ">
										<Link to="/termos-de-uso" className="nav-link">
											Termos de uso
										</Link>
									</ListGroup.Item>
									<ListGroup.Item as="li" bsPrefix=" ">
										<Link to="/politica-de-privacidade" className="nav-link">
											Política de privacidade
										</Link>
									</ListGroup.Item>
									<ListGroup.Item as="li" bsPrefix=" ">
										<Link to="/politica-de-cookies" className="nav-link">
											Política de cookies
										</Link>
									</ListGroup.Item>
									<ListGroup.Item as="li" bsPrefix=" ">
										<Link to="/perguntas-frequentes" className="nav-link">
											Perguntas frequentes
										</Link>
									</ListGroup.Item>
								</ListGroup>
							</div>
						</Col>
						<Col lg={3} md={12} sm={12}>
							{/* contact info */}
							<div className="mb-4">
								<h3 className="fw-bold mb-3">Contato</h3>

								<p className="mb-1">
									Email: <Link to="#">contato@prestadio.com.br</Link>
								</p>
								<p>
									Telefone: <span className="text-dark fw-semi-bold">(45) 9 8806-4157</span>
								</p>
							</div>
						</Col>
						<Col>
							<div className="mb-4">
								<h3 className="fw-bold mb-3">Baixe o aplicativo</h3>

								<div className="d-flex flex-column h-100">
									<Col className="m-0 p-0">
										<Link to="https://play.google.com/store/apps/details?id=com.prestadioapp.prestadio">
											<img src={PlayStore} alt="" className="w-50 play-store-logo" />
										</Link>
									</Col>
									<Col className="mt-4">
										<h3 className="fw-bold mb-3">Carteira digital</h3>
										<img src={MercadoPago} alt="" className="mw-50 mercado-pago-logo" />
									</Col>
								</div>
							</div>
						</Col>
					</Row>
					<Row className="align-items-center g-0 border-top py-2 mt-6">
						{/* Desc  */}
						<Col lg={4} md={5} sm={12}>
							<span>© 2023 Prestadio. Todos os direitos reservados</span>
						</Col>
						{/*  Links  */}
						<Col lg={8} md={7} sm={12} className="d-md-flex justify-content-end">
							<nav className="nav nav-footer">
								<Link className="nav-link ps-0" to="/politica-de-privacidade">
									Política de Privacidade
								</Link>
								<Link className="nav-link px-2 px-md-3" to="/politica-de-cookies">
									Cookies
								</Link>
								<Link className="nav-link" to="/termos-de-uso">
									Termos de Uso
								</Link>
							</nav>
						</Col>
					</Row>
				</Container>
			</footer>
		</Fragment>
	);
};

export default FooterWithLinks;
