// import node module libraries
import { useLocation, useNavigate } from 'react-router-dom';
import { Card, Row, Col, Button, Table, OverlayTrigger, Popover } from 'react-bootstrap';
import { useEffect, useRef, useState } from 'react';
import { InfoCircle } from 'react-bootstrap-icons';

import React from 'react';

import { initMercadoPago } from '@mercadopago/sdk-react';

// import profile layout wrapper
import ProfileLayout from 'components/marketing/student/ProfileLayout';

import styles from './styles/styles.css';

import axios from 'axios';

import { useForm } from 'react-hook-form';

import '../../../components/Style.css';

const MyWallet = () => {
    const navigate = useNavigate();
    const editorRef = useRef(null);
    const pathInfo = useLocation();
    const account = pathInfo.pathname.substring(21, 11);
    const [loading, setLoading] = useState(false);
    const [payments, setPayments] = useState();
    initMercadoPago('YOUR_PUBLIC_KEY');

    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm();

    const url = process.env.REACT_APP_API_URL;
    const hash = localStorage.getItem('hash');
    //const loggedUser = localStorage.getItem('lworder_user_data');

    const [loggedUser, setLoggedUser] = useState();

    const getLoggedUserData = async () => {
        const config = {
            headers: {
                Authorization: `Bearer ${hash}`,
            },
        };

        await axios
            .get(`${url}accounts/authentication/logged-user`, config)
            .then(function (response) {
                setLoggedUser(response.data);
            })
            .catch(function (error) {});
    };

    const getPaymentsData = async () => {
        const config = {
            headers: {
                Authorization: `Bearer ${hash}`,
            },
        };

        await axios
            .get(`${url}mercadopago/user-payments`, config)
            .then(function (response) {
                setPayments(response.data);
            })
            .catch(function (error) {});
    };

    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);

    useEffect(() => {
        getLoggedUserData();
        getPaymentsData();
    }, []);

    const goToWithdraw = () => {
        navigate('/usuario/meu-perfil/saque');
    };

    return (
        <ProfileLayout>
            {account === 'instructor'}
            <Card className="border-0">
                <Card.Header>
                    <div className="mb-3 mb-lg-0">
                        <h3 className="mb-0">Minha carteira</h3>
                        <p className="mb-0">
                            Aqui você pode visualizar as informações financeiras de sua conta!
                        </p>
                    </div>
                </Card.Header>
                <Card.Body>
                    <div>
                        <h4 className="mb-3">Informações da carteira</h4>
                        <Row>
                            <Col md={6} sm={12} className="mb-3 w-100">
                                <Table bordered responsive className="text-nowrap">
                                    <tbody>
                                        <tr>
                                            <th scope="row">
                                                <div className=" d-flex align-items-center flex-direction-row gap-2">
                                                    <h5 className="text-dark mb-0">Valor Total</h5>
                                                    <OverlayTrigger
                                                        trigger="focus"
                                                        placement="right"
                                                        overlay={
                                                            <Popover id="popover-basic">
                                                                <Popover.Header as="h3">
                                                                    Valor total
                                                                </Popover.Header>
                                                                <Popover.Body>
                                                                    Corresponde ao valor em serviços
                                                                    finalizados e não finalizados
                                                                </Popover.Body>
                                                            </Popover>
                                                        }
                                                    >
                                                        <Button className="info-popover">
                                                            <InfoCircle
                                                                size={16}
                                                                className="text-dark"
                                                            />
                                                        </Button>
                                                    </OverlayTrigger>
                                                </div>
                                            </th>
                                            <td>
                                                R${' '}
                                                {payments?.total.toLocaleString('pt-BR', {
                                                    minimumFractionDigits: 2,
                                                })}
                                            </td>
                                        </tr>
                                        <tr>
                                            <th scope="row">
                                                <div className=" d-flex align-items-center flex-direction-row gap-2">
                                                    <h5 className="text-warning mb-0">
                                                        Valor bloqueado
                                                    </h5>
                                                    <OverlayTrigger
                                                        trigger="focus"
                                                        placement="right"
                                                        overlay={
                                                            <Popover id="popover-basic">
                                                                <Popover.Header as="h3">
                                                                    Valor bloqueado
                                                                </Popover.Header>
                                                                <Popover.Body>
                                                                    Corresponde ao valor em serviços
                                                                    que ainda não foram confirmados
                                                                </Popover.Body>
                                                            </Popover>
                                                        }
                                                    >
                                                        <Button className="info-popover">
                                                            <InfoCircle
                                                                size={16}
                                                                className="text-dark"
                                                            />
                                                        </Button>
                                                    </OverlayTrigger>
                                                </div>
                                            </th>
                                            <td>
                                                R${' '}
                                                {payments?.bloqueado.toLocaleString('pt-BR', {
                                                    minimumFractionDigits: 2,
                                                })}
                                            </td>
                                        </tr>
                                        <tr>
                                            <th scope="row">
                                                <div className=" d-flex align-items-center flex-direction-row gap-2">
                                                    <h5 className="text-danger mb-0">Taxas</h5>
                                                    <OverlayTrigger
                                                        trigger="focus"
                                                        placement="right"
                                                        overlay={
                                                            <Popover id="popover-basic">
                                                                <Popover.Header as="h3">
                                                                    Taxas
                                                                </Popover.Header>
                                                                <Popover.Body>
                                                                    Valor total de taxas de
                                                                    intermediação
                                                                </Popover.Body>
                                                            </Popover>
                                                        }
                                                    >
                                                        <Button className="info-popover">
                                                            <InfoCircle
                                                                size={16}
                                                                className="text-dark"
                                                            />
                                                        </Button>
                                                    </OverlayTrigger>
                                                </div>
                                            </th>
                                            <td>
                                                {' '}
                                                R${' '}
                                                {payments?.taxa.toLocaleString('pt-BR', {
                                                    minimumFractionDigits: 2,
                                                })}
                                            </td>
                                        </tr>
                                        <tr>
                                            <th scope="row">
                                                <div className=" d-flex align-items-center flex-direction-row gap-2">
                                                    <h5 className="text-success mb-0">
                                                        Disponível para saque
                                                    </h5>
                                                    <OverlayTrigger
                                                        trigger="focus"
                                                        placement="right"
                                                        overlay={
                                                            <Popover id="popover-basic">
                                                                <Popover.Header as="h3">
                                                                    Disponível para saque
                                                                </Popover.Header>
                                                                <Popover.Body>
                                                                    Valor disponível para saque{' '}
                                                                    <br></br>
                                                                    (Valor Total - Valor Bloqueado -
                                                                    Taxas)
                                                                </Popover.Body>
                                                            </Popover>
                                                        }
                                                    >
                                                        <Button className="info-popover">
                                                            <InfoCircle
                                                                size={16}
                                                                className="text-dark"
                                                            />
                                                        </Button>
                                                    </OverlayTrigger>
                                                </div>
                                            </th>
                                            <td>
                                                {' '}
                                                <h5 className="text-success">
                                                    {' '}
                                                    R${' '}
                                                    {payments?.realizado.toLocaleString('pt-BR', {
                                                        minimumFractionDigits: 2,
                                                    })}
                                                </h5>
                                            </td>
                                        </tr>
                                    </tbody>
                                </Table>
                                {payments?.realizado > 0 ? (
                                    <Button disabled={false} onClick={() => goToWithdraw()}>
                                        Solicitar saque
                                    </Button>
                                ) : (
                                    <Button disabled={true}>Solicitar saque</Button>
                                )}
                            </Col>
                        </Row>
                    </div>
                </Card.Body>
            </Card>
        </ProfileLayout>
    );
};

export default MyWallet;
