// import node module libraries
import { Card, Image, OverlayTrigger, Tooltip, Spinner } from 'react-bootstrap';

import _ from 'lodash';

import { useState } from 'react';

import UserImage from 'assets/images/avatar/user.png';

import { GeoAltFill, NutFill } from 'react-bootstrap-icons';

import Rating from '@mui/material/Rating';
import Stack from '@mui/material/Stack';

import { useNavigate } from 'react-router-dom';
const ServiceProvidersCard = (props) => {
	const navigate = useNavigate();
	const { item } = props;

	const goToProvider = (providerId) => {
		const url = `/prestadores/listagem/${providerId}`;
		navigate(url);
	};

	const [imageLoaded, setImageLoaded] = useState(false);
	return (
		<Card
			className="card-bordered card-hover p-0 cursor-pointer h-100 card-profile"
			onClick={() => goToProvider(item.id)}
		>
			<Card.Body>
				<div className="d-flex mb-0 card-profile-body" key={item.id}>
					<div>
						<div className="ms-0 mb-2 ml-1">
							{item?.profilePicture?.url ? (
								<Image
									src={item?.profilePicture.url}
									alt=""
									className="rounded-3 avatar-xxl image-profile"
									onLoad={() => setImageLoaded(true)}
								/>
							) : item ? (
								<Image
									src={UserImage}
									alt=""
									className="rounded-3 avatar-xxl image-profile"
									onLoad={() => setImageLoaded(true)}
								/>
							) : (
								<Spinner
									animation="grow"
									variant="primary"
									className={`me-2 ${imageLoaded ? 'd-none' : ''}`}
								/>
							)}
						</div>
						<div className="ms-0 mb-0 ml-1">
							{item.rating ? (
								<Stack spacing={1}>
									<Rating
										name="half-rating-read"
										defaultValue={item.rating}
										precision={0.5}
										readOnly
										placeholder={item.rating}
									/>
								</Stack>
							) : (
								<Stack spacing={1}>
									<Rating
										name="half-rating-read"
										defaultValue={item.rating}
										precision={0.1}
										value={item.rating}
										readOnly
									/>
								</Stack>
							)}
						</div>
					</div>
					<div className="ms-3 profile-description">
						<div className="d-flex align-items-center card-profile-person-name">
							<div className="ms-0 mb-0 mr-1 ">
								<h5 className="mb-0 text-body mr-5 text-start">
									{_.startCase(_.toLower(item.firstName))} {_.startCase(_.toLower(item.lastName))}
								</h5>
								<OverlayTrigger
									placement="bottom"
									overlay={<Tooltip id="button-tooltip-2">{item?.portfolioTitle}</Tooltip>}
								>
									<div className="portfolioTitle">
										<small>{item?.portfolioTitle}</small>
									</div>
								</OverlayTrigger>
							</div>
						</div>
						<div className="d-flex align-items-center profile-title">
							<div className="ms-0 mb-2" style={{ maxHeight: '100px' }}>
								<h6 className="mb-0 portfolio-title">{item.profileTitle}</h6>
							</div>
						</div>
						<hr className="hr mb-2 mt-1" />
						<div className="d-flex align-items-center mb-3 profile-state">
							<NutFill size={16} className="text-primary" />
							<div className="ms-2">
								<h6 className="mb-0 text-body">
									{item.occupationArea ? `${item.occupationArea}` : ''}
								</h6>
							</div>
						</div>
						<div className="d-flex align-items-center mb-3 profile-state">
							<GeoAltFill size={16} className="text-primary" />
							<div className="ms-2">
								<h6 className="mb-0 text-body">
									{item.city && item.state ? `${item.city}, ${item.state}` : ''}
								</h6>
							</div>
						</div>

						{/*
                        <div className="contact-buttons">
                            {follow === true ? (
                                <Button
                                    className="btn-action btn btn-primary btn-sm follow-button following "
                                    item={item}
                                    onClick={() => unfollowUser()}
                                >
                                    Seguindo
                                </Button>
                            ) : item.isFollowing === false || follow === false ? (
                                <Button
                                    className=" btn-action btn btn-primary btn-sm follow-button"
                                    item={item}
                                    onClick={() => followUser()}
                                >
                                    Seguir
                                </Button>
                            ) : item.isFollowing === true ? (
                                <Button
                                    className="btn btn-primary btn-sm follow-button following"
                                    item={item}
                                    onClick={() => unfollowUser()}
                                >
                                    Seguindo
                                </Button>
                            ) : (
                                ' '
                            )}
                        </div>
                            */}
					</div>
				</div>
			</Card.Body>
		</Card>
	);
};

export default ServiceProvidersCard;
