// import node module libraries
const ThemeContext = React.createContext('light');
import React, { Fragment, useEffect } from 'react';
import { Outlet } from 'react-router-dom';

// import layouts
import NavbarDefault from 'layouts/marketing/navbars/NavbarDefault';
import FooterWithLinks from 'layouts/marketing/footers/FooterWithLinks';

import { Link, redirect, useNavigate } from 'react-router-dom';

import useSelector from 'react-redux';

import auth from 'functions/checkLogin';

const DefaultLayout = (props) => {
    auth();
    return (
        <Fragment>
            <NavbarDefault></NavbarDefault>
            <main>
                {props.children}
                <Outlet />
            </main>
            <FooterWithLinks />
        </Fragment>
    );
};

export default DefaultLayout;
