const occupationAreas = [
    {
        area: 'Artes e Entretenimento',
        profissoes: ['Música', 'Teatro', 'Cinema', 'Dança', 'Artes Visuais'],
    },
    {
        area: 'Ciência e Tecnologia',
        profissoes: [
            'Engenharia',
            'Pesquisa científica',
            'Desenvolvimento de software',
            'Tecnologia da Informação (TI)',
            'Telecomunicações',
        ],
    },
    {
        area: 'Comércio e Vendas',
        profissoes: ['Vendas', 'Varejo', 'Atacado', 'E-commerce', 'Marketing'],
    },
    {
        area: 'Educação',
        profissoes: [
            'Ensino básico',
            'Ensino superior',
            'Pedagogia',
            'Educação especial',
            'Treinamento corporativo',
        ],
    },
    {
        area: 'Saúde e Bem-Estar',
        profissoes: ['Medicina', 'Enfermagem', 'Fisioterapia', 'Nutrição', 'Psicologia'],
    },

    {
        area: 'Recursos Humanos',
        profissoes: [
            'Gestão de pessoas',
            'Recrutamento e seleção',
            'Treinamento e desenvolvimento',
            'Administração de salários e benefícios',
            'Relações trabalhistas',
        ],
    },
    {
        area: 'Serviços Sociais',
        profissoes: [
            'Assistência social',
            'Trabalho comunitário',
            'Serviço de apoio à infância e juventude',
            'Serviço de apoio a idosos',
            'Aconselhamento familiar',
        ],
    },
    {
        area: 'Turismo e Hotelaria',
        profissoes: [
            'Agências de viagens',
            'Hospedagem',
            'Organização de eventos',
            'Gestão de turismo',
            'Guias turísticos',
        ],
    },
    {
        area: 'Comunicação e Mídia',
        profissoes: [
            'Jornalismo',
            'Relações públicas',
            'Publicidade',
            'Produção de conteúdo',
            'Gestão de mídias sociais',
        ],
    },
    {
        area: 'Direito e Justiça',
        profissoes: [
            'Advocacia',
            'Magistratura',
            'Ministério Público',
            'Defensoria Pública',
            'Direito empresarial',
        ],
    },
    {
        area: 'Engenharia Civil e Arquitetura',
        profissoes: [
            'Construção civil',
            'Projetos de arquitetura',
            'Engenharia estrutural',
            'Urbanismo',
            'Gerenciamento de obras',
        ],
    },
    {
        area: 'Administração e Gestão',
        profissoes: [
            'Gestão de empresas',
            'Administração pública',
            'Gestão de projetos',
            'Gestão de recursos humanos',
            'Consultoria empresarial',
        ],
    },
    {
        area: 'Esportes e Atividades Físicas',
        profissoes: [
            'Educação física',
            'Treinamento esportivo',
            'Preparação física',
            'Gestão esportiva',
            'Esportes de alto rendimento',
        ],
    },
    {
        area: 'Moda e Design',
        profissoes: [
            'Design de moda',
            'Estilismo',
            'Modelagem',
            'Design de interiores',
            'Design gráfico',
        ],
    },

    {
        area: 'Consultoria e Assessoria',
        profissoes: [
            'Consultoria de negócios',
            'Consultoria de tecnologia',
            'Consultoria financeira',
            'Assessoria jurídica',
            'Assessoria de imprensa',
        ],
    },
    {
        area: 'Pesquisa e Desenvolvimento',
        profissoes: [
            'Pesquisa acadêmica',
            'Desenvolvimento de produtos',
            'Pesquisa de mercado',
            'Pesquisa científica aplicada',
            'Inovação tecnológica',
        ],
    },
    {
        area: 'Jardinagem',
        profissoes: [
            'Jardineiro',
            'Paisagista',
            'Horticultor',
            'Florista',
            'Técnico em jardinagem',
        ],
    },
    {
        area: 'Limpeza e Conservação',
        profissoes: [
            'Zelador',
            'Auxiliar de limpeza',
            'Faxineiro',
            'Limpador de vidros',
            'Encarregado de conservação',
        ],
    },
    {
        area: 'Manutenção e Reparos',
        profissoes: ['Eletricista', 'Encanador', 'Pedreiro', 'Marceneiro', 'Pintor'],
    },
    {
        area: 'Cuidados Pessoais',
        profissoes: [
            'Cabeleireiro',
            'Manicure e pedicure',
            'Esteticista',
            'Massagista',
            'Barbeiro',
        ],
    },
    {
        area: 'Motoristas e Entregadores',
        profissoes: [
            'Motorista de aplicativo',
            'Motorista de caminhão',
            'Motoboy',
            'Entregador de encomendas',
            'Motorista particular',
        ],
    },
    {
        area: 'Serviços Domésticos',
        profissoes: ['Diarista', 'Cozinheiro(a)', 'Babá', 'Governanta', 'Cuidador(a) de idosos'],
    },
    {
        area: 'Artesanato e Trabalhos Manuais',
        profissoes: [
            'Artesão/Artesã',
            'Costureiro(a)',
            'Bordadeiro(a)',
            'Marcador de livros',
            'Ceramista',
        ],
    },
    {
        area: 'Segurança e Vigilância',
        profissoes: [
            'Vigia',
            'Porteiro',
            'Segurança patrimonial',
            'Monitor de câmeras',
            'Escolta privada',
        ],
    },
    {
        area: 'Alimentação e Gastronomia',
        profissoes: ['Chef de cozinha', 'Padeiro(a)', 'Confeiteiro(a)', 'Chocolatier', 'Sommelier'],
    },
    {
        area: 'Pet Care',
        profissoes: [
            'Dog walker',
            'Pet sitter',
            'Tosador(a) de animais',
            'Adestrador(a) de cães',
            'Cuidador(a) de animais de estimação',
        ],
    },
    {
        area: 'Marketing Digital',
    },
    {
        area: 'Design e Multimídia',
    },
    {
        area: 'Consultoria e Assessoria Empresarial',
    },
    {
        area: 'Desenvolvimento Web e Programação',
    },
    {
        area: 'Apoio Administrativo e Virtual',
    },
    {
        area: 'Educação e Tradução',
    },
    {
        area: 'Marketing e Design Gráfico',
    },
    {
        area: 'Contabilidade e Finanças',
    },
    {
        area: 'Registro de Marcas',
    },
    {
        area: 'Produção Audiovisual',
    },
    {
        area: 'Suporte de Recursos Humanos',
    },

    {
        area: 'Revisão e Recrutamento Profissional',
    },
    {
        area: 'Tarologia',
    },
    {
        area: 'Motion Designer',
    },
    {
        area: 'Numerologia',
    },
];

export default occupationAreas;
