// import node module libraries
import React, { Fragment, useContext, useEffect } from 'react';
import { Outlet } from 'react-router-dom';
import { AuthContext } from 'App';

// import layouts
import NavbarDefault from 'layouts/marketing/navbars/NavbarDefault';
import FooterWithLinks from 'layouts/marketing/footers/FooterWithLinks';

import { Link, redirect, useNavigate } from 'react-router-dom';

import useSelector from 'react-redux';

import auth from 'functions/checkLogin';
const ThemeContext = React.createContext('light');

const HomeLayout = (props) => {
	//const [auth, setAuth] = useContext(AuthContext);
	return (
		<Fragment>
			{/*{auth ? <NavbarDefault login /> : <NavbarDefault />}*/}
			<NavbarDefault></NavbarDefault>
			<main>
				{props.children}
				<Outlet />
			</main>
			<FooterWithLinks />
		</Fragment>
	);
};

export default HomeLayout;
