// import node module libraries
import ReactPaginate from 'react-paginate';
import { Fragment, useEffect, useState } from 'react';
import { ChevronLeft, ChevronRight } from 'react-feather';
import { v4 as uuid } from 'uuid';
import { format, parseISO } from 'date-fns';
import Logo from 'assets/images/brand/logo/prestadio.svg';

// import sub components
import JobListingListviewCard from 'components/marketing/common/cards/JobListingListviewCard';
import FormSelect from 'components/elements/form-select/FormSelect';
import ServiceProvidersCard from './ServiceProvidersCard';

// import data files
//import JobsListingData from 'data/marketing/jobs/JobsListingData';

import LoadingIndicator from 'functions/loading';

import axios from 'axios';
//import { format } from 'prettier';

import styles from './styles/styles.css';

import { Spinner, Col, Row, Modal, Button } from 'react-bootstrap';
import sk from 'date-fns/esm/locale/sk/index.js';

import { useSelector, useDispatch } from 'react-redux';
import { is } from 'date-fns/locale';
import { filterCities, searchBoxFilter } from 'redux/filter/actions';

import { redirect, useNavigate, Link } from 'react-router-dom';

const ServiceProvidersList = () => {
	// Regex para validar se o usuario está em dispositivo movel
	const isMobileDevice = /Mobi|Android/i.test(navigator.userAgent);
	const token = localStorage.getItem('hash');
	// const navigate = useNavigate();
	const url = process.env.REACT_APP_API_URL;
	const [Records, setRecords] = useState([]);
	// const [skipPagination, setSkipPagination] = useState(0);
	const [pageNumber, setPageNumber] = useState(0);
	const [totalRegisters, setTotalRegisters] = useState(0);
	// const [orderFilter, setOrderFilter] = useState('');
	// const [filters, setFilters] = useState([]);
	// const [arrayFiltrada, setArrayFiltrada] = useState([]);
	const [following, setFollowing] = useState([]);
	// const [recordsFavorites, setRecordsFavorites] = useState([]);
	const [loading, setLoading] = useState(true);

	const { occupationAreaFilter, statesFilter, citiesFilter, searchBox } = useSelector(
		(state) => state.filterReducer
	);

	const RecordsPerPage = 18;
	// const pagesVisited = (pageNumber + 1) * RecordsPerPage;

	// const resetAllFilters = () => {
	// 	dispatch(filterOccupationArea(''));
	// 	dispatch(filterCities(''));
	// 	dispatch(filterStates(''));
	// 	dispatch(searchBoxFilter(''));
	// };

	async function getData() {
		setLoading(true);
		let newCitiesFilter = '';

		if (citiesFilter !== null) {
			newCitiesFilter = `&filters[cities][0]=${citiesFilter}`;
		} else {
			newCitiesFilter = '';
		}

		let newStatesFilter = '';
		if (statesFilter !== null) {
			newStatesFilter = `&filters[state]=${statesFilter}`;
		} else {
			newStatesFilter = '';
		}

		let newOccupationAreaFilter = '';
		if (occupationAreaFilter !== null) {
			newOccupationAreaFilter = `&filters[occupationAreas][0]=${occupationAreaFilter}`;
		} else {
			newOccupationAreaFilter = '';
		}

		let newSearchBoxFilter = '';
		if (searchBox !== null) {
			newSearchBoxFilter = `&search=${searchBox}`;
		} else {
			newSearchBoxFilter = '';
		}

		try {
			const response = await axios.get(
				`${url}accounts/users?limit=18&skip=${
					pageNumber * 18
				}${newStatesFilter}${newCitiesFilter}${newOccupationAreaFilter}${newSearchBoxFilter}`,
				{
					headers: {
						Authorization: `Bearer ${token}`,
						'Content-Type': 'x-www-form-urlencoded'
					}
				}
			);

			const items = response.data.result.map((item) => ({
				id: item._id,
				firstName: item.firstName,
				lastName: item.lastName,
				email: item.email,
				profileTitle: item.profileTitle,
				state: item.state,
				city: item?.city,
				skills: item.skills,
				occupationArea: item.occupationArea,
				profilePicture: item.profilePicture,
				profileResume: item.profileResume,
				rating: item.rating,
				portfolioTitle: item.portfolioTitle,
				portfolioAbout: item.portfolioAbout,
				protfolioServices: item.services,
				userRecommendations: item.userRecommendations,
				userSince: format(parseISO(item.createdAt), 'dd/MM/yyyy')
			}));

			setRecords(items);
			//setSkipPagination(0);

			setTotalRegisters(response.data.metadata.total);
			setLoading(false);
		} catch (error) {
			setLoading(false);
		}
	}

	function listFollowing() {
		setLoading(true);
		const config = {
			headers: {
				Authorization: `Bearer ${token}`
			}
		};

		axios
			.get(`${url}accounts/follows/follows`, config)

			.then((response) => {
				setFollowing(response.data.result);
				setLoading(false);
			})

			.catch((error) => {
				setLoading(false);
			});
	}

	const [loggedUser, setLoggedUser] = useState();
	const [haveServicesRegistred, setHaveServicesRegistred] = useState(false);
	const getLoggedUser = async () => {
		setLoading(true);

		const config = {
			headers: {
				Authorization: `Bearer ${token}`
			}
		};

		axios
			.get(`${url}accounts/authentication/logged-user`, config)
			.then((response) => {
				// Atualiza o estado com os dados da resposta da API
				setLoggedUser(response.data);
				setLoading(false);

				const verify = response?.data?.services?.length > 0;

				setHaveServicesRegistred(verify);

				// if (verify === false && (response.data?.userType === 'provider') === true) {
				// 	setShow(true);
				// }
			})
			.catch((error) => {
				console.error('Erro ao buscar dados da API:', error);
				setLoading(false);
			});
	};

	// const handleRegisterService = () => {
	// 	navigate('www.prestadio.com.br/usuario/meu-perfil/editar-porfolio');
	// };

	useEffect(() => {
		getLoggedUser();
		getData();
		listFollowing();
	}, [pageNumber, statesFilter, citiesFilter, occupationAreaFilter, searchBox]);

	function changePage({ selected }) {
		setPageNumber(selected);
		// setSkipPagination(selected * 18); //15 //30
	}

	const pageCount = Math.ceil(totalRegisters / RecordsPerPage);

	// const usersToFollow = Records.map((records) => records.id);
	const usersFollowing = following.map((following) => following.followedId);

	// const isFollowing = usersFollowing.some((value) => usersToFollow.includes(value));

	const updateRecords = Records.map((record) => {
		const isFollowing = usersFollowing.includes(record.id);
		return { ...record, isFollowing };
	});

	const displayRecords = updateRecords.map((record) => {
		return <ServiceProvidersCard item={record} key={record.id} />;
	});
	// end of paging setup

	// const [show, setShow] = useState(false);
	// const handleClose = () => setShow(false);
	// const handleShow = () => setShow(true);

	return (
		<Fragment>
			<Modal show={false}>
				<Modal.Header closeButton>
					<Modal.Title>Equipe Prestadio</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<div className="mb-4 justify-content-center d-flex">
						<img src={Logo} className="logo-prestadio" alt="Logo"></img>
					</div>
					<div className="d-flex flex-direction-row">
						<h4>
							Seja Bem Vindo(a), <h3 className="text-primary">{loggedUser?.firstName} 😁</h3>
						</h4>
					</div>
					Agradecemos por escolher nossa plataforma para conectar-se com oportunidades incríveis.
					Sua presença é fundamental para o sucesso da comunidade. <br />
					<br />
					<strong className="text-danger mb-4">
						Para otimizar sua experiência e aumentar suas chances de receber solicitações de
						serviço, recomendamos que complete seu perfil cadastrando seus serviços no portfólio.
						Essa é a chave para se destacar e atrair oportunidades que correspondam às suas
						habilidades! <br />
						<br />
					</strong>
					<div className="d-flex gap-2 flex-column">
						{' '}
						<strong>Clique no botão abaixo para cadastrar seu portfólio.</strong>
						<Link to="/usuario/meu-perfil/editar-porfolio">
							<Button variant="primary">Cadastrar portfólio</Button>
						</Link>
					</div>
					<br />
					Estamos aqui para ajudar em qualquer etapa do processo. Obrigado por fazer parte da nossa
					comunidade e estamos ansiosos para ver seu trabalho brilhar!
				</Modal.Body>
			</Modal>
			<Row className="lista-prestadores-wrapper">
				<Row className="justify-content-center lista-prestadores">
					<p className="ms-0 ps-0">
						<strong>{totalRegisters}</strong> Prestadores encontrados
					</p>
				</Row>
				{loading === false ? (
					displayRecords
				) : (
					<div className="d-flex justify-content-center" id="spinner_wrapper">
						<LoadingIndicator />
					</div>
				)}
			</Row>
			{isMobileDevice !== false ? (
				<ReactPaginate
					previousLabel={<ChevronLeft size="14px" />}
					nextLabel={<ChevronRight size="14px" />}
					pageCount={pageCount}
					onPageChange={changePage}
					containerClassName={'justify-content-center mb-0 pagination'}
					previousLinkClassName={'page-link mx-1 rounded'}
					nextLinkClassName={'page-link mx-1 rounded'}
					pageClassName={'page-item'}
					pageLinkClassName={'page-link mx-1 rounded'}
					disabledClassName={'paginationDisabled'}
					activeClassName={'active'}
					marginPagesDisplayed={1} // Defina quantos botões de página devem ser exibidos antes e depois do botão atual
					pageRangeDisplayed={1}
				/>
			) : (
				<ReactPaginate
					previousLabel={<ChevronLeft size="14px" />}
					nextLabel={<ChevronRight size="14px" />}
					pageCount={pageCount}
					onPageChange={changePage}
					containerClassName={'justify-content-center mb-0 pagination'}
					previousLinkClassName={'page-link mx-1 rounded'}
					nextLinkClassName={'page-link mx-1 rounded'}
					pageClassName={'page-item'}
					pageLinkClassName={'page-link mx-1 rounded'}
					disabledClassName={'paginationDisabled'}
					activeClassName={'active'}
					marginPagesDisplayed={3} // Defina quantos botões de página devem ser exibidos antes e depois do botão atual
					pageRangeDisplayed={3}
				/>
			)}
		</Fragment>
	);
};

export default ServiceProvidersList;
