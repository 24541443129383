// import node module libraries
import React, { useState, useContext, useEffect } from 'react';
import {
    ButtonGroup,
    ToggleButton,
    Col,
    Row,
    Form,
    InputGroup,
    Button,
    Modal,
    Card,
    Table,
    Alert,
} from 'react-bootstrap';

import { Fragment } from 'react';

import Flatpickr from 'react-flatpickr';

import { Link, redirect, useNavigate, useParams } from 'react-router-dom';

import axios from 'axios';

import ReactQuillEditor from 'components/elements/editor/ReactQuillEditor';

// import custom components
import { FormSelect } from 'components/elements/form-select/FormSelect';

import { AuthContext } from 'App';

import CurrencyInput from 'react-currency-input-field';

import { useForm, Controller, get } from 'react-hook-form';

import './styles/styles.css';
import departments from './components/departments';

import { useGeolocated } from 'react-geolocated';

import formOptions1 from './components/formOptions1';
import formOptions2 from './components/formOptions2';
import formOptions4 from './components/formOptions4';

//const sleep = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

const FormComplainInfo = () => {
    const navigate = useNavigate();
    const { id } = useParams();
    const url = process.env.REACT_APP_API_URL;
    const hash = localStorage.getItem('hash');
    const urlId = useParams();

    //lodingFile: 0 = Sem arquivo / 1 - Carregando / 2 - enviado
    const [loading, setLoading] = useState(false);
    const [username, setUsername] = useState('');
    const [jobData, setJobData] = useState();
    const [city, setCity] = useState('');
    const [region, setRegion] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const [successMessage, setSuccessMessage] = useState('');
    const [loggedUser, setLoggedUser] = useState();

    const {
        handleSubmit,
        control,
        formState: { errors },
        formState: { isSubmitSuccessful },
        register,
    } = useForm();

    const { controlDate } = useForm({
        defaultValues: {
            sells_date: new Date().toLocaleDateString('pt-BR'),
        },
    });

    //FUNÇÃO PARA PEGAR USUÁRIO LOGADO
    async function getLoggedUser() {
        const config = {
            headers: {
                Authorization: `Bearer ${hash}`,
            },
        };

        await axios
            .get(`${url}accounts/authentication/logged-user`, config)

            .then((response) => {
                setLoggedUser(response.data);
            })

            .catch((error) => {
                error;
            });
    }

    const getServiceData = async () => {
        setLoading(true);
        const config = {
            headers: {
                Authorization: `Bearer ${hash}`,
            },
        };

        axios
            .get(`${url}accounts/service-requests/${id}`, config)
            .then((response) => {
                // Atualiza o estado com os dados da resposta da API
                setJobData(response.data);
                setLoading(false);
                response.data;
            })
            .catch((error) => {
                console.error('Erro ao buscar dados da API:', error);
                setLoading(false);
            });
    };

    useEffect(() => {
        getServiceData();
        getLoggedUser();
    }, []);

    //FUNÇÃO PARA ENVIAR OS DADOS PARA O BANCO VIA REACT-HOOK-FORM
    const onSubmit = (data) => {
        setLoading(true);
        data;

        const dataForm = {
            serviceRequestId: id,
            complain: data.complain,
        };

        dataForm;

        const config = {
            headers: {
                Authorization: `Bearer ${hash}`,
            },
        };

        axios
            .post(`${url}accounts/service-requests/complain/${id}`, dataForm, config)

            .then((response) => {
                response;
                setLoading(false);
                setSuccessMessage(
                    'Reclamação enviada com sucesso, nossa equipe irá analisar o caso. Retornaremos o mais breve possível!'
                );
                setErrorMessage();
            })

            .catch((error) => {
                error;

                const statusCode = error.response.data.statusCode;

                if (error.response.data.errorCode == 140) {
                    setErrorMessage('Você já enviou sua avaliação sobre este usuário');
                } else if (statusCode == 500) {
                    setErrorMessage('Este trabalho não possui nenhum prestador selecionado');
                } else if (statusCode == 403) {
                    setErrorMessage('Você não pode recomendar a si mesmo');
                } else {
                    setErrorMessage('Erro na comunicação com o Servidor');
                }

                setLoading(false);
            });
    };

    useEffect(() => {
        getLoggedUser();
        getServiceData();
    }, []);

    return (
        <Card>
            <Card.Header className="card-header">
                <div className="d-flex justify-content-center align-items-center">
                    <div>
                        <h4 className="mb-0">
                            Reclamação do Prestador:{' '}
                            {jobData?.requested?.firstName + ' ' + jobData?.requested?.lastName}
                        </h4>
                    </div>
                </div>
            </Card.Header>
            {/* card body */}
            <Card.Body className="p-lg-8">
                <Row>
                    <Col lg={12} md={12} xs={12} sm={12}>
                        <div>
                            <Row>
                                <Col md={12} sm={12} xs={12} lg={12} className="mb-3">
                                    {/* Select Department */}
                                    <Form.Label htmlFor="department" required>
                                        Descreva sua reclamação
                                        <span className="text-danger">*</span>
                                    </Form.Label>
                                    <Form.Control
                                        type="text"
                                        id="job-title"
                                        placeholder=""
                                        {...register('complain', {
                                            required: true,
                                        })}
                                    />
                                    {errors?.complain?.type === 'required' && (
                                        <p className="errorMessageInput">Campo obrigatório*</p>
                                    )}
                                </Col>

                                <hr className="my-4" />

                                <Col md={12} xs={12}>
                                    <div className="d-flex justify-content-center align-items-center">
                                        <div>
                                            <Button
                                                variant="primary"
                                                onClick={handleSubmit(onSubmit)}
                                                disabled={loading}
                                            >
                                                {loading && (
                                                    <span
                                                        className="spinner-border spinner-border-sm me-2"
                                                        role="status"
                                                        aria-hidden="true"
                                                    ></span>
                                                )}
                                                Enviar Reclamação
                                            </Button>
                                        </div>
                                    </div>
                                </Col>
                                {errorMessage ? (
                                    <Col md={12} xs={12}>
                                        <div className="d-flex justify-content-center align-items-center mt-2">
                                            <Alert variant="danger">{errorMessage}</Alert>
                                        </div>
                                    </Col>
                                ) : successMessage ? (
                                    <Col md={12} xs={12}>
                                        <div className="d-flex justify-content-center align-items-center mt-2">
                                            <Alert variant="success">{successMessage}</Alert>
                                        </div>
                                    </Col>
                                ) : (
                                    ''
                                )}
                            </Row>
                        </div>
                    </Col>
                </Row>
            </Card.Body>
        </Card>
    );
};

export default FormComplainInfo;
