// import node module libraries
import { Fragment } from 'react';
import { Container, Row } from 'react-bootstrap';

// import styled
import {
	Title,
	Paragraph,
	Span,
	HowItWorksBackground,
	HowItWorksContainer,
	SecondParagraph,
	SubParagraph,
	MainImageWrapper,
	WrapperCards,
	CardProvider,
	CardTitleProvider,
	CardSubTitleProvider,
	ImageWrapperProvider,
	CardContractor,
	CardTitleContractor,
	CardSubTitleContractor,
	ImageWrapperContractor,
	Bold
} from './how-it-works-styled/HowItWorksStyled';

// import components
import HomeCards from '../home-academy/HomeCards';

// import images
import ilustracaoPrestadio1 from '../../../../assets/images/howItWorks/ilustracaoPrestadio1.svg';
import ilustracaoPrestadio2 from '../../../../assets/images/howItWorks/ilustracaoPrestadio2.svg';
import ilustracaoPrestadio3 from '../../../../assets/images/howItWorks/ilustracaoPrestadio3.svg';
import ilustracaoPrestadio4 from '../../../../assets/images/howItWorks/ilustracaoPrestadio4.svg';
import ilustracaoPrestadio5 from '../../../../assets/images/howItWorks/ilustracaoPrestadio5.svg';
import ilustracaoPrestadio6 from '../../../../assets/images/howItWorks/ilustracaoPrestadio6.svg';
import ilustracaoPrestadio7 from '../../../../assets/images/howItWorks/ilustracaoPrestadio7.svg';

const HowItWorks = () => {
	return (
		<Fragment>
			<Container>
				<Row>
					<Title>Como funciona?</Title>
					<Paragraph>
						Encontre o talento que <Span>se encaixa</Span> com seu projeto!
					</Paragraph>
					<SecondParagraph>
						Se você procura talentos ou quer oferecer seus serviços, aqui é o lugar onde suas
						necessidades encontram soluções.
					</SecondParagraph>
				</Row>
			</Container>
			<HowItWorksBackground>
				<HowItWorksContainer>
					<Container className="position-relative pb-10">
						<Row>
							<SubParagraph>
								Se você procura talentos ou quer oferecer seus serviços, aqui é o lugar onde suas
								necessidades encontram soluções.
							</SubParagraph>
							<MainImageWrapper>
								<img src={ilustracaoPrestadio7} alt="" />
							</MainImageWrapper>
						</Row>
					</Container>
					<WrapperCards>
						<CardProvider>
							<CardTitleProvider>Prestador</CardTitleProvider>
							<CardSubTitleProvider>
								Monte seu perfil destacando seu trabalho com o portifólio e serviços.
							</CardSubTitleProvider>
							<ImageWrapperProvider width="220px">
								<img src={ilustracaoPrestadio1} alt="" />
							</ImageWrapperProvider>
						</CardProvider>

						<CardContractor>
							<CardTitleContractor>Contratante</CardTitleContractor>
							<CardSubTitleContractor>
								Descubra Prestadores e analise os portifólios e serviços disponíveis.
							</CardSubTitleContractor>
							<ImageWrapperContractor width="230px">
								<img src={ilustracaoPrestadio2} alt="" />
							</ImageWrapperContractor>
						</CardContractor>

						<CardProvider>
							<CardTitleProvider>Prestador</CardTitleProvider>
							<CardSubTitleProvider>
								Receba solicitações de serviços de acordo com sua experiência.
							</CardSubTitleProvider>
							<ImageWrapperProvider width="280px">
								<img src={ilustracaoPrestadio3} alt="" />
							</ImageWrapperProvider>
						</CardProvider>

						<CardContractor>
							<CardTitleContractor>Contratante</CardTitleContractor>
							<CardSubTitleContractor>
								Decida se contratará um serviço da lista do Prestador ou algo mais prático através
								da <Bold>Contratação Rápida.</Bold>
							</CardSubTitleContractor>
							<ImageWrapperContractor width="260px">
								<img src={ilustracaoPrestadio4} alt="" />
							</ImageWrapperContractor>
						</CardContractor>

						<CardProvider>
							<CardTitleProvider>Prestador</CardTitleProvider>
							<CardSubTitleProvider>
								Hora de por a mão na massa e realizar o serviço com preço e prazo estabelecidos.
							</CardSubTitleProvider>
							<ImageWrapperProvider width="250px">
								<img src={ilustracaoPrestadio5} alt="" />
							</ImageWrapperProvider>
						</CardProvider>

						<CardContractor>
							<CardTitleContractor>Contratante</CardTitleContractor>
							<CardSubTitleContractor>
								Aguarde o prestador resolver seu problema e após o pagamento avalie o prestador.
							</CardSubTitleContractor>
							<ImageWrapperContractor width="260px">
								<img src={ilustracaoPrestadio6} alt="" />
							</ImageWrapperContractor>
						</CardContractor>
					</WrapperCards>
				</HowItWorksContainer>
			</HowItWorksBackground>
			<HomeCards />
		</Fragment>
	);
};

export default HowItWorks;
