import { v4 as uuid } from 'uuid';

const NavbarDefault = [
    {
        id: uuid(),
        menuitem: 'Prestadores',
        link: '#',
        children: [
            {
                id: uuid(),
                menuitem: 'Todos os prestadores',
                link: '/prestadores/listagem',
            },
        ],
    },
    {
        id: uuid(),
        menuitem: 'Postagens',
        link: '#',
        children: [
            {
                id: uuid(),
                menuitem: 'Postagens',
                link: '/feed/postagens',
            },
        ],
    },
    {
        id: uuid(),
        menuitem: 'Solicitações',
        link: '/solicitacoes',
    },
    {
        id: uuid(),
        menuitem: 'Portfólio',
        link: '#',
        children: [
            {
                id: uuid(),
                menuitem: 'Editar portfólio',
                link: '/usuario/meu-perfil/editar-porfolio',
            },
        ],
    },

    // {
    //     id: uuid(),
    //     menuitem: 'Conversas',
    //     link: '#',
    //     children: [
    //         {
    //             id: uuid(),
    //             menuitem: 'Minhas conversas',
    //             link: '/chat',
    //         },
    //     ],
    // },

    {
        /*{
        id: uuid(),
        menuitem: 'Sobre',
        link: '#',
        children: [
            {
                id: uuid(),
                menuitem: 'Sobre',
                link: '/marketing/pages/about/',
            },
            {
                id: uuid(),
                menuitem: 'Ajuda',
                link: '#',
                children: [
                    {
                        id: uuid(),
                        menuitem: 'Contato',
                        link: '/marketing/help-center/',
                    },
                    {
                        id: uuid(),
                        menuitem: "FAQ's",
                        link: '/marketing/help-center/faq/',
                    },
                    {
                        id: uuid(),
                        menuitem: 'Guia',
                        link: '/marketing/help-center/guide/',
                    },
                    {
                        id: uuid(),
                        menuitem: 'Suporte',
                        link: '/marketing/help-center/support/',
                    },
                ],
            },
            {
                id: uuid(),
                menuitem: 'Nossos planos',
                link: '/marketing/pages/pricing/',
            },
        ],
    },*/
    },
];

export default NavbarDefault;
