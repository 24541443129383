import { initMercadoPago, Wallet } from '@mercadopago/sdk-react';
import { Fragment, useEffect, useState } from 'react';
import { useParams, Link } from 'react-router-dom';
import { Card, Row, Container, ListGroup, Accordion, Col } from 'react-bootstrap';
import { Payment } from '@mercadopago/sdk-react';
import axios from 'axios';
import {
    PersonFill,
    EnvelopeFill,
    GeoAltFill,
    Folder2Open,
    NutFill,
    CashStack,
    ArrowLeftShort,
} from 'react-bootstrap-icons';
import { useNavigate } from 'react-router-dom';

import styles from './styles.css';

import { StatusScreen } from '@mercadopago/sdk-react';

const CheckoutPage = () => {
    const url = process.env.REACT_APP_API_URL;
    const hash = localStorage.getItem('hash');
    const navigate = useNavigate();
    const [loading, setLoading] = useState();
    const [loggedUser, setLoggedUser] = useState();
    const [serviceData, setServiceData] = useState();
    const { serviceId } = useParams();
    const [preference, setPreference] = useState();
    const [paymentId, setPaymentId] = useState();

    const getLoggedUser = async () => {
        setLoading(true);
        const config = {
            headers: {
                Authorization: `Bearer ${hash}`,
            },
        };

        axios
            .get(`${url}accounts/authentication/logged-user`, config)
            .then((response) => {
                // Atualiza o estado com os dados da resposta da API
                setLoading(false);
                response.data;
            })
            .catch((error) => {
                console.error('Erro ao buscar dados da API:', error);
                setLoading(false);
            });
    };

    const getServiceData = async () => {
        setLoading(true);
        const config = {
            headers: {
                Authorization: `Bearer ${hash}`,
            },
        };

        await axios
            .get(`${url}accounts/service-requests/${serviceId}`, config)
            .then((response) => {
                // Atualiza o estado com os dados da resposta da API
                setServiceData(response.data);
                setLoading(false);
                'Service Data', response.data;

                sendRequestPayment(response.data);
            })
            .catch((error) => {
                setLoading(false);
            });
    };

    const sendRequestPayment = async (service) => {
        const config = {
            headers: {
                Authorization: `Bearer ${hash}`,
            },
        };

        await axios
            .post(`${url}mercadopago/create-preference/${service._id}`, '', config)
            .then((response) => {
                // Atualiza o estado com os dados da resposta da API
                'Data', response.data;

                setPreference(response.data);
            })
            .catch((error) => {
                setLoading(false);
            });
    };

    useEffect(() => {
        getLoggedUser();
        getServiceData();

        initMercadoPago('APP_USR-ddf53baf-1dd7-40b5-9834-924e21c7bec0', {
            locale: 'pt-BR',
        });
    }, []);

    const initialization = {
        amount: preference?.amount,
        preferenceId: preference?.preferenceId,
    };

    const initializationStatus = {
        paymentId: paymentId,
    };

    const customizationStatus = {
        visual: {
            hideStatusDetails: false,
            hideTransactionDate: false,
            style: {
                theme: 'default', // 'default' | 'dark' | 'bootstrap' | 'flat'
            },
        },
    };

    const customization = {
        paymentMethods: {
            bankTransfer: 'all',
            creditCard: 'all',
        },
    };

    const onSubmit = async ({ selectedPaymentMethod, formData }) => {
        // callback chamado ao clicar no botão de submissão dos dados

        const config = {
            headers: {
                Authorization: `Bearer ${hash}`,
            },
        };
        return new Promise((resolve, reject) => {
            fetch(`${url}mercadopago/process_payment`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${hash}`,
                },
                body: JSON.stringify(formData),
            })
                .then((response) => response.json())
                .then((response) => {
                    setPaymentId(response.id);
                    response;
                    resolve();
                })
                .catch((error) => {
                    error;
                    reject();
                });
        });
    };

    const onError = async (error) => {
        // callback chamado para todos os casos de erro do Brick
        error;
    };
    const onReady = async () => {
        /*
          Callback chamado quando o Brick estiver pronto.
          Aqui você pode ocultar loadings do seu site, por exemplo.
        */
    };

    const settings = {
        initialization: {
            paymentId: paymentId, // Payment identifier, from which the status will be checked
        },
        customization: {
            visual: {
                hideStatusDetails: false,
                hideTransactionDate: false,
                style: {
                    theme: 'default', // 'default' | 'dark' | 'bootstrap' | 'flat'
                },
            },
        },
    };

    return (
        <section className="py-8 bg-light">
            <Container>
                <div className="mb-2 cursor-pointer" onClick={() => navigate(-1)}>
                    <Link>
                        <ArrowLeftShort size={16} className="text-primary" />
                        Voltar
                    </Link>
                </div>
                <Row>
                    <Col lg={8} className="mb-4">
                        <Card>
                            <Card.Header>
                                <h3>Detalhes da Solicitação</h3>
                            </Card.Header>
                            <Card.Body>
                                <div className="mb-2 d-flex align-items-center gap-2">
                                    <NutFill size={16} className="text-primary" />
                                    <h4 className="mb-0">Serviços solicitados</h4>
                                </div>
                                <ListGroup>
                                    {serviceData?.requestedServices &&
                                        serviceData?.requestedServices.map((item, index) => {
                                            return (
                                                <ListGroup.Item
                                                    key={index}
                                                    className="d-flex align-items-center justify-content-between"
                                                >
                                                    <div>
                                                        <h4 className="mb-0">{item.title}</h4>
                                                        <small>{item.description}</small>
                                                    </div>
                                                    <div className="d-flex flex-column align-items-end">
                                                        <small>Valor</small>
                                                        <p className=" mb-0 fw-bold text-primary">
                                                            {item.value.toLocaleString('pt-BR', {
                                                                style: 'currency',
                                                                currency: 'BRL',
                                                            })}
                                                        </p>
                                                    </div>
                                                </ListGroup.Item>
                                            );
                                        })}
                                </ListGroup>
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col lg={4} className="mb-4">
                        <Card>
                            <Card.Header>
                                <h3>Resumo</h3>
                            </Card.Header>
                            <Card.Body>
                                {' '}
                                <ListGroup>
                                    <ListGroup.Item className="d-flex align-items-center justify-content-between">
                                        <div className="d-flex align-items-center gap-1">
                                            <PersonFill size={16} className="text-primary" />
                                            <h5 className="mb-0 text-muted">Prestador: </h5>
                                        </div>
                                        <div>
                                            <h4 className="mb-0">
                                                {serviceData?.requested?.firstName}{' '}
                                                {serviceData?.requested?.lastName}
                                            </h4>
                                        </div>
                                    </ListGroup.Item>
                                    <ListGroup.Item className="d-flex align-items-center justify-content-between">
                                        <div className="d-flex align-items-center gap-1">
                                            <NutFill size={16} className="text-primary" />
                                            <h5 className="mb-0 text-muted">
                                                Serviços Solicitados:{' '}
                                            </h5>
                                        </div>
                                        <div>
                                            <h4 className="mb-0">
                                                {serviceData?.requestedServices?.length ?? 0}{' '}
                                            </h4>
                                        </div>
                                    </ListGroup.Item>
                                    <ListGroup.Item className="d-flex align-items-center justify-content-between">
                                        <div className="d-flex align-items-center gap-1">
                                            <CashStack size={16} className="text-primary" />
                                            <h5 className="mb-0 text-muted">Valor total: </h5>
                                        </div>
                                        <div>
                                            <strong>
                                                <h4 className="text-primary">
                                                    {serviceData?.total?.toLocaleString('pt-BR', {
                                                        style: 'currency',
                                                        currency: 'BRL',
                                                    })}
                                                </h4>
                                            </strong>
                                        </div>
                                    </ListGroup.Item>
                                </ListGroup>
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col lg={12}>
                        {preference !== undefined ? (
                            <div className="mb-4">
                                <Payment
                                    initialization={initialization}
                                    customization={customization}
                                    onSubmit={onSubmit}
                                    onReady={onReady}
                                    onError={onError}
                                />
                            </div>
                        ) : (
                            ''
                        )}

                        <div>
                            <StatusScreen
                                initialization={initializationStatus}
                                onReady={onReady}
                                onError={onError}
                                customization={customizationStatus}
                            />
                        </div>
                    </Col>
                </Row>
            </Container>
        </section>
    );
};

export default CheckoutPage;
