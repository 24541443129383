// import node module libraries
import { useEffect, useState } from 'react';
import { Card, Collapse, Button, Form } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { useDispatch } from 'react-redux';

// import custom components
// import RangeSlider from 'components/elements/range-slider/RangeSlider';

// Import required utility file
import { getSlug } from 'helper/utils';
// import rootReducer from 'redux/root-reducer';
// import { current } from '@reduxjs/toolkit';
// import { mdiPageLayoutHeader } from '@mdi/js';
import { filterOccupationArea, filterStates, filterCities } from 'redux/filter/actions';
import axios from 'axios';
import occupationAreas from '../occupationAreas';

const ServiceProvidersFilters = () => {
	const isMobileDevice = /Mobi|Android/i.test(navigator.userAgent);
	const url = process.env.REACT_APP_API_URL;
	const hash = localStorage.getItem('hash');

	const [openState, setOpenState] = useState(isMobileDevice === true ? false : true);
	const [openCity, setOpenCity] = useState(isMobileDevice === true ? false : true);
	const [openActing, setOpenActing] = useState(isMobileDevice === true ? false : true);

	// const [openLastUpdated, setOpenLastUpdated] = useState(true);
	const [loading, setLoading] = useState(true);

	// const { occupationAreaFilter, statesFilter, citiesFilter } = useSelector(
	// 	(rootReducer) => rootReducer.filterReducer
	// );

	const dispatch = useDispatch();
	// const setOccupationArea = (e) => {
	// 	dispatch(filterOccupationArea(`${e}`));
	// };

	// const setOccupationAreaFilter = (e) => {
	// 	dispatch(filterOccupationArea(e));
	// };

	// const setStatesFilter = (e) => {
	// 	dispatch(filterStates(e));
	// };

	// const setCitiesFilter = (e) => {
	// 	dispatch(filterCities(`${e}`));
	// };

	const [selectedOccupationArea, setSelectedOccupationArea] = useState(null);
	const [selectedCity, setSelectedCity] = useState(null);
	const [selectedState, setSelectedState] = useState(null);

	const handleOccupationAreaChange = (label) => {
		if (selectedOccupationArea === label) {
			setSelectedOccupationArea(null);
			dispatch(filterOccupationArea(null));
		} else {
			setSelectedOccupationArea(label);
			dispatch(filterOccupationArea(label));
		}
	};

	const handleStateChange = (label) => {
		if (selectedState === label) {
			setSelectedState(null);
			dispatch(filterStates(null));
		} else {
			setSelectedState(label);
			dispatch(filterStates(label));
		}
	};

	const handleCitiesChange = (label) => {
		if (selectedCity === label) {
			setSelectedCity(null);
			dispatch(filterCities(null));
		} else {
			setSelectedCity(label);
			dispatch(filterCities(label));
		}
	};

	const handleClearFilters = () => {
		setSelectedCity(null);
		dispatch(filterCities(null));
		setSelectedState(null);
		dispatch(filterStates(null));
		setSelectedOccupationArea(null);
		dispatch(filterOccupationArea(null));
	};

	const [states, setStates] = useState([]);
	const [cities, setCities] = useState([]);
	const getStates = async () => {
		setLoading(true);
		const config = {
			headers: {
				Authorization: `Bearer ${hash}`
			}
		};

		axios
			.get(`${url}accounts/users/find-states`, config)
			.then((response) => {
				setStates(response.data);
				setLoading(false);
			})
			.catch((error) => {
				console.error('Erro ao buscar dados da API:', error);
				setLoading(false);
			});
	};

	const getCities = async () => {
		setLoading(true);
		const config = {
			headers: {
				Authorization: `Bearer ${hash}`
			}
		};

		axios
			.get(`${url}accounts/users/find-cities?state=`, config)
			.then((response) => {
				setCities(response.data);
				setLoading(false);
			})
			.catch((error) => {
				console.error('Erro ao buscar dados da API:', error);
			});
	};

	useEffect(() => {
		setLoading(true);
		getStates();
		getCities();
	}, []);

	const options = occupationAreas.map((area) => ({
		label: area.area,
		value: area.area
	}));

	const [windowWidth, setWindowWidth] = useState(window.innerWidth);

	const handleResize = () => {
		setWindowWidth(window.innerWidth);
	};

	useEffect(() => {
		// Adiciona um listener para o evento de redimensionamento da janela
		window.addEventListener('resize', handleResize);

		// Remove o listener quando o componente é desmontado
		return () => {
			window.removeEventListener('resize', handleResize);
		};
	}, []);

	return loading === false ? (
		<Card
			className="border mb-6 mb-md-0 shadow-none filters-card"
			style={{ maxWidth: isMobileDevice === true ? windowWidth : null }}
		>
			<Card.Header>
				<h4 className="mb-0 fs-5">
					<svg
						xmlns="http://www.w3.org/2000/svg"
						width="16"
						height="16"
						fill="currentColor"
						className="bi bi-filter text-muted me-2"
						viewBox="0 0 16 16"
					>
						<path d="M6 10.5a.5.5 0 0 1 .5-.5h3a.5.5 0 0 1 0 1h-3a.5.5 0 0 1-.5-.5zm-2-3a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7a.5.5 0 0 1-.5-.5zm-2-3a.5.5 0 0 1 .5-.5h11a.5.5 0 0 1 0 1h-11a.5.5 0 0 1-.5-.5z" />
					</svg>
					Filtros
				</h4>
			</Card.Header>
			<Card.Body className="border-bottom p-0">
				<Link
					to="#"
					onClick={() => setOpenActing(!openActing)}
					aria-controls="salary"
					aria-expanded={openActing}
					className="fs-5 text-dark fw-semi-bold d-block px-4 py-3"
					data-bs-toggle="collapse"
				>
					Área de Atuação
					<span className="float-end">
						{!openActing ? (
							<svg
								xmlns="http://www.w3.org/2000/svg"
								width="14"
								height="14"
								fill="currentColor"
								className="bi bi-chevron-down"
								viewBox="0 0 16 16"
							>
								<path
									fillRule="evenodd"
									d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z"
								/>
							</svg>
						) : (
							<svg
								xmlns="http://www.w3.org/2000/svg"
								width="14"
								height="14"
								fill="currentColor"
								className="bi bi-chevron-down"
								viewBox="0 0 16 16"
								style={{ transform: 'rotate(180deg)' }}
							>
								<path
									fillRule="evenodd"
									d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z"
								/>
							</svg>
						)}
					</span>
				</Link>
				<Collapse in={openActing}>
					<div id="salary" className="locations px-4 py-0">
						<div className="mt-3">
							{options.map((item, index) => (
								<Form.Check
									type="checkbox"
									className="mb-2"
									id={getSlug(item.label)}
									key={index}
									multiple={false}
								>
									<Form.Check.Input
										type="checkbox"
										onChange={() => handleOccupationAreaChange(item.label)}
										checked={selectedOccupationArea === item.label}
										name="occupationAreaGroup"
									/>
									<Form.Check.Label>{item.label}</Form.Check.Label>
								</Form.Check>
							))}
						</div>
					</div>
				</Collapse>
			</Card.Body>
			<Card.Body className="p-0">
				<Link
					to="#"
					onClick={() => setOpenState(!openState)}
					aria-controls="locations"
					aria-expanded={openState}
					className="fs-5 text-dark fw-semi-bold d-block px-4 py-3"
					data-bs-toggle="collapse"
				>
					Estado
					<span className="float-end">
						{!openState ? (
							<svg
								xmlns="http://www.w3.org/2000/svg"
								width="14"
								height="14"
								fill="currentColor"
								className="bi bi-chevron-down"
								viewBox="0 0 16 16"
							>
								<path
									fillRule="evenodd"
									d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z"
								/>
							</svg>
						) : (
							<svg
								xmlns="http://www.w3.org/2000/svg"
								width="14"
								height="14"
								fill="currentColor"
								className="bi bi-chevron-down"
								viewBox="0 0 16 16"
								style={{ transform: 'rotate(180deg)' }}
							>
								<path
									fillRule="evenodd"
									d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z"
								/>
							</svg>
						)}
					</span>
				</Link>
				<Collapse in={openState}>
					<div id="locations" className="locations px-4 py-0">
						<div className="mt-2">
							{states
								?.sort((a, b) => a.localeCompare(b))
								.map((state, index) => (
									<Form.Check type="checkbox" className="mb-2" id={getSlug(state)} key={index}>
										<Form.Check.Input
											type="checkbox"
											onChange={() => handleStateChange(state)}
											checked={selectedState === state}
											name="statesGroup"
										/>
										<Form.Check.Label>{state}</Form.Check.Label>
									</Form.Check>
								))}
						</div>
					</div>
				</Collapse>
			</Card.Body>
			<Card.Body className="border-top border-bottom p-0">
				<Link
					to="#"
					onClick={() => setOpenCity(!openCity)}
					aria-controls="locations"
					aria-expanded={openCity}
					className="fs-5 text-dark fw-semi-bold d-block px-4 py-3"
					data-bs-toggle="collapse"
				>
					Cidade
					<span className="float-end">
						{!openCity ? (
							<svg
								xmlns="http://www.w3.org/2000/svg"
								width="14"
								height="14"
								fill="currentColor"
								className="bi bi-chevron-down"
								viewBox="0 0 16 16"
							>
								<path
									fillRule="evenodd"
									d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z"
								/>
							</svg>
						) : (
							<svg
								xmlns="http://www.w3.org/2000/svg"
								width="14"
								height="14"
								fill="currentColor"
								className="bi bi-chevron-down"
								viewBox="0 0 16 16"
								style={{ transform: 'rotate(180deg)' }}
							>
								<path
									fillRule="evenodd"
									d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z"
								/>
							</svg>
						)}
					</span>
				</Link>
				<Collapse in={openCity}>
					<div id="locations" className="locations px-4 py-0">
						<div className="mt-2">
							{cities.length > 0 &&
								cities
									.sort((a, b) => a.localeCompare(b))
									.map((city, index) => (
										<Form.Check type="checkbox" className="mb-2" id={getSlug(city)} key={index}>
											<Form.Check.Input
												type="checkbox"
												onChange={() => handleCitiesChange(city)}
												checked={selectedCity === city}
												name="citiesGroup"
											/>
											<Form.Check.Label>{city}</Form.Check.Label>
										</Form.Check>
									))}
						</div>
					</div>
				</Collapse>
			</Card.Body>
			<Card.Body className="py-3 d-grid">
				<Button as="a" variant="primary" href="#" onClick={handleClearFilters}>
					Limpar Filtros
				</Button>
			</Card.Body>
		</Card>
	) : null;
};

export default ServiceProvidersFilters;
