// import styled-component
import { styled } from 'styled-components';

export const CookieBackground = styled.div`
	width: 100%;
	height: 100%;
	background-color: #00000059;
	position: fixed;
	top: 0;
	z-index: 19;
`;

export const CookieContainer = styled.div`
	position: fixed;
	bottom: 0;
	left: 0;
	right: 0;
	z-index: 20;
	background-color: #011c25ed;
`;

export const CookieTitle = styled.h3`
	color: #fff;
	margin-bottom: 1.2rem;
`;

export const CookieDescription = styled.p`
	width: 80%;
	color: #dee3e7;
	margin: 0 !important;

	@media screen and (max-width: 768px) {
		width: 100%;
	}
`;

export const CookieContent = styled.div`
	display: flex;
	flex-direction: column;
	align-items: start;
	justify-content: space-between;
	padding: 2rem 0;
`;

export const CookieInformation = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;

	@media screen and (max-width: 768px) {
		flex-direction: column;
		align-items: start;
		gap: 15px;
	}
`;

export const Wrapperbuttons = styled.div`
	display: flex;
	gap: 20px;
`;

export const ButtonReject = styled.button`
	color: #00ccff;
	padding: 5px 12px;
	border-radius: 5px;
	border: solid 1px #00ccff;
	background-color: transparent;
	transition: 0.2s;

	&:hover {
		transition: 0.2s;
		color: #fff;
	}
`;
