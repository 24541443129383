export const settings = {
  app: {
    name: 'Prestadio',
    version: '1.0.0',
  },
  theme: {
    skin: 'light',
  },
};
export default { settings };
//
