import { BrowserRouter as Router } from 'react-router-dom';
import React from 'react';
import AllRoutes from 'layouts/AllRoutes';

import { Provider } from 'react-redux';
import store from 'redux/store';

import 'simplebar/dist/simplebar.min.css';
import 'tippy.js/animations/scale.css';

import ScrollToTop from 'layouts/dashboard/ScrollToTop';
import SocketIOComponent from 'components/notifications/MenuNotifications';

function App() {
  return (
    <Provider store={store}>
      <Router>
        <ScrollToTop />
        <AllRoutes />
        <SocketIOComponent />
      </Router>
    </Provider>
  );
}

export default App;
