// import node module libraries
import { Fragment, useEffect, useState } from 'react';
import {
    Col,
    Row,
    Card,
    ListGroup,
    OverlayTrigger,
    Tooltip,
    CloseButton,
    Image,
} from 'react-bootstrap';
import { ThumbsUp, Award, MessageSquare } from 'react-feather';
import { Link } from 'react-router-dom';

import LoadingIndicator from 'functions/loading';

import ReactPaginate from 'react-paginate';
import { ChevronLeft, ChevronRight } from 'react-feather';

import IconPedido from '../../../assets/images/icons/pedido.png';

import { redirect, useNavigate } from 'react-router-dom';

// import custom components
import DotBadge from 'components/elements/bootstrap/DotBadge';

import ProfileLayout from 'components/marketing/student/ProfileLayout';

import axios from 'axios';

import { format, parseISO } from 'date-fns';

import UserImage from 'assets/images/avatar/user.png';

const Followers = () => {
    const navigate = useNavigate();
    function NotificationsIcon(icon, color) {
        if (icon === 'ThumbsUp') {
            return <ThumbsUp className={`text-${color} me-1`} size="12px" />;
        }
        if (icon === 'Award') {
            return <Award className={`text-${color} me-1`} size="12px" />;
        }
        if (icon === 'MessageSquare') {
            return <MessageSquare className={`text-${color} me-1`} size="12px" />;
        }
    }

    function MarkAsRead(removable) {
        if (removable) {
            return (
                <Fragment>
                    <OverlayTrigger
                        placement="top"
                        overlay={<Tooltip id="tooltip-top">Mark as read</Tooltip>}
                    >
                        <Link to="#">
                            <DotBadge bg="info"></DotBadge>
                        </Link>
                    </OverlayTrigger>

                    <OverlayTrigger
                        placement="top"
                        overlay={<Tooltip id="tooltip-top">Remove</Tooltip>}
                    >
                        <CloseButton className="btn-close fs-6 d-block me-1" />
                    </OverlayTrigger>
                </Fragment>
            );
        } else {
            return (
                <OverlayTrigger
                    placement="top"
                    overlay={<Tooltip id="tooltip-top">Mark as unread</Tooltip>}
                >
                    <Link to="#">
                        <DotBadge bg="secondary"></DotBadge>
                    </Link>
                </OverlayTrigger>
            );
        }
    }

    const hash = localStorage.getItem('hash');
    const urlLogout = process.env.REACT_APP_API_URL;
    const url = process.env.REACT_APP_API_URL;
    const [notifications, setNotifications] = useState();
    const [loggedIn, setLoggedIn] = useState(false);
    const [loggedUserData, setLoggedUserData] = useState();
    const [followers, setFollowers] = useState();
    const [totalRegisters, setTotalRegisters] = useState();
    const [pageNumber, setPageNumber] = useState(0);
    const [loading, setLoading] = useState(true);

    const getLoggedUser = async () => {
        setLoading(true);
        const config = {
            headers: {
                Authorization: `Bearer ${hash}`,
            },
        };

        axios
            .get(`${url}accounts/authentication/logged-user`, config)
            .then((response) => {
                // Atualiza o estado com os dados da resposta da API
                setLoggedUserData(response.data);
                setLoggedIn(true);
                setLoading(false);
            })
            .catch((error) => {
                setLoggedIn(false);
                setLoading(false);
            });
    };

    const getFollowers = () => {
        setLoading(true);
        axios({
            method: 'get',
            url: `${url}accounts/follows/followers?limit=20&skip=${pageNumber * 20}`,
            headers: {
                Authorization: `Bearer ${hash}`,
            },
        })
            .then((response) => {
                setFollowers(response.data.result);
                response.data.result;
                setLoading(false);
            })
            .catch((error) => {
                console.error(error);
                setLoading(false);
            });
    };

    const handleViewNotification = (item) => {
        item;

        axios({
            method: 'patch',
            url: `${url}notifications/read/${item._id}`,
            headers: {
                Authorization: `Bearer ${hash}`,
            },
        })
            .then((response) => {})
            .catch((error) => {});
        ///
    };

    const handleViewUser = (item) => {
        navigate(`/prestadores/listagem/${item.follower._id}`);
    };

    useEffect(() => {
        getLoggedUser();
        getFollowers();
    }, [pageNumber]);

    function changePage({ selected }) {
        setPageNumber(selected);
    }

    const RecordsPerPage = 20;
    const pageCount = Math.ceil(totalRegisters / RecordsPerPage);
    return (
        <ProfileLayout>
            <Fragment>
                <Card>
                    <Card.Header>
                        <div className="mb-3 mb-lg-0">
                            <h3 className="mb-0">Meus seguidores</h3>
                            <p className="mb-0">
                                Aqui você pode visualizar todos os seus seguidores
                            </p>
                        </div>
                    </Card.Header>
                    <Card.Body>
                        <div className="d-flex justify-content-center">
                            {loading == true ? <LoadingIndicator /> : ''}
                        </div>
                        <ListGroup className="mb-4">
                            {followers &&
                                followers.map((item, index) => {
                                    return (
                                        <ListGroup.Item
                                            key={index}
                                            onClick={() => handleViewUser(item)}
                                        >
                                            <Row className="align-items-center">
                                                <Col>
                                                    <div className="d-flex align-items-center">
                                                        <Link to="#">
                                                            <Image
                                                                src={
                                                                    item.follower?.profilePicture
                                                                        ? item.follower
                                                                              ?.profilePicture.url
                                                                        : UserImage
                                                                }
                                                                alt=""
                                                                className="avatar-md rounded-circle"
                                                            />
                                                        </Link>
                                                        <div className="ms-3">
                                                            <Link to="#">
                                                                <p className="mb-0 text-body">
                                                                    <span className="fw-bold mb-0 h5">
                                                                        {item.follower.firstName +
                                                                            ' ' +
                                                                            item.follower.lastName}
                                                                    </span>{' '}
                                                                </p>
                                                            </Link>
                                                            <span className="fs-5 text-muted">
                                                                <span>
                                                                    {NotificationsIcon(
                                                                        item.icon,
                                                                        item.colorClass
                                                                    )}
                                                                    {item.follower.city}
                                                                </span>
                                                                <span className="ms-1">
                                                                    {item.time}
                                                                </span>
                                                            </span>
                                                        </div>
                                                    </div>
                                                </Col>
                                                <Col className="col-auto text-center p-2">
                                                    {MarkAsRead(item.removable)}
                                                </Col>
                                            </Row>
                                        </ListGroup.Item>
                                    );
                                })}
                        </ListGroup>
                        <ReactPaginate
                            previousLabel={<ChevronLeft size="14px" />}
                            nextLabel={<ChevronRight size="14px" />}
                            pageCount={pageCount}
                            onPageChange={changePage}
                            containerClassName={'justify-content-center mb-0 pagination'}
                            previousLinkClassName={'page-link mx-1 rounded'}
                            nextLinkClassName={'page-link mx-1 rounded'}
                            pageClassName={'page-item'}
                            pageLinkClassName={'page-link mx-1 rounded'}
                            disabledClassName={'paginationDisabled'}
                            activeClassName={'active'}
                        />
                    </Card.Body>
                </Card>
            </Fragment>
        </ProfileLayout>
    );
};

export default Followers;
